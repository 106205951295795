import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function CourseList() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const [tableilndex, settableilndex] = useState('');
  useEffect(() => {
      document.title = "臺中市政府文化局報名繳費系統--課程列表";
  },[]);

  return (
      <>
          <section class="section-box bg-white">
              <div class="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <div class="course">
                      <div class="course-serach">
                          <div class="course-logo"><img src="images/大墩文化中心.png" alt="" /></div>
                          {/*<!-- owl-carousel -->*/}
                          <div class="course-pic">
                              <div class="banner-ad-area owl-carousel owl-theme nav-style-2">

                                  <div class="item">
                                      <a href="#" title="" target="_blank"><img src="images/index/ad-01.jpg" /></a>
                                  </div>
                                  <div class="item">
                                      <a href="#" title="" target="_blank"><img src="images/index/ad-02.jpg" /></a>
                                  </div>
                                  <div class="item">
                                      <a href="#" title="" target="_blank"><img src="images/index/ad-03.jpg" /></a>
                                  </div>
                                  <div class="item">
                                      <a href="#" title="" target="_blank"><img src="images/index/ad-04.jpg" /></a>
                                  </div>
                                  <div class="item">
                                      <a href="#" title="" target="_blank"><img src="images/index/ad-05.jpg" /></a>
                                  </div>
                                  <div class="item">
                                      <a href="#" title="" target="_blank"><img src="images/index/ad-06.jpg" /></a>
                                  </div>
                                  <div class="item">
                                      <a href="#" title="" target="_blank"><img src="images/index/ad-07.jpg" /></a>
                                  </div>

                              </div>
                              {/*<!-- end owl-carousel -->*/}
                          </div>


                          <div class="course-wall"></div>


                          <div class="course-select">
                              <div class="course-title"><h1 class="course-title">大墩文化中心</h1></div>

                              <h3>課程星期</h3>
                              {/*<!-- 單位 -->*/}
                              <div class="course-unit">
                                  <label>
                                      <input type="checkbox" name="" value="1" />全部
                                  </label>

                                  <label>
                                      <input type="checkbox" name="" value="2" />星期二
                                  </label>
                                  <label>
                                      <input type="checkbox" name="" value="3" />星期三
                                  </label>
                                  <label>
                                      <input type="checkbox" name="" value="4" />星期四
                                  </label>
                                  <label>
                                      <input type="checkbox" name="" value="5" />星期五
                                  </label>
                                  <label>
                                      <input type="checkbox" name="" value="6" />星期六
                                  </label>
                                  <label>
                                      <input type="checkbox" name="" value="7" />星期日
                                  </label>
                              </div>
                              {/*<!-- 單位 -->*/}


                              <h3>選擇班別</h3>
                              {/*<!-- 單位 -->*/}
                              <div class="course-unit">

                                  <label>
                                      <input type="checkbox" name="" value="1" />成人班
                                  </label>
                                  <label>
                                      <input type="checkbox" name="" value="2" />兒童班
                                  </label>

                              </div>



                              <h3>課程類別</h3>
                              {/*<!--  類別 -->*/}
                              <div class="course-kind">
                                  <label>
                                      <input type="checkbox" name="" value="1" />全部
                                  </label>

                                  <label>
                                      <input type="checkbox" name="" value="2" />美術類
                                  </label>

                                  <label>
                                      <input type="checkbox" name="" value="3" />音樂類
                                  </label>

                                  <label>
                                      <input type="checkbox" name="" value="4" />舞蹈類
                                  </label>

                                  <label>
                                      <input type="checkbox" name="" value="5" />藝工類
                                  </label>
                                  <label>
                                      <input type="checkbox" name="" value="6" />文學類
                                  </label>

                              </div>


                              {/*  <h3>日期</h3>*/}
                              {/* <div class="course-date">*/}
                              {/*     <input type="date" /> ~ <input type="date" />*/}
                              {/*</div> */}


                              {/*  <!--  end course-select -->*/}
                          </div>
                          {/* <!--  end course-serach -->*/}


                      </div>
                      {/*<!-- course -->*/}


                  </div>
              </div>
          </section>
          {
              tableilndex == 1 ?
                  <> </>
                  :
                  <> </>
          }
          
          <section class="section-box bg-gray-100">
              <div class="container">

                  {/* <!-- 改變排列 -->*/}
                  <div class="radio-list-style">

                      <div class="input-container">
                          <input class="radio-button" value="list-sol-style3" name="list-template" type="radio"
                              onClick={() => { history.push('/CourseList'); }} checked />
                          <div class="radio-tile">
                              <div class="icon">
                                  <i class="fa fa-align-justify"></i>
                              </div>
                          </div>
                      </div>

                      <div class="input-container">
                          <input class="radio-button" value="list-sol-style2" name="list-template" type="radio"
                              onClick={() => { history.push('/Courselisttable'); }} />
                          <div class="radio-tile">
                              <div class="icon">
                                  <i class="fa fa-table"></i>
                              </div>
                          </div>
                      </div>

                  </div>


                  {/* <!-- 課程列表 -->*/}
                  <div class="course">

                      {/*<!-- list -->*/}
                      <a class="list-article" href="#">
                          <div class="list-article-pic"><img
                              src="https://www2apply.tccgc.gov.tw/IMG/FileUploads/FILE00000000230/22032409221127152370788.jpg"
                              alt="" /></div>
                          <div class="list-article-info">

                              <div class="list-article-textbox">
                                  <div class="text"><span>課程序號：AA001</span><span>班別：成人班</span><span>類別：藝工類</span></div>

                                  <h3>羊毛氈創作班 </h3>
                                  <div class="list-description">
                                      <div class="text"><span>星期二</span><span>上課日期：2022-09-24 ~
                                          2022-12-17</span><span>上課時段：9:30 – 16:30 </span></div>
                                      <div class="text"><span>名額：20</span><span>已報名：18</span></div>

                                  </div>
                              </div>
                              <div class="list-article-date">
                                  <div>
                                      <div class="num">剩餘<span>2</span>名額</div>
                                      <button type="button" onclick="window.location.href='course-registration.html';" class="btn-line btn-primary btn-small">我要報名</button>
                                  </div>
                              </div>
                          </div>
                      </a>
                      {/*    <!-- end list -->*/}

                      {/*<!-- list -->*/}
                      <a class="list-article" href="#">
                          <div class="list-article-pic"><img
                              src="https://www2apply.tccgc.gov.tw/IMG/FileUploads//20092301082153465366206.jpg"
                              alt="" /></div>
                          <div class="list-article-info">

                              <div class="list-article-textbox">
                                  <div class="text"><span>課程序號：AA001</span><span>班別：成人班</span><span>類別：舞蹈類</span></div>

                                  <h3>有氧舞蹈(一)混合有氧</h3>
                                  <div class="list-description">
                                      <div class="text"><span>星期二</span><span>上課日期：2022-09-24 ~
                                          2022-12-17</span><span>上課時段：9:30 – 16:30 </span></div>
                                      <div class="text"><span>名額：20</span><span>已報名：18</span></div>

                                  </div>
                              </div>
                              <div class="list-article-date">
                                  <div>
                                      <span class="deadline close">報名已截止 !</span>
                                  </div>
                              </div>
                          </div>
                      </a>
                      {/*<!-- end list -->*/}

                      {/*<!-- list -->*/}
                      <a class="list-article" href="#">
                          <div class="list-article-pic"><img
                              src="https://www2apply.tccgc.gov.tw/IMG/FileUploads//20092301082153465366206.jpg"
                              alt="" /></div>
                          <div class="list-article-info">

                              <div class="list-article-textbox">
                                  <div class="text"><span>課程序號：AA001</span><span>班別：成人班</span><span>類別：舞蹈類</span></div>

                                  <h3>有氧舞蹈(一)混合有氧</h3>
                                  <div class="list-description">
                                      <div class="text"><span>星期二</span><span>上課日期：2022-09-24 ~
                                          2022-12-17</span><span>上課時段：9:30 – 16:30 </span></div>
                                      <div class="text"><span>名額：20</span><span>已報名：18</span></div>

                                  </div>
                              </div>
                              <div class="list-article-date">
                                  <div>
                                      <div class="num">剩餘<span>2</span>名額</div>
                                      <button type="button" class="btn-line btn-primary btn-small">我要報名</button>
                                  </div>
                              </div>
                          </div>
                      </a>
                      {/*<!-- end list -->*/}

                      {/*<!-- list -->*/}
                      <a class="list-article" href="#">
                          <div class="list-article-pic"><img
                              src="https://www2apply.tccgc.gov.tw/IMG/FileUploads//22030209050782159388731.jpg"
                              alt="" /></div>
                          <div class="list-article-info">

                              <div class="list-article-textbox">
                                  <div class="text"><span>課程序號：AA001</span><span>班別：成人班</span><span>類別：藝工類</span></div>

                                  <h3>易經</h3>
                                  <div class="list-description">
                                      <div class="text"><span>星期二</span><span>上課日期：2022-09-24 ~
                                          2022-12-17</span><span>上課時段：9:30 – 16:30 </span></div>
                                      <div class="text"><span>名額：20</span><span>已報名：10</span></div>

                                  </div>
                              </div>
                              <div class="list-article-date">
                                  <div>
                                      <div class="num">剩餘<span>10</span>名額</div>
                                      <button type="button" class="btn-line btn-primary btn-small">我要報名</button>
                                  </div>
                              </div>
                          </div>
                      </a>
                      {/*<!--end list -->*/}

                      {/*<!-- 頁數 -->*/}
                      <div class="pagination">
                          <ul>
                              <li><a href="javascript:;">«</a></li>
                              <li class="active"><a href="javascript:;">1</a></li>
                              <li><a href="javascript:;">2</a></li>
                              <li><a href="javascript:;">3</a></li>
                              <li><a href="javascript:;">»</a></li>
                          </ul>
                      </div>

                  </div>
                  {/*<!-- end course -->*/}


              </div>
          </section>
     </>
  );
}
export default CourseList;

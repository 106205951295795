import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { checkAuthToken } from "../global/TokenUtil";
function Applydrawlots() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <section class="section-box">
              <div class="container">

                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1 class="text-center">報名抽籤</h1>


                  <div class="form-box">
                      <div class="form-container">

                          <div class="text-center">

                              <img src="images/apply-draw-lots.svg" />

                              <p class="my-4 mb-4 mb-md-5">於 10/10 公布錄取名單，錄取研習之友可點選繳費資訊連結完成繳費，才算完成報名。</p>

                              <button type="button" onclick="window.location.href='student-search.html';" class="btn btn-primary btn-large">確定</button>

                          </div>

                      </div>
                      {/*<!-- end form-container -->*/}
                  </div>
               {/*   <!-- end form-box -->*/}


              </div>
          </section>
     </>
  );
}
export default Applydrawlots;

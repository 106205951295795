import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { Helmet } from 'react-helmet'; //title
import { API_FAQDeptInfo } from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
function Faq() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [DeptInfo, setDeptInfo] = useState([{}]);
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--常見問題";
        var elem = document.querySelector("[href='" + window.location.hash + "']");
        if (elem != null) {
            elem.click();
        }
        API_FAQDeptInfo()
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    setDeptInfo(data.result);
                }
                else {
                    Swal.fire(data.message, "", "warning");
                    //alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })

    }, []);

    return (
        <>
            <Helmet>
                <title>臺中市政府文化局報名繳費系統-常見問題</title>
            </Helmet>

            <section className="section-box">
                <div className="container">


                    {/*導盲磚*/}
                    <div>
                        <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" className="a-acceky focusactive">:::</a>
                    </div>


                    <h1 className="text-center">常見問題</h1>


                    {/* 問題分類 */}
                    <div className="faq-tabs">
                        <h2 style={{ color: "transparent" }}>問題類型</h2>
                        <ul className="nav nav-tabs tabs-style-01" id="myTab" role="tablist">

                            <li>
                                <a className="active focusactive" href="#tab1" title="我要如何報名活動" role="tab" aria-controls="tab1"
                                    data-toggle="tab">
                                    我要如何報名活動
                                </a>
                            </li>

                            <li>
                                <a href="#tab2" className="focusactive" title="列印繳費單後如何繳費" role="tab" aria-controls="tab2" data-toggle="tab">
                                    列印繳費單後如何繳費
                                </a>
                            </li>

                            <li>
                                <a href="#tab3" className="focusactive" title="網站操作問題" role="tab" aria-controls="tab3" data-toggle="tab">
                                    網站操作問題
                                </a>
                            </li>

                            <li>
                                <a href="#tab4" className="focusactive" title="網站操作手冊" role="tab" aria-controls="tab4" data-toggle="tab">
                                    網站操作手冊
                                </a>
                            </li>
                            <li>
                                <a href="#tab5" className="focusactive" title="各單位連絡資訊" role="tab" aria-controls="tab5" data-toggle="tab">
                                    各單位連絡資訊
                                </a>
                            </li>
                        </ul>

                    </div>
                    {/* end 問題分類 */}


                    {/* 問題列表 */}
                    <div className="form-box">


                        {/* tab-content */}
                        <div className="tab-content" id="myTabContent">

                            {/* tab1 */}
                            <div class="tab-pane show active" id="tab1" role="tabpanel">

                                {/* 問題內容 */}
                                <div class="faq-box">

                                    {/* 1 */}
                                    <div class="faq-list">
                                        <h3>1. 我該如何加入研習之友？</h3>
                                        <p>
                                            加入研習之友，於報名各項課程時，僅需鍵入報名者身分證號碼，可縮短資料填寫時間，提高報名效率。<br />
                                            加入研習之友，請點選首頁右側「<b>登入</b>」之「<b>加入研習之友</b>」填寫相關資料後送出。<br />
                                            如學員欲更改信箱資料，請致電於承辦人員協助修正。
                                        </p>
                                    </div>

                                    {/*2 */}
                                    <div class="faq-list">
                                        <h3>2. 我要如何報名活動？</h3>
                                        <p>本局舉辦各類研習活動，採受益者付費方式辦理，歡迎有興趣者報名參加，</p>
                                        <p>各類研習課程均公告於網站「<b>課程</b>」專區，亦歡迎電話洽詢各單位活動相關訊息。</p>
                                        ※各項報名時程請參照「<b>最新消息</b>」、連絡資訊請參照「<b>各單位連絡資訊</b>」
                                        {/*<p>目前研習活動於每年春、秋季開設為期18週之藝文研習課程，並分三階段報名。</p>*/}
                                        {/*<ol>*/}
                                        {/*    <li>第一階段同時辦理現場敬老報名和網路報名，現場報名僅受理設籍本市65歲以上長者憑身分證明文件於報名期間內現場報名繳費。</li>*/}
                                        {/*    <li>第二階段為剩餘名額網路報名。</li>*/}
                                        {/*    <li>第三階段為現場報名，由中心服務臺受理現場報名及繳費作業。</li>*/}
                                        {/*</ol>*/}
                                        {/*※ 各項報名時程請參照「<b>最新公告</b>」。*/}
                                    </div>

                                    {/* 3 */}
                                    <div class="faq-list">
                                        <h3>3. 報名錄取後如何繳費？</h3>
                                        <p>現場報名採現場現金繳費，第一、二階段網路報名錄取後，可由首頁右側「<b>研習之友專區</b>」輸入身分證號碼查詢各門課程錄取情形，並自行列印繳款單至四大超商（7-11、全家、OK、萊爾富）繳費或透過
                                            ATM 轉帳。<br />
                                            ※ 繳款單列印請使用雷射印表機為佳，於報名期間亦可透過中心服務臺協助列印。</p>
                                    </div>

                                </div>
                                {/* end 問題內容 */}

                            </div>

                            {/* tab2 */}
                            <div className="tab-pane show " id="tab2" role="tabpanel">

                                {/* 問題內容 */}
                                <div className="faq-box">

                                    {/* 1 */}
                                    <div className="faq-list">
                                        <h3>1. 繳費後為何顯示未付款？</h3>
                                        <p>網路報名繳費是透過銀行代收系統，款項代收及轉帳入帳顯示需1至3個工作天，故無法即時顯示於網頁，請學員耐心等候。</p>
                                    </div>

                                    {/* 2 */}
                                    <div className="faq-list">
                                        <h3>2. 列印繳費單後如何繳費？</h3>
                                        <ol>
                                            <li>便利商店繳費：全臺 7-11、全家、OK、萊爾富皆可代收。</li>
                                            <li>臨櫃或 ATM 繳費：至臺灣銀行各分行臨櫃繳費，或透過 ATM 轉帳繳費。</li>
                                        </ol>
                                    </div>

                                    {/* 3 */}
                                    <div className="faq-list">
                                        <h3>3. 無法列印繳費單該怎麼繳費？</h3>
                                        <p>無法列印繳費單可至任一銀行 ATM
                                            辦理轉帳（依各銀行規定收取手續費），依螢幕指示操作，輸入指定帳號（14碼）轉帳即可。或於報名期間，由中心服務臺協助列印。</p>
                                    </div>

                                </div>
                                {/* end 問題內容 */}

                            </div>

                            {/* tab3 */}
                            <div className="tab-pane show" id="tab3" role="tabpanel">

                                {/* 問題內容 */}
                                <div className="faq-box">

                                    {/* 1 */}
                                    <div className="faq-list">
                                        <h3>1. 網站操作問題一</h3>
                                        <p>本網站的快速鍵﹝Accesskey﹞設定如下：<br />
                                            Alt+U：右上方功能區塊，包括回首頁、網站導覽、網站搜尋、字體選擇等。<br />
                                            Alt+C：中央內容區塊，為本頁主要內容區。<br />
                                            Alt+S：網站搜尋。<br />
                                            Alt+Z：下方功能區塊。<br />
                                            如果您的瀏覽器是Firefox，快速鍵的使用方法是 Shift+Alt+(快速鍵字母)，例如
                                            Shift+Alt+C會跳至網頁中央區塊，以此類推。</p>
                                    </div>

                                    {/* 2 */}
                                    <div className="faq-list">
                                        <h3>2. 網站操作問題二</h3>
                                        <p>本網站的快速鍵﹝Accesskey﹞設定如下：<br />
                                            Alt+U：右上方功能區塊，包括回首頁、網站導覽、網站搜尋、字體選擇等。<br />
                                            Alt+C：中央內容區塊，為本頁主要內容區。<br />
                                            Alt+S：網站搜尋。<br />
                                            Alt+Z：下方功能區塊。<br />
                                            如果您的瀏覽器是Firefox，快速鍵的使用方法是 Shift+Alt+(快速鍵字母)，例如
                                            Shift+Alt+C會跳至網頁中央區塊，以此類推。</p>
                                    </div>

                                </div>
                                {/* end 問題內容 */}

                            </div>

                            {/* tab4 */}
                            <div className="tab-pane show" id="tab4" role="tabpanel">

                                {/* 問題內容 */}
                                <div className="faq-box">

                                    {/*- 1 */}
                                    <div className="faq-list">
                                        <h3>1. 搜尋課程</h3>
                                        <p>點選「<b>課程</b>」搜尋課程並瀏覽研習課程內容，點選欲報名之班別。</p>
                                    </div>

                                    {/* 2 */}
                                    <div className="faq-list">
                                        <h3>2. 我要報名</h3>
                                        <p>點選「<b>報名</b>」，網路報名 1 人限報名 5 班。</p>
                                    </div>

                                    {/* 3 */}
                                    <div className="faq-list">
                                        <h3>3. 填寫資料</h3>
                                        <p>填寫報名資料檢查無誤後，按「確定報名」，確認出現「成功 報名成功」即完成報名程序。</p>
                                    </div>

                                    {/* 4 */}
                                    <div className="faq-list">
                                        <h3>4. 網路報名</h3>
                                        <ol>

                                            <li>
                                                <h3 style={{ left: "-53px", padding: "5px 10px" }}>A.</h3>
                                                <b>第一次報名（線上抽籤）</b><br />
                                                公告時間電腦隨機抽籤決定錄取名單，抽籤結果將於本網站公告。
                                            </li>
                                            <li>
                                                <b>第二次報名（剩餘名額報名）</b><br />
                                                名額未滿之班別，再次開放網路報名，採先後順序錄取。逾期未繳費視同放棄錄取資格，由系統判斷後，自動釋放名額供其他民眾報名。
                                            </li>
                                        </ol>
                                        <ol>

                                            <li>
                                                <h3 style={{ left: "-53px", padding: "5px 10px" }}>B.</h3>
                                                <b>依序錄取報名</b><br />
                                                網路報名後，採先後順序錄取。
                                            </li>
                                        </ol>
                                    </div>
                                    dxssx
                                    {/* 5 */}
                                    <div className="faq-list">
                                        <h3>5. 確認錄取</h3>
                                        <p>至「<b>研習之友專區</b>」，輸入身分證號碼查詢。</p>
                                    </div>

                                    {/* 6 */}
                                    <div className="faq-list">
                                        <h3>6. 繳納費用</h3>
                                        <p>錄取後3日內（含錄取當天），請自行列印繳款單繳費，不另行通知。<br />
                                            繳款單可於超商或銀行 ATM 繳款，逾期未繳費視同放棄錄取資格（亦可用手機出示繳款條碼給超商掃描繳費）。</p>
                                    </div>

                                    {/* 7 */}
                                    <div className="faq-list">
                                        <h3>7. 開課</h3>
                                        <p>依簡章規定各班上課時間及地點出席，不再另行通知。</p>
                                    </div>

                                </div>
                                {/* end 問題內容 */}

                            </div>
                            {/* tab4 */}
                            <div className="tab-pane show" id="tab5" role="tabpanel">

                                {/* 問題內容 */}
                                <div className="faq-box">
                                    <div className="news-content">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>單位</p>
                                                    </td>
                                                    <td>
                                                        <p>聯絡人</p>
                                                    </td>

                                                    <td>
                                                        <p>電話</p>
                                                    </td>

                                                    <td>
                                                        <p>服務時間</p>
                                                    </td>
                                                </tr>
                                                {
                                                    DeptInfo.map((x) => (
                                                        <tr>
                                                            <td>
                                                                <p dangerouslySetInnerHTML={{ __html: x.deptName }}></p>
                                                            </td>
                                                            <td>
                                                                <p dangerouslySetInnerHTML={{ __html: x.deptContact }}></p>
                                                            </td>

                                                            <td>
                                                                <p dangerouslySetInnerHTML={{ __html: x.deptTel }}></p>
                                                            </td>

                                                            <td>
                                                                <p dangerouslySetInnerHTML={{ __html: x.deptWorkDay }}></p>
                                                            </td>
                                                        </tr>

                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* end 問題內容 */}

                            </div>
                        </div>
                        {/* end tab-content */}


                    </div>
                    {/* end 問題列表 */}

                </div>
            </section>



        </>
    );
}
export default Faq;

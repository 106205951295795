import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_Course, API_CourseHome, API_CenterLogo } from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";

function Courselisttable() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const [dayAll, setdayAll] = useState(true);

    const [date, setDate] = useState(new Date());
    //分館篩選
    const [MDeptName, setMDeptName] = useState([{}]);
    const [DeptAll, setDeptAll] = useState(true);

    const [DeptName, setDeptName] = useState([{}]);
    const [typeAll, settypeAll] = useState(true);
    const [typeCAll, settypeCAll] = useState(true);
    const [Carousel, setCarousel] = useState([{}]);
    //星期
    const [day, setday] = useState([{}]);
    //課程類別
    const [couCType, setcouCType] = useState([{}]);
    const [course, setCourse] = useState([{}]);
    const [selected, setSelected] = useState([]);

    //課程班別
    const [couType, setcouType] = useState([{}]);
    const DeptID = queryParams.get("DeptID");

    const [currentDate, setCurrentDate] = useState('');

    //logo
    const [Logo, setLogo] = useState({}); 
    useEffect(() => {

        const getCurrentDate = () => {
            //2024-07-09T12:18:00
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const hour = String(today.getHours()).padStart(2, '0');
            const min = String(today.getMinutes()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}T${hour}:${min}:00`;
            setCurrentDate(formattedDate);

           
        };
        getCurrentDate();

        localStorage.setItem("CouUrl", document.baseURI);
        if (DeptID == null) {
            //alert("無此頁面。");
            Swal.fire("無此頁面", "", "info").then(ok => {
                history.goBack();
            });
        }

        API_CourseHome({ "DeptID": DeptID })
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {

                    document.title = "臺中市政府文化局報名繳費系統--課程報名(" + data.result.deptName.DeptName+")";
                    setCarousel(data.result.carouselList);
                    setDeptName(data.result.deptName);
                    //分館
                    setMDeptName(data.result.mDeptNameList);
                
                    setday(data.result.weekList);
                    setcouCType(data.result.courseTypeCList);
                    setcouType(data.result.courseTypeList);
                  
                }
                else {
                    console.log(data.message);
                    window.location = "/";
                }
            })
            .catch(err => {
                console.log(err);
            })
        API_Course({ "DeptID": DeptID })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {                  
                    setCourse(data.result.courseList);
                  
                }
                else {
                    console.log(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })

        API_CenterLogo({ "DeptID": DeptID })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setLogo(data.result);
                }
                else {
                    console.log(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })


        

    }, []);
    document.onkeydown = function (event) {
        var target, code, tag;
        if (!event) {
            event = window.event;
            code = event.keyCode;
            if (code == 32 && event.target.type == "checkbox") {
                event.target.click();
            }
        } else {
            target = event.target; //針對遵循w3c標準的瀏覽器
            code = event.keyCode;
            if (code == 32 && event.target.type == "checkbox") {
                event.target.click();
            }
        }
    };
    function searchCourse() {
        var days = document.getElementsByName('days');
        var mDept = document.getElementsByName('DeptNames');
        var types = document.getElementsByName('Types');
        var CTypes = document.getElementsByName('CTypes');
        var day = ""; var mDepts = ""; var type = ""; var CType = "";

        var daychkcnt = 0;
        console.log();
        //日期
        days.forEach(function (elem) {
            if (elem.checked) {
                day += elem.value + ',';
                daychkcnt++;
            }
        });
        day = day.substring(0, day.length - 1);
        if (day == '') {// && !document.getElementById('dayAll').checked
            day = '-1';
        }
      
        if (mDept.length != 0) {
            //分館
            mDept.forEach(function (elem) {
                if (elem.checked)
                    mDepts += elem.value + ',';

            });
            mDepts = mDepts.substring(0, mDepts.length - 1);

            if (mDepts == '' && !document.getElementById('DeptAll').checked) {
                mDepts = '-1';
            }

        }
       
        if (days.length == daychkcnt) {
            day = '';
        }
        //班別
        types.forEach(function (elem) {
            if (elem.checked)
                type += elem.value + ',';
        });
        type = type.substring(0, type.length - 1);
        if (type == '') { // && !document.getElementById('typeAll').checked
            type = '-1';
        }

        //類別
        CTypes.forEach(function (elem) {
            if (elem.checked )
                CType += elem.value + ',';
        });
        CType = CType.substring(0, CType.length - 1);
        if (CType == '' ) {
            CType = '-1';
        }

        console.log(mDepts)
        console.log(type)
        console.log(CType)

        var dt = {
            DeptID: DeptID,
            MDeptID: mDepts,
            CouTypeID: type,
            CouTypeCID: CType,
            CodeValue : day
        }

        API_Course(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (data.result.courseList == null) {
                        setCourse([{}]);
                    }
                    else {
                        setCourse( data.result.courseList);
                    }
                    
                }
                else if (data.code == "4038"){
                    setCourse([{}]);
                }
                else {
                    console.log(data.message);
                } 
            })
            .catch(err => {
                console.log(err);
            })
        
    }
    function dayAllChk(e) {
        console.log(e);
        var b = !dayAll;
        setdayAll(b);
        var l = document.getElementsByName('days');
        l.forEach(
            function (e) {
                e.checked = b;
            }
        )
        searchCourse();
    }
    function dayclick() {
        var cnt = 0;
        var l = document.getElementsByName('days');
        l.forEach(function (elem) {
            if (elem.checked)
                cnt++;
        });
        if (cnt == l.length) {
            setdayAll(true);
        }
        else {
            setdayAll(false);
        }
        searchCourse();
    }

    //分館的ck
    function DeptAllChk() {
        var b = !DeptAll;
        setDeptAll(b);
        var l = document.getElementsByName('DeptNames');
        l.forEach(
            function (e) {
                e.checked = b;
            }
        )
        searchCourse();
    }

    function Deptclick() {
        var cnt = 0;
        var l = document.getElementsByName('DeptNames');
        l.forEach(function (elem) {
            if (elem.checked)
                cnt++;
        });
        if (cnt == l.length) {
            setDeptAll(true);
        }
        else {
            setDeptAll(false);
        }
        searchCourse();
    }

    function typeAllChk() {
        var b = !typeAll;
        settypeAll(b);
        var l = document.getElementsByName('Types');
        l.forEach(
            function (e) {
                e.checked = b;
            }
        )
        searchCourse();
    }

    function typeclick() {
        var cnt = 0;
        var l = document.getElementsByName('Types');
        l.forEach(function (elem) {
            if (elem.checked)
                cnt++;
        });
        if (cnt == l.length) {
            settypeAll(true);
        }
        else {
            settypeAll(false);
        }
        searchCourse();
    }

    function CtypcAllChk() {
        var b = !typeCAll;
        settypeCAll(b);
        var l = document.getElementsByName('CTypes');
        l.forEach(
            function (e) {
                e.checked = b;
            }
        )
        searchCourse();
    }

    function Ctypeclick() {
        var cnt = 0;
        var l = document.getElementsByName('CTypes');
        l.forEach(function (elem) {
            if (elem.checked)
                cnt++;
        });
        if (cnt == l.length) {
            settypeCAll(true);
        }
        else {
            settypeCAll(false);
        }
        searchCourse();
    }

  return (
      <>
         
          <section class="section-box bg-white">
              
              <div class="container">                 
                  <div class="course">
                      <div class="course-serach">
                          <div class="course-logo">
                              {/*<img src="images/臺中市文化局.png" alt="" DeptID="2" />*/}
                              {/*<img src="images/大墩文化中心.png" alt="" />*/}
                              {/*<img src="images/葫蘆墩文化中心.png" alt="" />*/}
                              {/*<img src="images/港區藝術中心.png" alt="" />*/}
                              {/*<img src="images/屯區藝文中心.png" alt="" />*/}
                              {/*<img src="images/纖維工藝展覽中心.png" alt="" />*/}
                              {/*<img src="images/臺中市立圖書館.png" alt="" />*/}
                              {/*<img src="images/臺中市文資處.png" alt="" />*/}

                            
                            
                              <img src={Logo==null ? null  : Logo.attfPath} alt="" />
                             
                            
                          </div>

                          <div class="course-pic">
                              {/*<!-- owl-carousel -->*/}
                              
                              <div class="course-pic">
                                  <div class="owl-carousel owl-theme dots-style-2">
                                      {/*    Carousel.map((x) => (*/}
                                      {/*<div class="item">*/}
                                      {/*    <a href={x.CaroUrl} title="" target="_blank"><img src={x.CaroImage} /></a>*/}
                                      {/*</div>*/}
                                      {/*    ))*/}
                                      {
                         
                                          Carousel.length > 0 ?
                                              <div class="item">
                                                  <a href={Carousel[0].CaroUrl} className="focusactive" target="_blank" title="另開新視窗"><img src={Carousel[0].CaroImage} alt={Carousel[0].CaroCaption} /></a>
                                              </div>
                                              : <></>
                                        
                                      }
                                  </div>
                                  {/*<!-- end owl-carousel -->*/}
                              </div>
                              {/*<!-- end owl-carousel -->*/}
                          </div>


                          <div class="course-wall"><h1 style={{ color: "transparent", height: "0px" }}>課程報名表</h1><h2 style={{ color: "transparent", height: "0px" }}>中心類別</h2></div>

                         
                          
                          <div class="course-select">
                         
                              <div class="course-title"><a accessKey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a>{DeptName.DeptName}</div>
                              <h3>課程星期</h3>
                              {/*<!-- 單位 -->*/}
                              <div class="course-unit">
                                  <label>
                                      <input type="checkbox" id="dayAll" name="" checked={dayAll} onChange={dayAllChk} />全部
                                  </label>
                                  {
                                      day.map((x) => (
                                          <label>
                                              <input type="checkbox" name="days" onChange={dayclick} value={x.CodeValue} defaultChecked={true} />{x.CodeText}
                                          </label>
                                      ))                                      
                                  }
                              </div>

                              <div style={{ display:"none" }}>
                                  {
                                      DeptID == 4 ? <h3>圖書分館</h3> : null
                                  }
                                  
                                  {/*<!-- 分館 -->*/}
                                  {
                                      DeptID == 4 ?
                                          <div class="course-unit">
                                              <label>
                                                  <input type="checkbox" id="DeptAll" name="" checked={DeptAll} onClick={DeptAllChk} />全部
                                              </label>
                                              {
                                                  MDeptName.map((x) => (
                                                      <label>
                                                          <input type="checkbox" name="DeptNames" onClick={Deptclick} value={x.DeptID} defaultChecked={true} />{x.DeptName}
                                                      </label>
                                                  ))
                                              }
                                          </div>
                                          :null
                                  }
                              </div>

                              <h3>選擇班別</h3>
                              {/*<!-- 單位 -->*/}
                              <div class="course-unit">
                                  <label>
                                      <input type="checkbox" id="typeAll" value="" checked={typeAll} onClick={typeAllChk}  />全部
                                  </label>
                                  {
                                      couType.map((x) => (
                                          <label>
                                              <input type="checkbox" name="Types" onClick={typeclick} value={x.CouTypeID} defaultChecked={true} />{x.CouTypeName}
                                          </label>
                                          ))
                                  }

                              </div>
                              {/*<!-- 單位 -->*/}

                              <h3>課程類別</h3>
                              <div class="course-kind">
                                  <label>
                              {/*<!--  類別 -->*/}
                                      <input type="checkbox" id="CtypeAll" value="" checked={typeCAll} onClick={CtypcAllChk} />全部
                                  </label>
                                  {
                                      couCType.map(
                                          (x) => (
                                              <label>
                                                  <input type="checkbox" name="CTypes" onClick={Ctypeclick} value={x.CouTypeCID} defaultChecked={true} />{x.CouTypeName}
                                              </label>
                                          )
                                      )
                                  }
                              </div>
                          </div>
                          {/*<!--  end course-serach -->*/}
                      </div>
                      {/*<!-- course -->*/}
                  </div>
              </div>
          </section>


          <section class="section-box bg-gray-100">
              <div class="container" style={{ maxWidth:"95em" }}>


                  {/*<!-- 改變排列 -->*/}
                  <div class="radio-list-style">

                      {/*<div class="input-container">*/}
                      {/*    <input class="radio-button" value="list-sol-style3" name="list-template" type="radio"*/}
                      {/*        onClick={() => { history.push('/CourseList'); }}/>*/}
                      {/*        <div class="radio-tile">*/}
                      {/*            <div class="icon">*/}
                      {/*                <i class="fa fa-align-justify"></i>*/}
                      {/*            </div>*/}
                      {/*        </div>*/}
                      {/*</div>*/}



                      <div class="input-container">
                          <input class="radio-button " value="list-sol-style2" name="list-template" type="radio" title="列表"
                              onClick={() => { history.push('/Courselisttable'); }} checked/>
                              <div class="radio-tile ">
                                  <div class="icon">
                                      <i class="fa fa-table"></i>
                                  </div>
                              </div>
                      </div>


                  </div>


                  {/*<!-- 課程列表 -->*/}
                  <div class="course">



                      {/*<!-- table -->*/}
                      <div class="table-box table-responsive">
                          <table>
                              <thead>
                                  <tr>

                                      <th>課程序號</th>
                                      <th>班別</th>
                                      <th>類別</th>
                                      <th>課程名稱</th>
                                      <th>教師</th>
                                      <th>課程星期</th>
                                      <th>上課日期</th>
                                      <th>上課時段</th>
                                      <th>名額<br /><p style={{ fontSize:"0.8em" }}>(報名/錄取/剩餘)</p></th>
                                      <th>狀態</th>
                                      <th>功能</th>
                                      <th>名單 </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {course.map((x) => (
                                      x.CouSID  == null ? null :
                                          <tr>
                                              <td hidden>{x.CouID}</td>
                                              <td>{x.CouNo}</td>
                                              <td>
                                                  {x.CTCouTypeName}
                                              </td>
                                              <td>{x.CouTypeName}</td>
                                              <td>
                                                  <a href="#" style={{ color: 'blue', cursor: 'pointer' }} onClick={() => { window.location = '/CourseLessonInfo?CouID=' + x.CouID + "&DeptID=" + DeptID }} >{x.CouName}</a>
                                              </td>
                                              <td>{x.CouTeacherName}</td>
                                              <td>{x.CouWeeks}</td>
                                              <td>{x.CouStartDate} ~ {x.CouCloseDate}</td>
                                              <td>{x.CouStartTime} – {x.CouCloseTime}</td>
                                              <td>{x.RegCnt}/{x.DistCnt}/{x.CouAmount - x.DistCnt}</td>{/*(已報名人數/錄取名額/報名名額-已錄取人數=剩餘名額)*/}
                                              <td>{x.CouStatusText}</td>
                                              {
                                                  x.CouStatus == "1" && (x.CouAmount - x.DistCnt) > "0" && (x.CouSTOpenDate > currentDate) ?
                                                    <td><button onClick={() => { window.location = '/CourseRegistration?CouID=' + x.CouID + "&DeptID=" + DeptID; }} className="btn-line btn-primary btn-small focusablack">報名</button></td>
                                                    :
                                                  x.CouStatus == "1" && (x.CouAmount - x.DistCnt) <= "0" ?
                                                    <td><button class="btn-line btn-primary btn-small" disabled>額滿</button></td>
                                                    :
                                                  x.CouStatus == "1" && (x.CouSTOpenDate < currentDate) ?
                                                    <td><button class="btn-line btn-primary btn-small" disabled>報名中</button></td>
                                                    :
                                                  x.CouStatus == "0" ? <td><button class="btn-line btn-primary btn-small" disabled>未開放</button></td>
                                                    :
                                                    <td><button class="btn-line btn-primary btn-small" disabled>已截止</button></td>
                                              
                                              }
                                              {/*<td><button onClick={() => { history.push('/CourseRegistration'); }} class="btn-line btn-primary btn-small">報名</button></td>*/}
                                              <td>
                                                  {/*{x.CouStatus == "2" ? <button onClick={() => { history.push('/CourseAdmissionList?CouID=' + x.CouID); }} class="btn-line btn-primary btn-small">檢視</button> : null}*/}
                                                  <button onClick={() => { history.push('/CourseAdmissionList?CouID=' + x.CouID); }} class="btn-line btn-primary btn-small">檢視</button> 
                                              </td>
                                          </tr>

                                  ))}
                              </tbody>

                          </table>
                      </div>








                      {/*<!-- 頁數 -->*/}
                      <div class="pagination">
                          <ul>
                              <li><a href="javascript:;" className="focusactive">«</a></li>
                              <li class="active"><a href="javascript:;" className="focusactive">1</a></li>
                              <li><a href="javascript:;" className="focusactive">»</a></li>
                          </ul>
                      </div>



                  </div>
                  {/*<!-- end course -->*/}


              </div>
          </section>
     </>
  );
}
export default Courselisttable;

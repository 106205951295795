import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_AdmissList } from "../global/constants";
import Swal from "sweetalert2";

function CourseAdmissionList() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const [AdmissList, setAdmissList] = useState([{}]);
    const CouID = queryParams.get("CouID");
    useEffect(() => {
        API_AdmissList({ CouID: CouID })
            .then((res) => res.data)
            .then(data => {
                if (data.code == "0000") {
                    setAdmissList(data.result);
                }
                //else {
                //    setAdmissList([{}]);
                //    Swal.fire(data.message, "錯誤", "error").then
                //        ((res) => {
                //            window.history.back();
                //        });
                //}

            }).catch(e => {
                console.log(e);
            })
  },[]);

  return (
      <>
          <section class="section-box bg-gray-100">
              <div class="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1></h1>
               
                  <h1 class="text-center">  {AdmissList[2]?.CouName} - 錄取名單</h1>
                  
                  <div class="admission-box">


                      <div class="table-box">
                          <table>
                              <thead>
                                  <tr>
                                      <th>序號</th>
                                      <th>報名編號</th>
                                      <th>姓名</th>
                                      <th>性別</th>
                                      <th>報名時間</th>
                                      <th>報名狀態</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {
                                      AdmissList.map((x) => (
                                          <tr>
                                           
                                              <td>{x.Serial}</td>
                                              <td>{x.SNo}</td>
                                              <td>{x.UserName}</td>
                                              <td>{x.Sex}</td>
                                              <td>{x.RegDate}</td>
                                              <td>
                                              {
                                                  <b class="text-red"> {x.state} </b>
                                              }
                                              </td>
                                          </tr>
                                      ))
                                  }
                              </tbody>
                          </table>
                      </div>


                     
                      {/*<div class="pagination">*/}
                      {/*    <ul>*/}
                      {/*        <li><a href="javascript:;">«</a></li>*/}
                      {/*        <li class="active"><a href="javascript:;">1</a></li>*/}
                      {/*        <li><a href="javascript:;">2</a></li>*/}
                      {/*        <li><a href="javascript:;">3</a></li>*/}
                      {/*        <li><a href="javascript:;">»</a></li>*/}
                      {/*    </ul>*/}
                      {/*</div>*/}



                  </div>
                  


              </div>
          </section>
     </>
  );
}
export default CourseAdmissionList;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import { Helmet } from 'react-helmet'; //title
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_ForgetInfo, API_ForgetPwd, API_ResetPwd } from "../global/constants"
import { checkAuthToken, getAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
function ResetPwd() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const ID = queryParams.get("ID");
    const [Email, setEmail] = useState('');
    const [Second, setSecond] = useState(0);
    const [Pwd, setPwd] = useState('');
    const [rePwd, setrePwd] = useState('');
    const [CertCode, setCertCode] = useState('');
    const [Chk, setChk] = useState({ PwdChk: false, rePwdChk: false, diffPwdChk: false, CertChk: false, RegChk: false });
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--重置密碼";
        API_ForgetInfo({ ID: ID })
            .then(res => res.data)
            .then(data => {
                if (data.code == "0000") {
                    setEmail(data.result.email);
                    if (data.result.second != null) {
                        countdown(data.result.second)
                        setSecond(data.result.second);
                    }
                }
                else {
                    Swal.fire(data.message, "錯誤", "error").then(ok => {
                        window.history.back();
                    });
                }
            })
    }, []);
    function Reset() {
        var isOK = true;
        console.log(Chk);
        if (Pwd == '') {
            setChk(prev => { prev.PwdChk = true; return prev; });
            isOK = false;
            Swal.fire("請輸入密碼", "錯誤", "error");
            return;
        }
        if (rePwd == '') {
            setChk(prev => { prev.rePwdChk = true; return prev; });
            isOK = false;
            Swal.fire("請輸入確認密碼", "錯誤", "error");
            return;
        }
        if (CertCode == '') {
            setChk(prev => { prev.CertChk = true; return prev; });
            isOK = false;
            Swal.fire("請輸入驗證碼", "錯誤", "error");
            return;
        }

        if (Pwd != rePwd) {
            setChk(prev => { prev.diffPwdChk = true; return prev; });
            isOK = false;
            Swal.fire("兩次密碼不相符", "驗證錯誤", "error");
            return;
        }
        var reg = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);
        if (!reg.test(Pwd)) {
            isOK = false;
            Swal.fire("密碼至少12碼，須包含大寫英文、小寫英文、數字、特殊符號。", "驗證錯誤", "error");
            return;
        }
        console.log(Chk);
        if (isOK) {
            var loading = document.getElementById('loading');
            loading.style.display = "block";
            API_ResetPwd({ ID: ID, Pwd: Pwd, rePwd: rePwd, CertCode: CertCode })
                .then(res => res.data)
                .then(data => {
                    loading.style.display = "none";
                    if (data.code == "0000") {
                        Swal.fire("更改成功，請用新密碼登入", "成功", "success")
                            .then(ok => {
                                localStorage.clear();
                                window.location = "/IdentityVerify";
                            });

                    }
                    else {
                        Swal.fire(data.message, "錯誤", "error");
                    }
                })
        }
    }
    function setValue(e, type) {
        if (type == "pwd") {
            setPwd(e.target.value);
            if (e.target.value != '') {
                setChk(prev => { prev.PwdChk = false; return prev; });
                if (e.target.value == rePwd) { setChk(prev => { prev.diffPwdChk = false; return prev; }); }
                else { setChk(prev => { prev.diffPwdChk = true; return prev; }); }
            }
            else { setChk(prev => { prev.PwdChk = true; return prev; }) }
        }
        if (type == "repwd") {
            setrePwd(e.target.value);
            if (e.target.value != '') {
                setChk(prev => { prev.rePwdChk = false; return prev; });
                if (e.target.value == Pwd) { setChk(prev => { prev.diffPwdChk = false; return prev; }); }
                else { setChk(prev => { prev.diffPwdChk = true; return prev; }); }
            }
            else { setChk(prev => { prev.rePwdChk = true; return prev; }) }
        }
        if (type == "cert") {
            setCertCode(e.target.value);
            if (e.target.value != '') { setChk(prev => { prev.CertChk = false; return prev; }) }
            else { setChk(prev => { prev.CertChk = true; return prev; }) }
        }


    }
    function reSendCert() {
        API_ForgetPwd({ ID: ID })
            .then(res => res.data)
            .then(data => {
                if (data.code == "0000") {
                    Swal.fire("已送驗證碼至信箱，請收取驗證碼。", "成功", "success");
                    countdown(data.result.second);

                }
                else if (data.code == "1045") {
                    Swal.fire("驗證碼尚未過期，請至信箱收取驗證碼。", "訊息", "info");
                    countdown(data.result.second);
                }
                else {
                    Swal.fire(data.message, "錯誤", "error");
                }
            }).catch(e => {
                console.log(e);
            });
    }
    var timeInter;
    function countdown(second) {
        //  
        var timer = document.getElementById('count');
        var number = second;
        timer.hidden = false;
        clearInterval(timeInter);
        timeInter = setInterval(function () {
            number--;
            if (number <= 0) {
                clearInterval(timeInter);
                timer.hidden = true;
            }
            var min = Math.floor(number / 60);
            var sec = number % 60;
            timer.innerText = "密碼到期剩餘:" + min + "分" + sec + "秒";
        }, 1000);
    }

    const showpassword1 = () => {
        if (document.getElementById('password').type === "password") {
            document.getElementById('password').type = "text";
            document.getElementById('eye1').classList.add('active');
        } else {
            document.getElementById('password').type = "password";
            document.getElementById('eye1').classList.remove('active');
        }
    }

    const showpassword2 = () => {
        if (document.getElementById('password2').type === "password") {
            document.getElementById('password2').type = "text";
            document.getElementById('eye2').classList.add('active');
        } else {
            document.getElementById('password2').type = "password";
            document.getElementById('eye2').classList.remove('active');
        }
    }

    return (
        <>
            <section className="section-box">
                <div className="container">

                    <h1 className="text-center">修改密碼</h1>


                    <div className="form-box">



                        <div className="form-container login-container">

                            <div className="form-item">
                                <div className="field">您的電子信箱：</div>
                                <div className="value">
                                    <b className="ml-md-2">{Email}</b>
                                </div>
                            </div>


                            <div className={`form-item show-notes  ${Chk.PwdChk ? 'show-help' : ''}`}>
                                <div className="field">請輸入新密碼</div>
                                <div className="value">
                                    <input type="password" id="password" onChange={(e) => setValue(e, "pwd")} placeholder="請輸入新密碼" />
                                    <i className="eye" id="eye1" onClick={showpassword1}></i>
                                </div>
                                <span className="notes" >
                                    <b>※</b> 密碼至少12碼，須包含大寫英文、小寫英文、數字、特殊符號。
                                </span>
                                <span className="help-word">請輸入新密碼</span>
                            </div>
                            <div className={`form-item ${Chk.rePwdChk ? 'show-help' : ''}`}>
                                <div className="field">再次確認密碼</div>
                                <div className="value">
                                    <input type="password" id="password2" onChange={(e) => setValue(e, "repwd")} placeholder="再次確認密碼" />
                                    <i className="eye" id="eye2" onClick={showpassword2}></i>
                                </div>
                                <span className="help-word">再次確認密碼</span>
                            </div>
                            <div className={`form-item ${Chk.diffPwdChk ? 'show-help' : ''}`}>
                                <span className="help-word">兩次密碼不相符</span>
                            </div>
                            <div className={`form-item ${Chk.CertChk ? 'show-help' : ''}`}>
                                <div className="field">請輸入驗證碼</div>
                                <div className="value">
                                    <input type="text" onChange={(e) => setValue(e, "cert")} placeholder="請輸入驗證碼" />
                                </div>
                                <span className="help-word">請輸入驗證碼</span>
                            </div>
                            <div className="form-item btn-area mt-5">
                                <button type="button" onClick={Reset} className="btn btn-primary btn-large mr-2">確認送出</button>
                            </div>
                            <div className="form-item btn-area btn-no-style show-notes">
                                <button type="button" value="reSend" onClick={reSendCert} className="btn focusactive">重送驗證碼</button>
                                <span class="notes" id="count" hidden style={{ width: "auto", padding: "8px 10px" }}><b>※</b>密碼到期剩餘:0分0秒</span>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}
export default ResetPwd;

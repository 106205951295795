import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import Swal from 'sweetalert2'
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { getAuthToken, checkAuthToken } from "../global/TokenUtil";
import { API_UserRegCous, API_UserInfoBK, key } from '../global/constants';
import CryptoJS from 'crypto-js';

function StudentSearchCosure() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [listShow, setlistShow] = useState(false);
    const [ID, setID] = useState("");
    const [UserData, setUser] = useState({ userIDNo: "", name: "" });
    const [UserRegList, setUserRegList] = useState([{}]);

    const [UserIDNo, setUserIDNo] = useState("");
    const [UserName, setUserName] = useState("");
    const [UserSex, setUserSex] = useState("");
    const [Birday, setBirday] = useState("1911-01-01");
    const [CityNo, setCityNo] = useState("");
    const [AreaNo, setAreaNo] = useState("");
    const [UserPhone, setUserPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [Contact, setContact] = useState("");
    const [ContactPhone, setContactPhone] = useState("");
    const [isForeig, setisForeig] = useState(false);
    const [AreaList, setAreaList] = useState([{}]);
    const [IsGetInfo, setIsGetInfo] = useState(false);

    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--查詢已報名課程";
        if (getAuthToken() == "xxx") {

            window.location = "/IdentityVerify";


        }

        if (localStorage.getItem("UserData") != null) {

           // GetUserInfo(JSON.parse(localStorage.getItem("UserData")).userIDNo);
            if (localStorage.getItem("UserData") != null) {
                let UserData = JSON.parse(localStorage.getItem("UserData"));
                let date = new Date(UserData.birDay);
                var year = date.toLocaleString("default", { year: "numeric" }).replace("年", "");
                var month = date.toLocaleString("default", { month: "2-digit" }).replace("月", "");
                setUserIDNo(UserData.userIDNo);
                setUserName(UserData.userName);
                setUserSex(UserData.userSex);
                setCityNo(UserData.cityNo);
                setArea(UserData.cityNo);
                setAreaNo(UserData.areaNo);
                setBirday(year + '-' + month);
                setUserPhone(UserData.userPhone);
                setEmail(UserData.email);
                setContact(UserData.contact);
                setContactPhone(UserData.contactPhone);
                setisForeig(UserData.isForeig);
                setIsGetInfo(true);
                getData(UserData.userIDNo, UserData.userPhone);
            }
        }
        
    }, []);

    function aesDecryptBase64(Source, cryptoKey) {
        try {
            // 生成 SHA256 哈希作为密钥
            const key = CryptoJS.SHA256(cryptoKey);

            // 生成 MD5 哈希作为 IV
            const iv = CryptoJS.MD5(cryptoKey);

            // 解密数据
            const decrypted = CryptoJS.AES.decrypt(Source, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            // 将解密后的数据从 WordArray 转换为 UTF-8 字符串
            const decryptedStr = CryptoJS.enc.Utf8.stringify(decrypted);

            return decryptedStr;
        } catch (err) {
            console.error('Decryption failed:', err);
            return null;
        }
    }

    function GetUserInfo(tmpUserIDNo) {
        //() => { history.push('/IdentityVerify'); }
        API_UserInfoBK({ UserIDNo: tmpUserIDNo })
            .then(res => res.data)
            .then(data => {
                if (data.code == "0000") {
                    //Lee Jason解

                    var originalText = aesDecryptBase64(data.result, key);
                    var _data = JSON.parse(originalText);
                    //Lee

                    //TODO 記得把data屬性轉換過
                    let date = new Date(_data.BirDay);
                    var year = date.toLocaleString("default", { year: "numeric" }).replace("年", "");
                    var month = date.toLocaleString("default", { month: "2-digit" }).replace("月", "");
                    setUserIDNo(_data.UserIDNo);
                    setUserName(_data.UserName );
                    setUserSex(_data.UserSex);
                    setCityNo(_data.CityNo);
                    setArea(_data.CityNo);
                    setAreaNo(_data.AreaNo);
                    setBirday(year + '-' + month);
                    setUserPhone(_data.UserPhone);
                    setEmail(_data.Email);
                    setContact(_data.Contact);
                    setContactPhone(_data.ContactPhone);
                    setisForeig(_data.IsForeig);
                    setIsGetInfo(true);
                    getData(_data.UserIDNo, _data.UserPhone);
                }
                else {
                    Swal.fire(data.message, "", "warning");
                    setIsGetInfo(false);
                }
            })
    }
   
    function getData(UserIDNo, UserPhone) {
        setUserName("");
        API_UserRegCous({
            UserIDNo: UserIDNo,
            PhNumber: UserPhone
        })
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                //if (data.code == "0000") {
                console.log(data);
                setUserRegList(data.result.ur);
                setUserName(data.result.userName);
                setlistShow(true);
                //}
                //else {
                //    console.log('dataCode',data.code)
                //    console.log(data.message);
                //    //window.location = "/";
                //}
            })
            .catch(err => {
                console.log(err);
            })
    }

    function setArea(CityNo) {
        if (CityNo == "1") {
            setAreaList(
                [
                    { Value: 1, Text: "中區" },
                    { Value: 2, Text: "東區" },
                    { Value: 3, Text: "南區" },
                    { Value: 4, Text: "西區" },
                    { Value: 5, Text: "北區" },
                    { Value: 6, Text: "北屯區" },
                    { Value: 7, Text: "西屯區" },
                    { Value: 8, Text: "南屯區" },
                    { Value: 9, Text: "太平區" },
                    { Value: 10, Text: "大里區" },
                    { Value: 11, Text: "霧峰區" },
                    { Value: 12, Text: "烏日區" },
                    { Value: 13, Text: "豐原區" },
                    { Value: 14, Text: "后里區" },
                    { Value: 15, Text: "石岡區" },
                    { Value: 16, Text: "東勢區" },
                    { Value: 17, Text: "和平區" },
                    { Value: 18, Text: "新社區" },
                    { Value: 19, Text: "潭子區" },
                    { Value: 20, Text: "大雅區" },
                    { Value: 21, Text: "神岡區" },
                    { Value: 22, Text: "大肚區" },
                    { Value: 23, Text: "沙鹿區" },
                    { Value: 24, Text: "龍井區" },
                    { Value: 25, Text: "梧棲區" },
                    { Value: 26, Text: "清水區" },
                    { Value: 27, Text: "大甲區" },
                    { Value: 28, Text: "外埔區" },
                    { Value: 29, Text: "大安區" }
                ]
            );

        }
        else {
            setAreaList(
                [
                    { Value: 2, Text: "臺北市" },
                    { Value: 3, Text: "新北市" },
                    { Value: 4, Text: "桃園市" },
                    { Value: 5, Text: "臺南市" },
                    { Value: 6, Text: "高雄市" },
                    { Value: 7, Text: "基隆縣" },
                    { Value: 8, Text: "新竹市" },
                    { Value: 9, Text: "嘉義市" },
                    { Value: 10, Text: "新竹縣" },
                    { Value: 11, Text: "苗栗縣" },
                    { Value: 12, Text: "彰化縣" },
                    { Value: 13, Text: "南投縣" },
                    { Value: 14, Text: "雲林縣" },
                    { Value: 15, Text: "嘉義縣" },
                    { Value: 16, Text: "屏東縣" },
                    { Value: 17, Text: "宜蘭縣" },
                    { Value: 18, Text: "花蓮縣" },
                    { Value: 19, Text: "臺東縣" },
                    { Value: 20, Text: "澎湖縣" },
                    { Value: 21, Text: "金門縣" },
                    { Value: 22, Text: "連江縣" },
                    { Value: 1, Text: "其他" }
                ]
            );
        }
    }

    return (
        <>

            <section className="section-box bg-white">
                <div className="form-container">
                    <h2 class="text-line my-5">基本資料</h2>
                    <div class="form-item">
                        <div class="field"><label htmlFor="UName">姓名</label></div>
                        <div class="value">
                            <input type="text" id="UName" className="focusactive" placeholder="請填寫真實中文姓名" value={UserName} disabled={localStorage.getItem("UserData") != null || IsGetInfo ? "disabled" : ""} onChange={(e) => { setUserName(e.target.value) }} required />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field"><label htmlFor="Ubirday">出生年月</label></div>
                        <div class="value">
                            <input type="month" id="Ubirday" className="focusactive" style={{ fontSize: "1.2em" }} value={Birday} disabled={localStorage.getItem("UserData") != null || IsGetInfo ? "disabled" : ""} onChange={(e) => { setBirday(e.target.value) }} required />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">性別</div>
                        <div class="value">
                            <div>
                                <input type="radio" className="focusactive" id="male" name="radio" value="male" onChange={() => { setUserSex("M") }} checked={UserSex == "M" ? "Checked" : ""} required disabled />
                                <label htmlFor="male">男生</label>
                            </div>
                            <div>
                                <input type="radio" className="focusactive" id="female" name="radio" value="female" onChange={() => { setUserSex("F") }} checked={UserSex == "F" ? "Checked" : ""} required disabled />
                                <label for="female">女生</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field"><label htmlFor="cityNo">居住地區</label></div>
                        <div class="value item-inline">
                            <select name="" className="focusactive" id="cityNo" value={CityNo} onChange={(e) => { setCityNo(e.target.value), setArea(e.target.value) }} required disabled>
                                <option value="" disabled> 請選擇</option>
                                <option value="1">臺中市</option>
                                <option value="2">其他</option>
                            </select>

                            <select name="" className="focusactive" id="AreaNo" value={AreaNo} onChange={(e) => { setAreaNo(e.target.value) }} required disabled>
                                <option value="" disabled> 請選擇</option>
                                {
                                    AreaList.map((x) => (
                                        <option value={x.Value}>{x.Text}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field"><label htmlFor="userPhone">手機號碼</label></div>
                        <div class="value">
                            <input type="tel" className="focusactive" id="userPhone" placeholder="請輸入手機號碼" value={UserPhone} onChange={(e) => { setUserPhone(e.target.value) }} required disabled />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field"><label htmlFor="Email">電子信箱</label></div>
                        <div class="value">
                            <input type="email" className="focusactive" id="Email" placeholder="請輸入電子信箱" value={Email} onChange={(e) => { setEmail(e.target.value) }} required disabled />
                        </div>
                    </div>
                    <hr />
                    <div class="form-item">
                        <div class="field"><label htmlFor="Contact">緊急聯絡人姓名</label></div>
                        <div class="value">
                            <input type="text" className="focusactive" id="Contact" placeholder="請填寫緊急聯絡人姓名" value={Contact} onChange={(e) => { setContact(e.target.value) }} required disabled />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field"><label htmlFor="ContactPhone">緊急聯絡人電話</label></div>
                        <div class="value">
                            <input type="tel" className="focusactive" id="ContactPhone" placeholder="請輸入緊急聯絡人手機號碼" value={ContactPhone} onChange={(e) => { setContactPhone(e.target.value) }} required disabled />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-box bg-gray-100" style={listShow == true ? show : hide} style={{ marginTop: "0" }}>
                <div className="container">
                    <div className="table-box table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>開課日期</th>
                                    <th>期別</th>
                                    <th>課程名稱</th>
                                    <th>活動單位</th>
                                    <th>報名狀態</th>
                                    <th>繳費情況</th>
                                    <th>報名費</th>
                                    <th>課程狀況</th>
                                    {/*<th>功能</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    UserRegList.map((x) => (
                                        <tr>
                                            <td>{x.CouStartDate}</td>
                                            <td>{x.CouPName}</td>
                                            <td>{x.CouName}</td>
                                            <td>{x.DeptName}</td>
                                            <td><b className="text-red">{x.URegDistText}</b></td>
                                            <td><b className="text-pay">{x.URegPayText}</b></td>
                                            <td>{x.CouMoney}</td>
                                            <td>{x.CouStatusText}</td>
                                            {/*{x.URegPay == 2 ?*/}
                                            {/*    <td><button type="button" id="pay" onclick="window.location.href='#';" className="btn btn-secondary mr-1" value="show">繳費證明</button>*/}
                                            {/*        <button onClick={() => { history.push('/Applyrefund?URegID=' + x.URegID); }} className="btn-line btn-secondary" value="refune">申請退費</button>*/}
                                            {/*    </td>*/}
                                            {/*    : x.URegPay == 1*/}
                                            {/*        ? <td><button type="button" onclick="window.location.href='print/2.mobile-payment.html';" className="btn btn-secondary" value="download">下載繳費單</button></td>*/}
                                            {/*        : x.URegPay == 3*/}
                                            {/*            ? <td><button type="button" onclick="window.location.href='#';" className="btn-line btn-secondary" value="download">退費單下載</button></td>*/}
                                            {/*            : < td ></td>*/}
                                            {/*}*/}
                                        </tr>


                                    ))
                                }

                                {/*<tr>*/}
                                {/*    <td>111/09/24</td>*/}
                                {/*    <td>111春季班</td>*/}
                                {/*    <td>仿真粘土-冷瓷花及多肉植物</td>*/}
                                {/*    <td>大墩活動中心</td>*/}
                                {/*    <td><b className="text-red">已錄取</b></td>*/}
                                {/*    <td><b className="text-pay">完成繳費</b></td>*/}
                                {/*    <td>1500</td>*/}
                                {/*    <td>報名中</td>*/}
                                {/*    <td><button type="button" id="pay" onclick="window.location.href='#';" className="btn btn-secondary mr-1" value="show">繳費證明</button>*/}
                                {/*        <button onClick={() => { history.push('/Applyrefund'); }} className="btn-line btn-secondary" value="refune">申請退費</button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td>111/10/20</td>*/}
                                {/*    <td>111春季班</td>*/}
                                {/*    <td>藝術手工皂及保養品</td>*/}
                                {/*    <td>大墩活動中心</td>*/}
                                {/*    <td><b>候補</b></td>*/}
                                {/*    <td></td>*/}
                                {/*    <td>2600</td>*/}
                                {/*    <td>繳費中</td>*/}
                                {/*    <td>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td>111/11/10</td>*/}
                                {/*    <td>-</td>*/}
                                {/*    <td>水墨畫（一）｜陳華</td>*/}
                                {/*    <td>市立圖書館-西屯分館</td>*/}
                                {/*    <td><b className="text-red">待繳費</b></td>*/}
                                {/*    <td><b className="text-pay">未繳費</b></td>*/}
                                {/*    <td>2000</td>*/}
                                {/*    <td>報名中</td>*/}
                                {/*    <td><button type="button" onclick="window.location.href='print/2.mobile-payment.html';" className="btn btn-secondary" value="download">下載繳費單</button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td>111/12/10</td>*/}
                                {/*    <td>-</td>*/}
                                {/*    <td>書法｜張志鴻</td>*/}
                                {/*    <td>港區藝術中心</td>*/}
                                {/*    <td><b className="text-red">待繳費</b></td>*/}
                                {/*    <td><b className="text-pay">未繳費</b></td>*/}
                                {/*    <td>3000</td>*/}
                                {/*    <td>繳費中</td>*/}
                                {/*    <td><button type="button" onclick="window.location.href='print/2.mobile-payment.html';" className="btn btn-secondary" value="download">下載繳費單</button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td>111/12/18</td>*/}
                                {/*    <td>-</td>*/}
                                {/*    <td>水墨畫（二）｜陳華</td>*/}
                                {/*    <td>-</td>*/}
                                {/*    <td><b>未錄取</b></td>*/}
                                {/*    <td></td>*/}
                                {/*    <td>2200</td>*/}
                                {/*    <td>報名截止</td>*/}
                                {/*    <td>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}

                                {/*<tr>*/}
                                {/*    <td>112/01/08</td>*/}
                                {/*    <td>-</td>*/}
                                {/*    <td>水墨畫（三）｜陳華</td>*/}
                                {/*    <td>港區藝術中心</td>*/}
                                {/*    <td><b className="text-red">申請退費</b></td>*/}
                                {/*    <td></td>*/}
                                {/*    <td>2200</td>*/}
                                {/*    <td>報名截止</td>*/}
                                {/*    <td><button type="button" onclick="window.location.href='#';" className="btn-line btn-secondary" value="download">退費單下載</button>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                            </tbody>
                        </table>
                    </div>


                </div>
            </section>
        </>
    );
}
export default StudentSearchCosure;

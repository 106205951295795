import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function Applysuccessrefundok() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <section className="section-box">
              <div className="container">


                  <h1 className="text-center">退費申請</h1>


                  <div className="form-box">
                      <div className="form-container">

                          <div className="text-center">

                              <img src="images/apply-success.svg" />

                              <p className="my-4 mb-4 mb-md-5">已完成退費申請，審核完成後會將會通知結果。</p>

                              <button type="button" onClick={() => { window.location ="/StudentSearch" }} className="btn btn-primary btn-large" >確定</button>

                          </div>

                      </div>
                      {/*<!-- end form-container -->*/}
                  </div>
                  {/*<!-- end form-box -->*/}


              </div>
          </section>
     </>
  );
}
export default Applysuccessrefundok;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_ForgetPwd, API_UserRefund } from "../global/constants"
import { checkAuthToken, getAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
import { Helmet } from 'react-helmet'; //title
function ForgetPassword() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--忘記密碼";
        
    }, []);
    const [ID, setID] = useState('');
    function ForgetPwd() {
        var loading = document.getElementById('loading');
        loading.style.display = "block";
        API_ForgetPwd({ ID: ID })
            .then(res => res.data)
            .then(data => {
                loading.style.display = "none";
                if (data.code == "0000") {
                    Swal.fire("已送驗證碼至信箱，請收取驗證碼。", "成功", "success")
                        .then(ok => {

                            window.location = "/ResetPwd?ID=" + ID;
                        });
                }
                else if (data.code == "1045") {
                    Swal.fire("驗證碼尚未過期，請至信箱收取驗證碼。", "訊息", "info")
                        .then(ok => {

                            window.location = "/ResetPwd?ID=" + ID;
                        });
                }
                else
                {
                    Swal.fire(data.message, "錯誤", "error");
                }
            }).catch(e => {
                console.log(e);
            });
    }
  return (
      <>
          <Helmet>
              <title>臺中市政府文化局報名繳費系統-忘記密碼</title>
          </Helmet>

          <section className="section-box">
              <div className="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1 className="text-center">忘記密碼</h1>


                  <div className="form-box">

                      <form action="#">

                          <div className="form-container login-container">

                              <div className="form-item show-notes">
                                  <div className="field">請輸入您的身分證號碼或居住證號</div>
                                  <div className="value">
                                      <input type="text" value={ID} onChange={(e)=>setID(e.target.value)} placeholder="請輸入您的身分證號碼或居住證號" />
                                  </div>
                                  <span className="notes">
                                      <b>※</b> 請輸入您的 身分證號碼或居住證號，我們會將密碼函寄至您的信箱。
                                  </span>
                              </div>
                             
                              <div className="form-item btn-area mt-5">
                                  <button type="button" onClick={ForgetPwd} className="btn btn-primary btn-large mr-2">確認寄送密碼</button>
                              </div>

                          </div>

                      </form>
                      {/*<!-- end form -->*/}

                  </div>
                 {/* <!-- end form-box -->*/}


              </div>
          </section>
     </>
  );
}
export default ForgetPassword;

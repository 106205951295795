import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function MerryChristmas() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <section>

              <div class="index-banner">

                  {/*<!-- 星星 -->*/}
                  <div id='stars2'></div>
                  <div id='stars3'></div>

                  <div class="index-banner-style"><img src="images/index/banner-Merry-Christmas.png" /></div>

                  {/*<!-- 廣告輪播-->*/}
                  <div class="index-banner-ad">
                      <div class="index-banner-box">

                          {/*<!-- owl-carousel -->*/}
                          <div class="owl-carousel owl-theme dots-style-2"
                              data-plugin-options="{'items': 1, 'autoplay': true, 'dots': true, 'nav': true}">
                              <div><a href="#"><img src="images/index/ad-01.jpg" /></a></div>
                              <div><a href="#"><img src="images/index/ad-02.jpg" /></a></div>
                              <div><a href="#"><img src="images/index/ad-03.jpg" /></a></div>
                              <div><a href="#"><img src="images/index/ad-04.jpg" /></a></div>
                              <div><a href="#"><img src="images/index/ad-05.jpg" /></a></div>
                              <div><a href="#"><img src="images/index/ad-06.jpg" /></a></div>
                              <div><a href="#"><img src="images/index/ad-07.jpg" /></a></div>

                          </div>
                          {/*<!-- end owl-carousel -->*/}

                      </div>
                  </div>
                  {/*<!--end  廣告輪播-->*/}

              </div>
          </section>

          <section className="section-box">
              <div className="container">
                  <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a>
                  <div className="cultural-logo">
                      <a href="/CourseListTable"><img src="images/大墩文化中心.png" alt="" className="img-filter-blue" />大墩文化中心</a>
                      <a href="/CourseListTable"><img src="images/葫蘆墩文化中心.png" alt="" />葫蘆墩文化中心</a>
                      <a href="/CourseListTable"><img src="images/港區藝術中心.png" alt="" />港區藝術中心</a>
                      <a href="/CourseListTable"><img src="images/屯區藝文中心.png" alt="" />屯區藝文中心</a>
                      <a href="/CourseListTable"><img src="images/纖維工藝展覽中心.png" alt="" />纖維工藝展覽中心</a>
                      <a href="/CourseListTable"><img src="images/臺中市立圖書館.png" alt="" />臺中市立圖書館</a>
                  </div>
              </div>
          </section>
     </>
  );
}
export default MerryChristmas;

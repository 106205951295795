import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { checkAuthToken } from "../global/TokenUtil";
import {API_CourseQNR, API_PostCourseQNR} from '../global/constants';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';
function CourseSurvey() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const URegID = queryParams.get("URegID").toString();
    const [SurveyList, setSurveyList] = useState([{}]);
    const [cookies, setCookie, removeCookie] = useCookies(['SystemSurvey']);
    
    
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統-課程問卷";
       // var s = document.getElementById("string");
        //s.innerHTML = "<p style='color:#553125;font-size:20px'>123</p>";
      //取得中心列表
        getData();
       
    }, []);
    function Submit() {
        var input = document.querySelector('#SurveyMain').getElementsByTagName('input');
        var inputList = Array.prototype.slice.call(input);
        var TextArray = [];
        var RadioArray = []
        var ResultArray = [];
        var isOK = true;
        inputList.forEach(f => {
            if (f.type == "radio") {
                if (!RadioArray.includes(f.name)) {
                    RadioArray.push(f.name);
                }
            }
            else {
                if (!TextArray.includes(f.name)) {
                    TextArray.push(f.name);
                }
            }
            
        });
        RadioArray.forEach(f => {
            var radio = document.querySelector('input[name="' + f + '"]:checked');
            if (radio != null) {
                ResultArray.push({ URegID: URegID, QunSNo: f, QunFillData: radio.value });
            }
            else {
                Swal.fire("尚未填寫完成", "錯誤", "warning").then((res) => {
                    document.getElementsByName(f)[0].focus();
                });
                isOK = false;
            }
        })
        TextArray.forEach(f => {
            var text = document.getElementById(f);
            if (text.value != "") {
                ResultArray.push({ URegID: URegID, QunSNo: f, QunFillData: text.value });
            }
            else {
                Swal.fire("尚未填寫完成", "錯誤", "warning").then((res) => {
                    document.getElementsByName(f)[0].focus();
                });
                isOK = false;
            }
        })
        if (isOK) {
            API_PostCourseQNR(ResultArray)
                .then((res) => res.data)
                .then((data) => {
                    if (data.code == "0000") {
                        Swal.fire("已送出完成", "成功", "success").then((res) => {
                            window.history.back();
                        });

                    }
                    else {
                        Swal.fire(data.message, "錯誤", "error")
                    }
                })
                .catch((e) => {
                    Swal.fire("送出錯誤", "錯誤", "error");
                })
        }
        
    }
    function getData() {
        API_CourseQNR({ URegID: URegID })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    var html = "";
                    data.result.forEach(f => {
                        html = html + f.HtmlText;
                    });
                    var Maindiv = document.getElementById("SurveyMain");
                    Maindiv.innerHTML = html;
                    //setCookie("SystemSurvey", "1", { expires: new Date(2100, 1, 1) });
                   
                }   
                else {
                    Swal.fire(data.message, "錯誤", "error").then((res) => {
                        window.history.back();
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

  return (
      <>
          <section class="section-box">
              <div class="container">


                  <h1 class="text-center">課程滿意度</h1>


                  <div class="form-box">
                      <div class="form-container">

                          <div class="max-w-lg mx-auto">
                              
                              <div id="SurveyMain">
                                
                              </div>



                              <div class="form-item btn-area mt-5">
                                  <button type="button" class="btn btn-primary btn-large" on onClick={Submit}>送出</button>
                              </div>

                          </div>

                      </div>
                  {/*    <!-- end form-container -->*/}
                  </div>
                  {/*<!-- end form-box -->*/}


              </div>
          </section>
      </> 
  );
}
export default CourseSurvey;

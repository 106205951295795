import "../css/style.css";
import Barcode from 'jsbarcode';
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { Helmet } from 'react-helmet'; //title
import { API_Barcode, API_BarcodePDF } from '../global/constants'; //公告內容
import { checkAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
function printPhone() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const URegID = queryParams.get("URegID");
    const [CouName, setCouName] = useState();
    useEffect(() => {
        API_Barcode({ "URegID": URegID })
            .then((response) => response.data)
            .then((data) => {

                checkAuthToken(data);
                if (data.code == "0000") {
                    setCouName(data.result.couName);
                    JsBarcode("#code39-1", data.result.barcode1, { height: "50%", width: 1, fontSize: 14, format: "code39" });
                    JsBarcode("#code39-2", data.result.barcode2, { height: "50%", width: 1, fontSize: 14, format: "code39" });
                    JsBarcode("#code39-3", data.result.barcode3, { height: "50%", width: 1, fontSize: 14, format: "code39" });

                }
                else if (data.code == "9999") {
                    history.goBack();
                }
                else {
                    Swal.fire(data.message, "", "warning");
                    //alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
       
    }, []);
    
  return (
      <>
          <Helmet>
              <title>臺中市政府文化局報名繳費系統-繳費單下載</title>
          </Helmet>
              
          <section className="section-box">
              <div className="container">


                  {/*  導盲磚 */}
                  <div>
                      <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a>
                  </div>


                  <h1 className="text-center">{CouName} - 繳費單下載</h1>


                  {/*  繳費單種類 */}
                  <div className="print-category">
                      <div className="news-category">

                          <ul className="news-category-list">

                              <li>
                                  <a href={"/printAtm?URegID=" + URegID} title="ATM繳費資訊與ATM繳費單下載">
                                      <i className="im im-icon-ATM"></i>
                                      ATM 繳費資訊
                                  </a>
                              </li>

                              <li>
                                  <a className="active" href={"/printPhone?URegID=" + URegID} title="超商繳費資訊與手機版繳費單下載">
                                      <i className="im im-icon-Smartphone-3"></i>
                                      超商繳費（手機）
                                  </a>
                              </li>
                          </ul>

                      </div>
                  </div>
                  {/*  end 繳費單種類 */}


                   {/*  內容 */}
                  <div className="form-box">

                      {/*  列印範圍 */}
                      <div id="print" className="phone">

                          <h1>超商繳費</h1>
                          <h2>手機條碼至超商繳費</h2>
                          <table>
                              <tr>
                                  <td>手機條碼</td>
                              </tr>
                              <tr>
                                  <td align="center">
                                      <div>
                                          <svg id="code39-1"></svg><br />
                                          <svg id="code39-2"></svg><br />
                                          <svg id="code39-3"></svg><br />
                                      </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td>貼心提醒：<br />
                                      <ol>
                                          <li>每筆繳費帳號皆為獨立，請勿使用他人帳號繳款。 </li>
                                          {/*<li>超商繳費上限金額為3萬元。</li>*/}
                                          <li>超商繳費之手續費，由繳費人自行支付。超商繳費上限金額為3萬元。</li>
                                          <li>以本條碼方式至超商（7-11、全家、萊爾富、OK）繳費後，請務必保留「代收款繳費證明單」，以保障權益。</li>
                                          <li>建議將手機 / 平板之螢幕亮度調至最亮，以確保繳費條碼正常讀取。</li>
                                      </ol>
                                  </td>
                              </tr>
                          </table>

                      </div>
                      {/*  end 列印範圍 */}

                      {/*<div className="text-center mt-5">*/}
                      {/*    <button name="print" type="button" value="列印繳費單" onClick={download}*/}
                      {/*        className="btn btn-primary btn-large">列印繳費單</button>*/}
                      {/*</div>*/}

                  </div>
                  {/*  end 內容 */}


              </div>
          </section>


    
     </>
  );
}
export default printPhone;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { Helmet } from 'react-helmet'; //title
import {  checkAuthToken } from "../global/TokenUtil";
function CourseLogin() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <Helmet>
              <title>臺中市政府文化局報名繳費系統-研習之友登入</title>
          </Helmet>
          <section class="section-box">
              <div class="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1 class="text-center">研習之友登入</h1>


                  <div class="form-box">

                      <form action="#">

                          <div class="form-container login-container">

                              <div class="form-item">
                                  <div class="field">身分證號碼</div>
                                  <div class="value">
                                      <input type="text" placeholder="請填寫身分證號碼" value="A123456789" />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">密碼</div>
                                  <div class="value">
                                      <input type="password" placeholder="請輸入密碼" />`
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">驗證碼</div>
                                  <div class="value item-inline">
                                      <input type="text" placeholder="請輸入右側驗證碼" />
                                      <img src="images/verification-code.png" class="pl-3" />
                                  </div>
                              </div>



                              <div class="form-item btn-area">
                                  <button type="button" onClick={() => { history.push('/CourseRegistrationEnter'); }} class="btn btn-primary btn-large">登入</button>
                              </div>

                          </div>


                      </form>
                    

                  </div>
                  


              </div>
          </section>
     </>
  );
}
export default CourseLogin;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import { Helmet } from 'react-helmet'; //title
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_SignUp } from '../global/constants';
import { check } from "prettier";
import { checkAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
import { doc } from "prettier";
function Register() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [AreaList, setAreaList] = useState([{}]);
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--報名註冊";
    });


    function setArea(CityNo) {
        if (CityNo == "1") {
            setAreaList(
                [
                    { Value: 1, Text: "中區" },
                    { Value: 2, Text: "東區" },
                    { Value: 3, Text: "南區" },
                    { Value: 4, Text: "西區" },
                    { Value: 5, Text: "北區" },
                    { Value: 6, Text: "北屯區" },
                    { Value: 7, Text: "西屯區" },
                    { Value: 8, Text: "南屯區" },
                    { Value: 9, Text: "太平區" },
                    { Value: 10, Text: "大里區" },
                    { Value: 11, Text: "霧峰區" },
                    { Value: 12, Text: "烏日區" },
                    { Value: 13, Text: "豐原區" },
                    { Value: 14, Text: "后里區" },
                    { Value: 15, Text: "石岡區" },
                    { Value: 16, Text: "東勢區" },
                    { Value: 17, Text: "和平區" },
                    { Value: 18, Text: "新社區" },
                    { Value: 19, Text: "潭子區" },
                    { Value: 20, Text: "大雅區" },
                    { Value: 21, Text: "神岡區" },
                    { Value: 22, Text: "大肚區" },
                    { Value: 23, Text: "沙鹿區" },
                    { Value: 24, Text: "龍井區" },
                    { Value: 25, Text: "梧棲區" },
                    { Value: 26, Text: "清水區" },
                    { Value: 27, Text: "大甲區" },
                    { Value: 28, Text: "外埔區" },
                    { Value: 29, Text: "大安區" }
                ]
            );
            
        }
        else {
            setAreaList(
                [
                    { Value: 1, Text: "其他" },              
                    { Value: 2, Text: "臺北市" },
                    { Value: 3, Text: "新北市" },
                    { Value: 4, Text: "桃園市" },
                    { Value: 5, Text: "臺南市" },
                    { Value: 6, Text: "高雄縣" },
                    { Value: 7, Text: "基隆縣" },
                    { Value: 8, Text: "新竹市" },
                    { Value: 9, Text: "嘉義市" },
                    { Value: 10, Text: "新竹縣" },
                    { Value: 11, Text: "苗栗縣" },
                    { Value: 12, Text: "彰化縣" },
                    { Value: 13, Text: "南投縣" },
                    { Value: 14, Text: "雲林縣" },
                    { Value: 15, Text: "嘉義縣" },
                    { Value: 16, Text: "屏東縣" },
                    { Value: 17, Text: "宜蘭縣" },
                    { Value: 18, Text: "花蓮縣" },
                    { Value: 19, Text: "臺東縣" },
                    { Value: 20, Text: "澎湖縣" },
                    { Value: 21, Text: "金門縣" },
                    { Value: 22, Text: "連江縣" },
                    
                ]
            );
        }
    }
    const [UserIDNo, setUserIDNo] = useState('');
    const [userIDNoError, setUserIDNoError] = useState(false);
    const [UserName, setUserName] = useState('');
    const [userNameError, setUserNameError] = useState(false);
    const [PassWord, setPassWord] = useState('');
    const [passWordError, setPassWordError] = useState(false);
    const [ChkPassWord, setChkPassWord] = useState('');
    const [chkPassWordError, setChkPassWordError] = useState(false);
    const [UserPhone, setUserPhone] = useState('');
    const [userPhoneError, setUserPhoneError] = useState(false);
    const [CityNo, setCityNo] = useState('');
    const [cityNoError, setCityNoError] = useState(false);
    const [UserSex, setUserSex] = useState('');
    const [userSexError, setUserSexError] = useState(false);
    const [AreaNo, setAreaNo] = useState('');
    //const [areaNoError, setAreaNoError] = useState(false);
    const [BirDay, setBirDay] = useState('2000-01');
    const [birDayError, setBirDayError] = useState(false);
    const [Email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [Contact, setContact] = useState('');
    const [contactError, setContactError] = useState(false);
    const [ContactPhone, setContactPhone] = useState('');
    const [contactPhoneError, setContactPhoneError] = useState(false);
    const [isForeig, setisForeig] = useState(false);
    const [RegPwdError, setRegPwdError] = useState(false);
    const [RegRePwdError, setRegRePwdError] = useState(false);
    //信箱格式檢查
    function checkemail(mail) {
        var reg = new RegExp(/^\S+@\S+\.\S{2,}$/);
        if (!reg.test(mail)) {
            return false;
            document.getElementById("Email").focus();
        } else {
            return true;
        }
    }
    //註冊前資料檢查
    function checkData() {
        var result = true;
        if (UserIDNo == '') {
            setUserIDNoError(true);
            result = false;
            document.getElementById("UserIDNo").focus();
        }
        if (UserName == '') {
            setUserNameError(true);
            document.getElementById("UserName").focus();
        }
        if (PassWord == '') {
            setPassWordError(true);
            result = false;
            document.getElementById("PassWord").focus();
        }
        console.log(PassWord, ChkPassWord);
        if (PassWord != ChkPassWord || ChkPassWord == '') {
            setChkPassWordError(true);
            result = false;
            document.getElementById("ChkPassWord").focus();
        }
        if (UserPhone == '') {
            setUserPhoneError(true);
            result = false;
            document.getElementById("UserPhone").focus();
        }
        console.log(UserSex);
        if (UserSex == '') {
            setUserSexError(true);
            result = false;
            document.getElementById("male").focus();
        }
        if (CityNo == '' || AreaNo == '') {
            setCityNoError(true);
            result = false;
            document.getElementById("CityNo").focus();
        }
        //if (UserSex == '') {
        //    setUserSex(true);
        //    result = false;
        //    document.getElementById("male").focus();
        //}
        if (BirDay == '') {
            setBirDayError(true);
            result = false;
            document.getElementById("BirDay").focus();
        }
        if (Email == '') {
            setEmailError(true);
            result = false;
            document.getElementById("Email").focus();
        } else { 
            if (!checkemail(Email)) {
                setEmailError(true);
                result = false;
                document.getElementById("Email").focus();
            }  
        }
        if (Contact == '') {
            setContactError(true);
            result = false;
            document.getElementById("Contact").focus();
        }
        if (ContactPhone == '') {
            setContactPhoneError(true);
            result = false;
            document.getElementById("ContactPhone").focus();
        }

        var regPhone = new RegExp(/^\d{10}$/);
        if (!regPhone.test(UserPhone)) {
            setUserPhoneError(true);
            result = false;
            return;
        }

        if (!regPhone.test(ContactPhone)) {
            setContactPhoneError(true);
            result = false;
            return;
        }

        var regPwd = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);
        if (!regPwd.test(PassWord)) {
            result = false;           
            return;
        }
        var regRePwd = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);
        if (!regRePwd.test(ChkPassWord)) {
            result = false;
            return;
        }
        return result;
    }
    function chkEmpty(e, name) {
        if (name == "UserIDNo") {
            if (e.target.value == '')
                setUserIDNoError(true);
            else
                setUserIDNoError(false);
        }
        if (name == "UserName") {
            if (e.target.value == '')
                setUserNameError(true);
            else
                setUserNameError(false);
        }
        if (name == "PassWord") {
            if (e.target.value == '')
                setPassWordError(true);
            else
                setPassWordError(false);           
        }
        if (name == "ChkPassWord") {
            if (e.target.value == '')
                setChkPassWordError(true);
            else
                setChkPassWordError(false);            
        }
        if (name == "UserPhone") {
            if (e.target.value == '')
                setUserPhoneError(true);
            else
                setUserPhoneError(false);
        }
        //if (name == "UserSex") {
        //    if (e.target.value == '')
        //        setUserSexError(true);
        //    else
        //        setUserSexError(false);
        //}
        if (name == "City") {
            if (e.target.value == '')
                setCityNoError(true);
            else
                setCityNoError(false);
        }
        if (name == "male") {
            if (e.target.value == '')
                setUserSexError(true);
            else
                setUserSexError(false);
        }
        if (name == "BirDay") {
            if (e.target.value == '')
                setBirDayError(true);
            else
                setBirDayError(false);
        }
        if (name == "Email") {
            if (e.target.value == '') {
                setEmailError(true);
            } else {
                if (!checkemail(e.target.value)) {
                    setEmailError(true);
                }
                else {
                    setEmailError(false);
                }
            }
        }
        if (name == "Contact") {
            if (e.target.value == '')
                setContactError(true);
            else
                setContactError(false);
        }
        if (name == "ContactPhone") {
            if (e.target.value == '')
                setContactPhoneError(true);
            else
                setContactPhoneError(false);
        }       
    }
    function chkPwdReg(e,name) {
        var pwdErr = document.getElementById('pwdErr');
        var repwdErr = document.getElementById('repwdErr');
        if (name == "pwd") {
            var regPwd = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);
            if (!regPwd.test(e.target.value)) {
                pwdErr.style.display = "block";
            }
            else {
                pwdErr.style.display = "none";
            }
            if (e.target.value != ChkPassWord) {
                repwdErr.style.display = "block";
            }
            else {
                repwdErr.style.display = "none";
            }
            if (e.target.value.length >= 12) {
                document.getElementById('PassWord').style.color = "black";
            }
            else {
                document.getElementById('PassWord').style.color = "red";
            }
        }
        if (name == "repwd") {
            //var regRePwd = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);
            if (e.target.value != PassWord) {
                repwdErr.style.display = "block";
            }
            else {
                repwdErr.style.display = "none";
            }
            if (e.target.value.length >= 12) {
                document.getElementById('ChkPassWord').style.color = "black";
            }
            else {
                document.getElementById('ChkPassWord').style.color = "red";
            }
        }
    }
    function chkPhoneReg(e, name) {
        var pwdErr = document.getElementById('rephoneErr');
       /* var repwdErr = document.getElementById('repwdErr');*/
        if (name == "rephone") {
           /* var regPwd = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);*/
            var regPhone = new RegExp(/^\d{10}$/);
            if (!regPhone.test(e.target.value))
            {
                pwdErr.style.display = "block";
            }
            else
            {
                pwdErr.style.display = "none";
            }
       
        }

        if (name == "reCphone") {
            /* var regPwd = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);*/
            var regPhone = new RegExp(/^\d{10}$/);
            if (!regPhone.test(e.target.value)) {
                reCphoneErr.style.display = "block";
            }
            else {
                reCphoneErr.style.display = "none";
            }
        }
    }
    

    function register() {
        //檢查資料
        var result = checkData();
        if (result == true) {
            setUserIDNoError(false);
            setUserNameError(false);
            setPassWordError(false);
            setUserPhoneError(false);
            setCityNoError(false);
            setUserSexError(false);
            setBirDayError(false);
            setEmailError(false);
            setContactError(false);
            setContactPhoneError(false);
            var dt =
            {
                UserIDNo: UserIDNo,
                UserName: UserName,
                PassWord: PassWord,
                UserPhone: UserPhone,
                CityNo: CityNo,
                AreaNo: AreaNo,
                UserSex: UserSex,
                BirDay: BirDay + "-01",
                Email: Email,
                Contact: Contact,
                ContactPhone: ContactPhone,
                IsForeig: isForeig
            }
            console.log(JSON.stringify(dt));
            API_SignUp(dt)
                .then((response) => response.data)
                .then((data) => {
                    if (data.code == "0000") {
                        Swal.fire("註冊成功，請登入", "", "success").then(ok => {
                        //alert("註冊成功，請登入");
                            window.location = "/IdentityVerify";
                        });
                    }
                    else {
                        Swal.fire(data.message, "", "warning");
                        //alert(data.message);
                    }

                })
                .catch(err => {
                    console.log(err);
                })
        }
      
        //event.preventDefault();
    }

    const showpassword1 = () => {
        if (document.getElementById('PassWord').type === "password") {
            document.getElementById('PassWord').type = "text";
            document.getElementById('eye1').classList.add('active');
        } else {
            document.getElementById('PassWord').type = "password";
            document.getElementById('eye1').classList.remove('active');
        }
    }
    const showpassword2 = () => {
        if (document.getElementById('ChkPassWord').type === "password") {
            document.getElementById('ChkPassWord').type = "text";
            document.getElementById('eye2').classList.add('active');
        } else {
            document.getElementById('ChkPassWord').type = "password";
            document.getElementById('eye2').classList.remove('active');
        }
    }


    return (
        <>
            <Helmet>
                <title>臺中市政府文化局報名繳費系統-研習之友註冊</title>
            </Helmet>
            <section className="section-box">

                <div className="container">
                    <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                    <h1 className="text-center">研習之友註冊</h1>

                    <div className="form-box">
                        <div className="form-container ">
                            <div className={`form-item ${userIDNoError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="UserIDNo">身分證號碼</label>
                                </div>
                                <div className="value">
                                    <input type="text" id="UserIDNo" autocomplete="userid" value={UserIDNo} onChange={e => { setUserIDNo(e.target.value), setUserIDNoError(false), chkEmpty(e,"UserIDNo") }} title="請輸入身分證號碼" placeholder="請輸入身分證號碼" required />
                                </div>
                            </div>
                            <div className={`form-item ${userIDNoError ? 'show-help' : ''}`}>
                                <div className="field">
                                </div>
                                <div className="value">
                                    <input type="checkbox" id="foreign" title="是否外籍人士選項" checked={isForeig} onClick={() => setisForeig(!isForeig)} />
                                    <label htmlFor="foreign">外籍人士（請輸入居住證號）</label>
                                </div>
                                <span className="help-word">請輸入身分證號碼</span>
                            </div>
                            <div className={`form-item ${userNameError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="UserName">姓名</label>
                                </div>
                                <div className="value">
                                    <input type="text" id="UserName" autocomplete="username" placeholder="請輸入真實中文姓名" title="請輸入真實中文姓名" value={UserName} autocomplete="UserName" onChange={e => { setUserName(e.target.value), setUserNameError(false), chkEmpty(e, "UserName") }} required />
                                </div>
                                <span className="help-word">請輸入真實中文姓名</span>
                            </div>

                            <div className={`form-item ${birDayError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="BirDay">出生年月</label>
                                </div>
                                <div className="value">
                                    <input type="month" id="BirDay" value={BirDay} title="請選擇出生年月" onChange={e => { setBirDay(e.target.value), setBirDayError(false), chkEmpty(e, "BirDay") }} required />
                                </div>
                                <span className="help-word">請選擇出生年月</span>
                            </div>

                            <div className={`form-item ${userSexError ? 'show-help' : ''}`}>
                                <div className="field">
                                    性別
                                </div>
                                <div className="value">
                                    <div>
                                        <input type="radio" id="male" name="sex" title="男生" value="M" onChange={(e) => { setUserSex(e.target.value), setUserSexError(false), chkEmpty(e, "male") }} required />
                                        <label htmlFor="male">男生</label>
                                    </div>
                                    <div>
                                        <input type="radio" id="female" name="sex" title="女生" value="F" onChange={(e) => { setUserSex(e.target.value), setUserSexError(false), chkEmpty(e, "male") }} required />
                                        <label htmlFor="female">女生</label>
                                    </div>
                                </div>
                                <span className="help-word">請選擇性別</span>
                            </div>

                            <div className={`form-item ${emailError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="Email">電子信箱</label>
                                </div>
                                <div className="value">
                                    <input id="Email" type="email" autocomplete="useremail" title="請輸入常用電子信箱" placeholder="請輸入常用電子信箱" value={Email} autocomplete="email" onChange={e => { setEmail(e.target.value), setEmailError(false), chkEmpty(e, "Email") }} required />
                                </div>
                                <span className="help-word">請檢查電子信箱格式</span>
                                
                            </div>
                            <hr />
                            <div className={`form-item ${passWordError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="PassWord">設定密碼</label>
                                </div>
                                <div className="value">
                                    <input type="password" id="PassWord" title="密碼至少12碼，須包含大寫英文、小寫英文、數字、特殊符號" placeholder="密碼至少12碼，須包含大寫英文、小寫英文、數字、特殊符號" value={PassWord} onChange={e => { setPassWord(e.target.value), setPassWordError(false), chkPwdReg(e, "pwd"), chkEmpty(e, "PassWord") }} required />
                                    <i className="eye" id="eye1" onClick={showpassword1}></i>
                                </div>

                                <div>
                                    <p style={{ paddingRight: "30px", color: "orange" }}>
                                        特殊符號包含以下：， 、 。 ． ？ ！ ～ ＄ ％ ＠ ＆ ＃ ( )＊ ‧ ； ︰
                                    </p>
                                    <p style={{ paddingRight: "30px", color: "orange" }}>
                                        密碼至少12碼，須包含大寫英文、小寫英文、數字、特殊符號
                                    </p>
                                </div>
                                <span className="help-word">請輸入密碼</span>
                               
                            </div>

                            <div className={`form-item ${chkPassWordError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="ChkPassWord">確認密碼</label>
                                </div>
                                <div className="value">
                                    <input type="password" id="ChkPassWord" title="請再輸入一次密碼" placeholder="請再輸入一次密碼" value={ChkPassWord} onChange={e => { setChkPassWord(e.target.value), setChkPassWordError(false), chkPwdReg(e, "repwd"), chkEmpty(e, "ChkPassWord")}} required />
                                    <i className="eye" id="eye2" onClick={showpassword2}></i>
                                </div>
                                <span className="help-word">請在輸入一次密碼</span>
                                <span id="repwdErr" className="RegErr" >兩次密碼不相同</span>
                            </div>

                            <div className={`form-item ${userPhoneError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="UserPhone">手機</label>
                                </div>
                                <div className="value item-inline">
                                    <input type="tel" id="UserPhone" title="請輸入手機號碼" maxLength="10" placeholder="請輸入手機號碼(ex.0955888888)" value={UserPhone} autocomplete="UserPhone" onChange={e => { setUserPhone(e.target.value), setUserPhoneError(false), chkPhoneReg(e,"rephone"), chkEmpty(e, "UserPhone") }} required />
                                    {/*<button type="button" className="btn btn-primary ml-2">獲取手機驗證碼</button>*/}
                                </div>
                                <span className="help-word">請輸入手機號碼</span>
                                <span id="rephoneErr" className="RegErr">請輸入正確手機號碼</span>
                            </div>

                            {/*<div className="form-item code">*/}
                            {/*    <div className="field">手機驗證碼</div>*/}
                            {/*    <div className="value btn-no-style">*/}
                            {/*        <input type="tel" placeholder="請輸入簡訊中的六位數字" />*/}
                            {/*        <button type="button" onclick="window.open('phone-verification.html');" className="btn">收不到手機驗證碼？</button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            <hr />


                            <div className={`form-item ${cityNoError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="CityNo">居住地區 </label>
                                </div>
                                <div className="value item-inline">
                                    <select name="CityNo" id="CityNo" tilte="請選擇縣市" onChange={e => { setCityNo(e.target.value), setCityNoError(false), setArea(e.target.value), chkEmpty(e, "City")}}>
                                        <option value="" selected disabled>請選擇</option>
                                        <option value="1">臺中市</option>
                                        <option value="2">其他</option>
                                    </select>

                                    <select name="AreaNo" id="AreaNo" tilte="請選擇地區" onChange={e => { setAreaNo(e.target.value), setCityNoError(false), chkEmpty(e, "City")}}>
                                        <option value="" selected disabled>請選擇</option>
                                        {
                                            AreaList.map((x) => (
                                                <option value={x.Value}>{x.Text}</option>
                                                ))
                                        }
                                    </select>
                                </div>
                                <span className="help-word">請輸入居住地區</span>
                            </div>

                            <div className={`form-item ${contactError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="Contact">緊急聯絡人姓名</label>
                                </div>
                                <div className="value">
                                    <input type="text" id="Contact" placeholder="請輸入緊急聯絡人姓名" title="請輸入緊急聯絡人姓名" value={Contact} onChange={e => { setContact(e.target.value), setContactError(false), chkEmpty(e, "Contact") }} required />
                                </div>
                                <span className="help-word">請輸入緊急聯絡人姓名</span>
                            </div>

                            <div className={`form-item ${contactPhoneError ? 'show-help' : ''}`}>
                                <div className="field">
                                    <label htmlFor="ContactPhone">緊急聯絡人手機</label>
                                </div>
                                <div className="value">
                                    <input type="tel" id="ContactPhone" placeholder="請輸入緊急聯絡人手機號碼(ex.0955888888)" maxLength="10" title="請輸入緊急聯絡人手機號碼" value={ContactPhone} onChange={e => { setContactPhone(e.target.value), setContactPhoneError(false), chkPhoneReg(e, "reCphone"), chkEmpty(e, "ContactPhone") } } required />
                                </div>
                                <span className="help-word">請輸入緊急聯絡人手機</span>
                                <span id="reCphoneErr" className="RegErr">請輸入正確手機號碼</span>
                            </div>


                            <div className="form-item btn-area mt-5">
                                <button type="button" className="btn btn-primary" value="goin" onClick={register} >立即加入研習之友</button>
                                <a style={{ fontSize: "0.9rem", color: "#cd1d1d", textDecorationLine: "underline", paddingLeft: "0.8rem", }} href="/FAQ#tab5">如有問題請洽各單位聯絡人</a>
                            </div>

                            
                        </div>



                    </div>

                </div>
            </section>
        </>
    );
}
export default Register;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_BarcodePDF, API_BarcodeATM } from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
function printAtm() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const URegID = queryParams.get("URegID");
    const [PayInfo, setPayInfo] = useState({});
    const [ID, setID] = useState('');
    const [PDFUrl, setPDFUrl] = useState('');
    
    const FileDownload = require("js-file-download");

    useEffect(() => {
        var FIleName = "";
        setID(URegID);

       //API_BarcodePDF({
       //     URegID: URegID,
       //   }).then((response) => {
       //     //FileDownload(response.data, PayInfo.couName + '.pdf');

       //     var Url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf'}));
       //     setPDFUrl(Url);

       //     //iFrame.setAttribute("src",);
            
       // });
        API_BarcodeATM({ URegID: URegID })
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    console.log(data.result);
                    setPayInfo(data.result);
                    FIleName = data.result.couName;
                }
                else {
                    console.log(data.message);
                    //window.location = "/";
                }
            })
            .catch(err => {
                console.log(err);
            })
     
        console.log(URegID);
    }, []);

    function download() {
        API_BarcodePDF({
            URegID: URegID,
        }).then((response) => {
            FileDownload(response.data, PayInfo.couName + '.pdf');


        });
    }

  return (
      <>
         
              
          <section className="section-box">
              <div className="container">


                  {/*  導盲磚 */}
                  <div>
                      <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a>
                  </div>


                  <h1 className="text-center">繳費單下載</h1>


                  {/*  繳費單種類 */}
                  <div className="print-category">
                      <div className="news-category">

                          <ul className="news-category-list">

                              <li>
                                  <a className="active" href={"/printAtm?URegID=" + ID} title="ATM繳費資訊與ATM繳費單下載">
                                      <i className="im im-icon-ATM"></i>
                                      ATM 繳費資訊
                                  </a>
                              </li>

                              <li>
                                  <a href={"/printPhone?URegID=" + ID} title="超商繳費資訊與手機版繳費單下載">
                                      <i className="im im-icon-Smartphone-3"></i>
                                      超商繳費（手機）
                                  </a>
                              </li>

                              {/*<li>*/}
                              {/*    <a href="/printConvenience" title="超商繳費資訊與超商繳費單下載">*/}
                              {/*        <i className="im im-icon-Billing"></i>*/}
                              {/*        超商繳費單*/}
                              {/*    </a>*/}
                              {/*</li>*/}

                              {/*<li>*/}
                              {/*    <a href="/printCounter" title="臨櫃繳費資訊與臨櫃繳費單下載">*/}
                              {/*        <i className="im im-icon-Money-2"></i>*/}
                              {/*        臨櫃繳費單*/}
                              {/*    </a>*/}
                              {/*</li>*/}

                          </ul>

                      </div>
                  </div>
                  {/*  end 繳費單種類 */}


                   {/*  內容 */}
                  <div className="form-box">

                      {/*  列印範圍 */}
                      <div id="print">

                          <h2>ATM繳費資訊</h2>
                          <h3>{PayInfo.couName}</h3>
                          <table>
                              <tr>
                                  <td width="386">繳費人：{PayInfo.userName}</td>
                                  <td width="365">電話：{PayInfo.userPhone}</td>
                              </tr>
                              <tr>
                                  <td colspan="2">繳費銀行代號：004台灣銀行</td>
                              </tr>
                              <tr>
                                  <td colspan="2">繳費帳號：{PayInfo.payNo}</td>
                              </tr>
                              <tr>
                                  <td colspan="2">繳費期限：{PayInfo.paydate}</td>
                              </tr>
                              <tr>
                                  <td colspan="2">繳費金額：{PayInfo.payMoney}元</td>
                              </tr>
                              <tr>
                                  <td colspan="2">合計：{PayInfo.payMoney2}</td>
                              </tr>
                              <tr>
                                  <td colspan="2">
                                      <ol>
                                          <li>繳費帳號為每個人每次報名唯一帳號，請依帳號繳費。</li>
                                          <li>超商繳費之手續費，由繳費人自行支付。超商繳費上限金額為3萬元。</li>
                                        {/*  <li>ATM轉帳產生之手續費，由繳費者自行支付。</li>*/}
                                          <li>請務必在繳費期限內繳款，否則無法繳費。</li>
                                          <li>ATM轉帳請確認交易成功並請請妥善保存交易明細。</li>
                                      </ol>
                                  </td>
                              </tr>
                          </table>

                      </div>
                      {/*  end 列印範圍 */}
                      <div className="text-center mt-5">
                          <button name="print" type="button" value="列印繳費單"
                              className="btn btn-primary btn-xxlarge" onClick={download}>列印繳費單</button>
                      </div>
                      <br/>
                     {/* <iframe height="1000px" width="100%" id="PDFifram" class="px-3" src={PDFUrl + "#view=FitV&toolbar=0"} ></iframe>*/}
                      

                  </div>
                  {/*  end 內容 */}


              </div>
          </section>


    
     </>
  );
}
export default printAtm;

import React from 'react';
import ReactDOM from 'react-dom';
import I18n from "./i18n"; //多國語言
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Route, BrowserRouter, Switch } from "react-router-dom";
import Home from './pages/Home';
import StudentSearch from './pages/StudentSearch';
import IdentityVerify from './pages/IdentityVerify';
import Register from './pages/Register';
import Header from './components/partial/header';
import CourseList from './pages/CourseList';
import FAQ from './pages/FAQ';
import Announcement from './pages/Announcement';
import Applydrawlots from './pages/Applydrawlots';
import Applyrefundconfirm from './pages/Applyrefundconfirm';
import Applysuccessrefundok from './pages/Applysuccessrefundok';
import Applyrefund from './pages/Applyrefund';
import Sitemap from './pages/Sitemap';
import MerryChristmas from './pages/MerryChristmas';
import HappyNewYear from './pages/HappyNewYear';
import Courselisttable from './pages/Courselisttable';
import StudentSearchCosure from './pages/StudentSearchCosure';
import StudentSearchResultQuery from './pages/StudentSearchResultQuery';
import CourseLogin from './pages/CourseLogin';
import CourseRegistration from './pages/CourseRegistration';
import ApplySuccess from './pages/ApplySuccess';
import QuestionnaireSurvey from './pages/QuestionnaireSurvey';
import CourseAdmissionList from './pages/CourseAdmissionList';
import CourseRegistrationEnter from './pages/CourseRegistrationEnter';
import ApplySuccessQus from './pages/ApplySuccessQus';
import printAtm from './pages/printAtm';
import printPhone from './pages/printPhone';
import printConvenience from './pages/printConvenience';
import printCounter from './pages/printCounter';
import News from './pages/News';
import SystemSurvey from './pages/SystemSurvey';
import CourseSurvey from './pages/CourseSurvey';
import ForgetPassword from './pages/ForgetPassword';
import ResetPwd from './pages/ResetPassword';
import CourseLessonInfo from './pages/CourseLessonInfo';
import ChangePassword from './pages/ChangePassword';
import ApiUrl from './pages/ApiUrl';
import { API_Views } from './global/constants';
const header = (
    <Header />

);

ReactDOM.render(header, document.getElementById('header'));
const root = (
    //test
    <I18n>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/StudentSearch" component={StudentSearch} />
                <Route exact path="/IdentityVerify" component={IdentityVerify} />
                <Route exact path="/Register" component={Register} />
                <Route exact path="/CourseList" component={CourseList} />
                <Route exact path="/FAQ" component={FAQ} />
                <Route exact path="/Announcement" component={Announcement}/>
                <Route exact path="/Applydrawlots" component={Applydrawlots} />
                <Route exact path="/Applyrefundconfirm" component={Applyrefundconfirm} />
                <Route exact path="/Applysuccessrefundok" component={Applysuccessrefundok} />
                <Route exact path="/Applyrefund" component={Applyrefund} />
                <Route exact path="/Sitemap" component={Sitemap} />
                <Route exact path="/MerryChristmas" component={MerryChristmas} />
                <Route exact path="/HappyNewYear" component={HappyNewYear} />
                <Route exact path="/Courselisttable" component={Courselisttable} />
                <Route exact path="/StudentSearchCosure" component={StudentSearchCosure} />
                <Route exact path="/StudentSearchResultQuery" component={StudentSearchResultQuery} />
                <Route exact path="/CourseLogin" component={CourseLogin} />
                <Route exact path="/CourseRegistration" component={CourseRegistration} />
                <Route exact path="/ApplySuccess" component={ApplySuccess} />
                <Route exact path="/QuestionnaireSurvey" component={QuestionnaireSurvey} />
                <Route exact path="/CourseAdmissionList" component={CourseAdmissionList} />
                <Route exact path="/CourseRegistrationEnter" component={CourseRegistrationEnter} />
                <Route exact path="/ApplySuccessQus" component={ApplySuccessQus} />
                <Route exact path="/printAtm" component={printAtm} />
                <Route exact path="/printPhone" component={printPhone} />
                <Route exact path="/printConvenience" component={printConvenience} />
                <Route exact path="/printCounter" component={printCounter} />
                <Route exact path="/News" component={News} />
                <Route exact path="/SystemSurvey" component={SystemSurvey} />
                <Route exact path="/CourseSurvey" component={CourseSurvey} />
                <Route exact path="/ForgetPassword" component={ForgetPassword} />
                <Route exact path="/ResetPwd" component={ResetPwd} />
                <Route exact path="/CourseLessonInfo" component={CourseLessonInfo} />
                <Route exact path="/ChangePassword" component={ChangePassword}/>
                <Route exact path="/ApiUrl" component={ApiUrl} />
            </Switch>
        </BrowserRouter >
        </I18n>
);

ReactDOM.render(root, document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
{

    API_Views().then(res => res.data).then(data => {
        var v = document.getElementById("views");
        if (data.code == "0000") {
            v.innerHTML = "瀏覽人數:" + data.result.gaqtyInfo.gaqty + "/" + data.result.allGAQTYInfo.gaqty;
        }
    })
    var navigation = new Navigation(document.getElementById("navigation"), {
        submenuTrigger: "hover"
    });

}

import axios from 'axios';
import { getAuthToken } from './TokenUtil';

export const getHeaders = (token) => {
    return {
        'Content-Type': 'application/vnd.myapp.type+json',
        'Authorization': token
    }
}
//const API_URL = process.env.REACT_APP_API_URL;

const API_URL = window.apiUrl;
//API URL
//export const API_HOST = "https://localhost:7021/api";
//export const API_HOST = "https://demo-txgculture.summit-edu.com.tw/api";
//export const API_HOST = "https://culture-signup.taichung.gov.tw/api";
//�@�뱡�p��
const baseRequest = axios.create({
    baseURL: API_URL
});
//�U����
export const downloadRequest = axios.create({
    baseURL: API_URL,
    responseType: 'blob'
});
export const key = "summit";



//#region �@��api
export const apiLangSetting = data => baseRequest.get('/languageData');
export const apiAreaSetting = data => baseRequest.get('/areaData');
//#endregion

//#region �D�e��
//�D�e��-���o�v��
//export const API_GET_HomeData = data => baseRequest.get('/home/data', { headers: getHeaders(getAuthToken()) });
//#endregion

//#region �\��-0
//0-0 ���ͨϥΪ̱b��
//export const API_Account_Create = data => baseRequest.put('/account/createuser', JSON.stringify(data), { headers: getHeaders(getAuthToken()) });
//0-3 ���m�K�X
//export const API_Account_ResetPWD = data => baseRequest.get('/authenticate/resetpwd', { params: data, headers: getHeaders(getAuthToken()) });
//0-4 �C�X�ϥΪ��v��--------------------
//0-5 �ϥΪ̵��U

//�H���s��
export const API_Views = data => baseRequest.get('/Views/ViewsInfo', { params: data, headers: getHeaders(getAuthToken()) });

export const API_SignUp = data => baseRequest.post('/Login/SignUp', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json',  'Access-Control-Allow-Origin': '*' }
});
//�ϥΪ̵n�J
export const API_SignIn = data => baseRequest.post('/Login/Singin', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});
//Email����
export const API_EmailCert = data => baseRequest.post('/Login/EmailCert', JSON.stringify(data), {
    params: data,headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});
//���e���ҽX
export const API_ReSentEmailCert = data => baseRequest.post('/Login/ReSentEmailCert', JSON.stringify(data), {
    params: data, headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});
//�ѰO�K�X
export const API_ForgetPwd = data => baseRequest.post('/Front/ForgetPwd', JSON.stringify(data), {
    params: data, headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});
//���]�K�X
export const API_ChangePwd = data => baseRequest.post('/Login/ChangePwd', JSON.stringify(data), {
    params: data, headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken()  }
});
//���K�X�e�X
export const API_ResetPwd = data => baseRequest.post('/Front/ResetPwd', JSON.stringify(data), {
    params: data, headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }
});
//�ѰO�K�X��T
export const API_ForgetInfo = data => baseRequest.get('/Front/ForgetInfo', { params: data, headers: getHeaders(getAuthToken()) });
//���C��
export const API_DeptList = data => baseRequest.get('/Front/DeptList', { params: data, headers: getHeaders(getAuthToken()) });
//�ҵ{�D�e��
export const API_CourseHome = data => baseRequest.get('/Front/CourseSearchInfo', { params: data, headers: getHeaders(getAuthToken()) });
//�ҵ{�C��
export const API_Course = data => baseRequest.post('/Front/CourseInfo', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//�ҵ{��T 2023/3/7 
export const API_LessonInfo = data => baseRequest.get('/Lesson/LessonInfo', { params: data, headers: getHeaders(getAuthToken()) });
/*export const API_Course = data => baseRequest.get('/Front/CourseInfo', { params: data, headers: getHeaders(getAuthToken()) });*/
//�p��Logo
export const API_CenterLogo = data => baseRequest.get('/Front/CenterLogo', { params: data, headers: getHeaders(getAuthToken()) });
//�ݻP��-��쵡�f�p����T
export const API_FAQDeptInfo = data => baseRequest.get('/Front/FAQDeptInfo', { params: data, headers: getHeaders(getAuthToken()) });

//�����C��
export const API_Carousel = data => baseRequest.get('/Front/FrontCarousel', { params: data, headers: getHeaders(getAuthToken()) });

//�ҵ{�Ӹ`
export const API_CourseDtail = data => baseRequest.get('/Front/CourseDetail', { params: data, headers: getHeaders(getAuthToken()) });
//�ҵ{�Ӹ`
export const API_UserInfoBK = data => baseRequest.get('/Front/GetUserInfo', { params: data, headers: getHeaders(getAuthToken()) });
//�Ϯ��]�n�J
export const API_ValidLibAccount = data => baseRequest.post('/Front/ValidLibAccount', JSON.stringify(data), {
    params: data,
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//�Ϯ��]�n�J
export const API_ValidLibData = data => baseRequest.post('/Front/ValidLibData', JSON.stringify(data), {
    params: data,
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//�ҵ{���W
export const API_CourseReg = data => baseRequest.post('/Front/CourseReg', JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() }
});
//�����W��
export const API_AdmissList = data => baseRequest.get('/Front/AdmissionList', { params: data, headers: getHeaders(getAuthToken()) });

//�ӤH�ҵ{���W�C��
export const API_UserRegCous = data => baseRequest.get('/Front/UserRegCous', { params: data, headers: getHeaders(getAuthToken()) });

//�ҵ{����(�h�O����)
export const API_URegDetail = data => baseRequest.get('/Front/URegDetail', { params: data, headers: getHeaders(getAuthToken()) });
//�h�O�T�{
export const API_UserRefund = data => baseRequest.post('/Front/UserRefundpost', JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() } });
//�������W�T�{
export const API_UserRegCancel = data => baseRequest.post('/Front/UserRegCancel', JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() } });

//���ߦC��(�̷s���i����)
export const API_CenterList = data => baseRequest.get('Front/CenterList', { params: data, headers: getHeaders(getAuthToken()) });

//���i���O(�̷s���i����)
export const API_AnnouncementList = data => baseRequest.get('/Front/AnnouncementList', { params: data, headers: getHeaders(getAuthToken()) });

//�d�ߦC��(�̷s���i����)
export const API_MessageRecList = data => baseRequest.get('/Front/MessageRecList', { params: data, headers: getHeaders(getAuthToken()) });

//���i���e(�̷s���i����)
export const API_MessageRecContant = data => baseRequest.get('/Front/MessageRecContant', { params: data, headers: getHeaders(getAuthToken()) });
//�t�ΰݨ�
export const API_SystemQNR = data => baseRequest.get('Front/SystemQNR', { params: data, headers: getHeaders(getAuthToken()) });
//�ҵ{�ݨ�
export const API_CourseQNR = data => baseRequest.get('Front/GetCouQNR', { params: data, headers: getHeaders(getAuthToken()) });
//�ҵ{�ݨ�-�s�W
export const API_PostCourseQNR = data => baseRequest.post('Front/PostCouQNR', JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() } });
//�t�ΰݨ�-�s�W
export const API_PostSYSQNR = data => baseRequest.post('Front/PostSYSQNR', JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': getAuthToken() } });
//������X
export const API_Barcode = data => baseRequest.get('/Front/Barcode', { params: data, headers: getHeaders(getAuthToken()) });
//ATM���X
export const API_BarcodeATM = data => baseRequest.get('/Front/BarcodeATM', { params: data, headers: getHeaders(getAuthToken()) });
//PDF
export const API_BarcodePDF = data => downloadRequest.get('/Front/BarcodePDF', { params: data, headers: getHeaders(getAuthToken()) });
//PDF
export const API_ReceiptPDF = data => downloadRequest.get('/Front/ReceiptPDF', { params: data, headers: getHeaders(getAuthToken()) });
//�ɼ��ҩ�
export const API_ProofClassPDF = data => downloadRequest.get('/Front/ProofClassPDF', { params: data, headers: getHeaders(getAuthToken()) });

///Url��X
export function UrlDecode(str) {
    var uzipStr = '';
    for (var i = 0; i < str.length; i++) {
        var chr = str.charAt(i);
        if (chr === '+') {
            uzipStr = ' ';
        } else if (chr === '%') {
            var asc = str.substring(i + 1, i + 3)
            if (parseInt('0x' + asc) > 0x7f) {
                uzipStr += decodeURI('%' + asc.toString() + str.substring(i + 3, i + 9).toString())
                i += 8;
            } else {
                uzipStr += AsciiTostring(parseInt('0x' + asc));
                i += 2;
            }
        } else {
            uzipStr += chr;
        }
    }
    return uzipStr;
}
import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import { Helmet } from 'react-helmet'; //title
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function Sitemap() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
	  <>
		  <Helmet>
			  <title>臺中市政府文化局報名繳費系統-網頁導覽</title>
		  </Helmet>
		  <section class="section-box bg-white">

			  <div class="container">
				  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky focusactive">:::</a></div>
				  <h1 class="text-center">網頁導覽</h1>

				  <div class="personal-search">

					  <p>本網站依無障礙網頁設計原則建置，網站的主要內容分為三大區塊：<br />
						  1. 上方功能區塊、2. 中央內容區塊、3.下方功能區塊。<br />
						  <br />
						  本網站的快速鍵﹝Accesskey﹞設定如下：<br />
						  &nbsp;
					  </p>
					  <ul>
						  <li>Alt+U：右上方功能區塊，包括回首頁、網站導覽、網站搜尋、字體選擇等。</li>
						  <li>Alt+C：中央內容區塊，為本頁主要內容區。</li>
				{/*		  <li>Alt+S：網站搜尋。</li>*/}
						  <li>Alt+Z：下方功能區塊。</li>
					  </ul>

					  <p>如果您的瀏覽器是Firefox，快速鍵的使用方法是 Shift+Alt+(快速鍵字母)，例如 Shift+Alt+C會跳至網頁中央區塊，以此類推。</p>
					  <p><br />
						  ※當本網站項目頁籤無法以滑鼠點選時，您可利用以下鍵盤操作方式瀏覽資料<br />
						  ← → or ↑↓：按左右鍵或上下鍵移動標籤順序。<br />
						  Home or End→：可直接跳至標籤第一項或者最後一項。<br />
						  Tab：停留於該標籤後,可利用Tab鍵跳至內容瀏覽該筆資料，遇到radio按鈕時請配合使← → or↑↓鍵移動項目順序。<br />
						  Tab + Shift：按Tab + Shift可往回跳至上一筆資料；當跳回至標籤項目時您可繼續利用← → or↑↓鍵移動標籤順序。<br />
						  &nbsp;</p>
					  <ul class="li-style-link">						 
						  <li class="nav-1"><strong><a href="/" className="focusactive"  title="移至首頁">1.首頁</a></strong></li>
					  </ul>
					  <div><strong><p>課程</p></strong></div>
					  <ul class="li-style-link">

						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=2" title="移至大墩文化中心課程">2.台中市政府文化局課程</a></strong></li>
						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=6" title="移至大墩文化中心課程">3.大墩文化中心課程</a></strong></li>
						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=9" title="移至大墩文化中心課程">4.葫蘆墩文化中心課程</a></strong></li>
						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=10" title="移至大墩文化中心課程">5.港區藝術中心課程</a></strong></li>
						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=7" title="移至大墩文化中心課程">6.屯區藝文中心課程</a></strong></li>
						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=11" title="移至大墩文化中心課程">7.纖維工藝展覽中心課程</a></strong></li>
						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=4" title="移至大墩文化中心課程">8.臺中市立圖書館課程</a></strong></li>
						  <li class="nav-1"><strong><a className="focusactive"  href="/CourseListTable?DeptID=12" title="移至大墩文化中心課程">9.臺中市文資處課程</a></strong></li>
					  </ul>
					  <ul class="li-style-link">
						  <li class="nav-1"><strong><a href="/Announcement" title="移至最新消息">10.最新公告</a></strong></li>
						  <li class="nav-2"><strong><a href="/FAQ" title="移至常見問題">11.常見問題</a></strong></li>
						  <li class="nav-3"><strong><a className="focusactive"  href="/StudentSearch" title="移至繳費/退費">12.繳費/退費</a></strong></li>
						  <li class="nav-4"><strong><a className="focusactive" href="/StudentSearchCosure" title="移至學員專區">13.研習之友專區</a></strong></li>
						  <li class="nav-4"><strong><a className="focusactive" href="/FAQ#tab5" title="尋求幫助">13.尋求幫助</a></strong></li>
						  <li class="nav-5"><strong><a className="focusactive"  href="/IdentityVerify" title="移至登入">14.登入</a></strong></li>
					  </ul>
				  </div>
			  </div>
		  </section>
     </>
  );
}
export default Sitemap;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_MessageRecContant } from '../global/constants'; //公告內容
import Swal from "sweetalert2";

function News() {
    const history = useHistory();

    const queryParams = new URLSearchParams(window.location.search);

    //最新公告
    const [NewsContant, setNewsContant] = useState({});
   
    const MsgID = queryParams.get("MsgID");
   
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--最新消息內文";
      //取得最新消息API 內容
        API_MessageRecContant({ "MsgID": MsgID })
          .then((response) => response.data)
          .then((data) => {
              if (data.code == "0000") {
                  console.log(data.result)
                  //
                  if (data.result != null) {
                      setNewsContant(data.result);
                      var detail = document.getElementById('news-detail');
                      detail.innerHTML = data.result.MsgData;
                  }
                  else {
                      Swal.fire("查無此消息", "", "warning").then(ok => {
                          //alert("查無此消息");
                          window.location = '/Announcement';
                      });
                  }                  
                  
              }
              else if (data.code == "9999"){
                  history.goBack();
              }
              else {
                  Swal.fire(data.message, "", "warning");                  
                  //alert(data.message);
              }
          })
          .catch(err => {
             console.log(err);
          })
    }, []);

  

  return (
      <>
          <section className="section-box">
              <div className="container">

                  {/* 導盲磚 */}
                  <div>
                      <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" className="a-acceky focusactive">:::</a>
                  </div>

                  <h1 className="text-center">最新消息</h1>

                  <div className="form-box">
                      <div class="news-area">
                          
                          {/*<!-- 日期 -->*/}
                          <div class="news-date">
                              <span class="year">{NewsContant.MsgPushYM} </span>
                              <span class="date">{NewsContant.MsgPushD}</span>
                          </div>

                          {/*<!-- 內文 -->*/}
                          <div class="news-content">

                              {/*<!-- 發布資訊 -->*/}

                              <h2>{NewsContant.MsgSub}</h2>
                              <div id="news-detail"></div>          
                              
                              {/*<!-- 回上一頁 -->*/}
                              <div class="text-center text-md-right">
                                  <button type="button" onClick={() => { history.goBack(); }} value="返回上一頁"
                                      class="btn btn-large btn-primary mt-5 focusablack ">上一頁</button>
                              </div>

                          </div>
                          {/* <!-- end 內文 -->*/}

                      </div>

                  
                     

                </div>

              </div>
          </section>
      </> 
  );
}
export default News;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";



function ApiUrl() {


    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--API連線位址";

    }, []);
    const url = process.env.REACT_APP_API_URL;

    return (
        <>

            <div style={{textAlign: 'center', padding: '20px' ,color: '#c3915b', fontSize: '1.875rem',  fontWeight: '600' }}>
                目前連線的Api Url:
                <br />
                {url}
            </div>
        </>
    );
}
export default ApiUrl;

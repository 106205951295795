import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import { Helmet } from 'react-helmet'; //title
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { getAuthToken, checkAuthToken } from "../global/TokenUtil";
import { API_UserRegCous, API_ReceiptPDF, API_ValidLibData, API_ValidLibAccount, API_UserRegCancel, API_ProofClassPDF } from '../global/constants';
import Swal from "sweetalert2";
import { key } from "../global/constants"
import CryptoJS from 'crypto-js';
function StudentSearch() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [listShow, setlistShow] = useState(false);
    const [ID, setID] = useState("");
    const [encryptId, setEncryptId] = useState("")//加密後的id
    const [UserData, setUser] = useState({});
    const [UserName, setUserName] = useState("");
    const [UserRegList, setUserRegList] = useState([{}]);
    const FileDownload = require("js-file-download");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {


        const getCurrentDate = () => {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            setCurrentDate(formattedDate);


        };
        getCurrentDate();


        if (getAuthToken() == "xxx") {

        }
        if (localStorage.getItem("UserData") != null) {
            setUser(JSON.parse(localStorage.getItem("UserData")));
            setID(JSON.parse(localStorage.getItem("UserData")).userIDNo);
            setPhoneNumber(JSON.parse(localStorage.getItem("UserData")).userPhone);
            getData(JSON.parse(localStorage.getItem("UserData")).userIDNo, JSON.parse(localStorage.getItem("UserData")).userPhone);
            setlistShow(true);
        }
    }, []);
    function ReceiptDL(i) {
        API_ReceiptPDF({
            URegID: UserRegList[i].URegID,
        }).then((response) => {
            FileDownload(response.data, UserRegList[i].CouName+"繳費證明.pdf");
        });
    }
    
    function ProofClassDL(i) {
        API_ProofClassPDF({
            URegID: UserRegList[i].URegID,
        }).then((response) => {
            FileDownload(response.data, UserRegList[i].CouName + '_' + UserRegList[i].UserName + "時數證明.pdf");
        });
    }

    async function LibLogin() {
        var libres = false;
        var loading = document.getElementById('loading');
        var libData = localStorage.getItem("libData");
        if (libData != null) {
            await API_ValidLibData({ Data: libData })
                .then(res => res.data)
                .then(data => {
                    if (data.code == "0000") {
                        libData = data.result;
                        localStorage.setItem("libData", libData);
                        libres = true;
                    }
                    else {
                        libres = false;
                    }
                })
        }
        if (!libres) {
            await Swal.fire({
                title: '中市圖讀者登入',
                html:
                    '' +
                    '<div>' +
                    '<span>帳號</span>' +
                    '<input id="swal-input1" class="swal2-input" value=' + ID + ' disabled  required>' +
                    '</div>' +
                    '<div>' +
                    '<span>密碼</span>' +
                    '  <input id="swal-input2" class="swal2-input" required>' +
                    '</div>',
                focusConfirm: false,
                showCancelButton: false,
                confirmButtonText: "登入",
                preConfirm: async () => {
                    Swal.resetValidationMessage();
                    if (document.getElementById('swal-input2').value == '') {
                        Swal.showValidationMessage('請輸入密碼');
                        return false;
                    }

                }

            }).then(async ok => {
                if (ok.isConfirmed) {
                    loading.style.display = "block";
                    await API_ValidLibAccount({ UserName: document.getElementById('swal-input1').value, Password: document.getElementById('swal-input2').value })
                        .then(res => res.data)
                        .then(async data => {
                            if (data.code == "0000") {
                                loading.style.display = "none";
                                libData = data.result;
                                localStorage.setItem("libData", libData);
                            }
                            else {
                                loading.style.display = "none";
                                await Swal.fire(data.message, "失敗", "error");
                                libData = null;
                            }
                        })
                }
                else if (ok.isDismissed) {
                    await Swal.fire({
                        title: "臺中市圖書館帳號尚未登入，無法使用點數扣抵",
                        icon: "info"
                    }).then(ok => {
                        libData = null;
                    });
                }
            })
        }
        return libData;
    }
   async function CancelReg(i) {
        if (getAuthToken() == "xxx") {
            Swal.fire('請先登入', '', 'info').then(
                () => {
                    history.push("/IdentityVerify");
                }
            )
        }
        else {
            var isOK = true;
            var libData = "";
            console.log(UserRegList[i].URMCardType);
            if (UserRegList[i].URMCardType == "A") {
                libData =await LibLogin();
                console.log(libData);
                if (libData == null) {
                    isOK = false;
                }
            }
            else {
                libData = null;
            }
            if (isOK) {
                Swal.fire({
                    icon: "question",
                    title: '確認要取消報名?',
                    showDenyButton: true,
                    confirmButtonText: '確定',
                    denyButtonText: `取消`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        API_UserRegCancel({
                            URegID: UserRegList[i].URegID,
                            Data: libData
                        }).then(res => res.data)
                            .then(data => {
                                if (data.code == "0000") {
                                    Swal.fire('已取消課程報名', '', 'info').then(
                                        () => { getData(ID); }
                                    )
                                }
                                else {
                                    Swal.fire(data.message, '', 'info').then(
                                        () => { getData(ID); }
                                    )
                                }
                            }).catch(ex => {
                                if (ex.code == "1001") {
                                    Swal.fire('請先登入', '', 'info').then(
                                        () => { history.push("/IdentityVerify"); }
                                    )
                                }
                            });
                    } else if (result.isDenied) {
                        //Swal.fire('已取消', '', 'info')
                    }
                })
            }
        }
    }
    function submit() {
        getData(ID, phoneNumber);
    }
    function getData(UserIDNo, phoneNumber) {
        API_UserRegCous({
            UserIDNo: UserIDNo,
            PhNumber: phoneNumber
        })
        .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    console.log(data);
                    setUserRegList(data.result.ur);
                    setUserName(data.result.userName);
                    setlistShow(true);
                }
                else {
                    setUserRegList([{}]);
                    Swal.fire(data.message, "", "warning");
                    //window.location = "/";
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const _handleInputPhoneNumber = (e) => {
        const value = e.target.value.trim();
        const regex = /^\d*$/;
        console.log(key)
        if (regex.test(value) ) {

            
            setPhoneNumber(value);
        }
        else {
            alert("只允許輸入數字")
        }
    }

  return (
      <>
          <Helmet>
              <title>臺中市政府文化局報名繳費系統-查詢可退繳費課程</title>
          </Helmet>
          <section className="section-box bg-white">
              <div className="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky focusactive">:::</a></div>
                  <h1 className="text-center">查詢可以繳費或退費課程</h1>

                  <div className="personal-search">

                      <div className="form-item mt-5 mb-md-5">
                          <div className="field"><label htmlFor="IDNum">身分證號/居留證號</label></div>                       
                          <div className="value">
                              <input type="text" className="focusactive" id="IDNum" value={ID} onChange={e => setID(e.target.value)} title="請輸入身分證號碼或居住證號" placeholder="請輸入身分證號碼或居住證號" required />
                             
                          </div>
                          <div className="field"><label htmlFor="phoneNumber">報名時手機號碼</label></div>
                          <div className="value">
                          <input type="text" className="phoneNumber" id="phoneNumber" value={phoneNumber} onChange={e => _handleInputPhoneNumber(e)} title="請輸入電話" placeholder="請輸入電話" required maxlength={10} />
                          </div>
                      </div>

                      <div className="form-item btn-area">
                          <button type="button" onClick={submit}
                              className="btn btn-primary btn-large focusablack" value="search">查詢</button>
                      </div>

                  </div>


              </div>
          </section>
          <section className="section-box bg-gray-100" style={listShow == false ? show : hide}>
              <div className="container">

                  <p className="text-center mt-4">請輸入身分證號碼以查詢個人報名課程及相關資訊！</p>

              </div>
          </section>
          <section className="section-box bg-gray-100" style={listShow == true ? show : hide}>
              <div className="container">

                  <h2 className="text-center">學員：{UserName}</h2>

                  <div className="table-box table-responsive">
                      <table>
                          <thead>
                              <tr>
                                  <th>開課日期</th>
                                  <th>期別</th>
                                  <th>課程名稱</th>
                                  <th>活動單位</th>
                                  <th>報名狀態</th>
                                  <th>繳費情況</th>
                                  <th>報名費</th>
                                  <th>課程狀況</th>
                                  <th>取消報名</th>
                                  <th>功能</th>
                              </tr>
                          </thead>
                          <tbody>
                              {
                                  UserRegList.map((x,i) => (
                                      <tr>
                                          <td>{x.CouStartDate}</td>
                                          <td>{x.CouPName}</td>
                                          <td>{x.CouName}</td>
                                          <td>{x.DeptName}</td>
                                          <td><b className="text-red">{x.URegDistText}</b></td>
                                          <td><b className="text-pay">{x.URegPayText}</b></td>
                                          <td>{x.CouMoney}</td>
                                          
                                          <td>{x.CouStatusText}</td>
                                          <td>
                                              {

                                             (x.URegPay == 1 || x.URegPay == null) && x.CouStatusText == "報名中" && x.URegDist != 5 ?
                                              <button type="button" id="pay" onclick="window.location.href='#';" onClick={(e) => CancelReg(i)} className="btn btn-secondary mr-1" value="show">取消報名</button>
                                              : null}</td>
                                          {
                                              x.URegPay == 2 ?
                                                  <td>
                                                      <button type="button" id="pay" onclick="window.location.href='#';" onClick={(e) => ReceiptDL(i)} className="btn btn-secondary mr-1" value="show">繳費證明</button>
                                                      {
                                                          x.Closed ?
                                                              <>
                                                                  <button onClick={() => { history.push('/CourseSurvey?URegID=' + x.URegID); }} className="btn-line btn-secondary" value="refune">填寫問卷</button>
                                                                  <button type="button" id="pay" onclick="window.location.href='#';" onClick={(e) => ProofClassDL(i)} className="btn btn-secondary mr-1" value="show">時數證明書</button>
                                                              </>

                                                            :
                                                            <button onClick={() => { history.push('/Applyrefund?URegID=' + x.URegID); }} className="btn-line btn-secondary" value="refune">申請退費</button>
                                                      }
                                                      
                                                  </td>
                                              : x.URegPay == 1
                                                ? <td><button type="button" onClick={() => { window.location = "/printAtm?URegID=" + x.URegID }} className="btn btn-secondary" value="download">下載繳費單</button></td>
                                              : x.URegPay == 3
                                                ? <td><button type="button" onclick="window.location.href='#';" className="btn-line btn-secondary" value="download">退費單下載</button></td>
                                                :< td ></td>
                                          }
                                      </tr>                                      
                                      ))
                              }

                              {/*<tr>*/}
                              {/*    <td>111/09/24</td>*/}
                              {/*    <td>111春季班</td>*/}
                              {/*    <td>仿真粘土-冷瓷花及多肉植物</td>*/}
                              {/*    <td>大墩活動中心</td>*/}
                              {/*    <td><b className="text-red">已錄取</b></td>*/}
                              {/*    <td><b className="text-pay">完成繳費</b></td>*/}
                              {/*    <td>1500</td>*/}
                              {/*    <td>報名中</td>*/}
                              {/*    <td><button type="button" id="pay" onclick="window.location.href='#';" className="btn btn-secondary mr-1" value="show">繳費證明</button>*/}
                              {/*        <button onClick={() => { history.push('/Applyrefund'); }} className="btn-line btn-secondary" value="refune">申請退費</button>*/}
                              {/*    </td>*/}
                              {/*</tr>*/}

                              {/*<tr>*/}
                              {/*    <td>111/10/20</td>*/}
                              {/*    <td>111春季班</td>*/}
                              {/*    <td>藝術手工皂及保養品</td>*/}
                              {/*    <td>大墩活動中心</td>*/}
                              {/*    <td><b>候補</b></td>*/}
                              {/*    <td></td>*/}
                              {/*    <td>2600</td>*/}
                              {/*    <td>繳費中</td>*/}
                              {/*    <td>*/}
                              {/*    </td>*/}
                              {/*</tr>*/}

                              {/*<tr>*/}
                              {/*    <td>111/11/10</td>*/}
                              {/*    <td>-</td>*/}
                              {/*    <td>水墨畫（一）｜陳華</td>*/}
                              {/*    <td>市立圖書館-西屯分館</td>*/}
                              {/*    <td><b className="text-red">待繳費</b></td>*/}
                              {/*    <td><b className="text-pay">未繳費</b></td>*/}
                              {/*    <td>2000</td>*/}
                              {/*    <td>報名中</td>*/}
                              {/*    <td><button type="button" onclick="window.location.href='print/2.mobile-payment.html';" className="btn btn-secondary" value="download">下載繳費單</button>*/}
                              {/*    </td>*/}
                              {/*</tr>*/}

                              {/*<tr>*/}
                              {/*    <td>111/12/10</td>*/}
                              {/*    <td>-</td>*/}
                              {/*    <td>書法｜張志鴻</td>*/}
                              {/*    <td>港區藝術中心</td>*/}
                              {/*    <td><b className="text-red">待繳費</b></td>*/}
                              {/*    <td><b className="text-pay">未繳費</b></td>*/}
                              {/*    <td>3000</td>*/}
                              {/*    <td>繳費中</td>*/}
                              {/*    <td><button type="button" onclick="window.location.href='print/2.mobile-payment.html';" className="btn btn-secondary" value="download">下載繳費單</button>*/}
                              {/*    </td>*/}
                              {/*</tr>*/}

                              {/*<tr>*/}
                              {/*    <td>111/12/18</td>*/}
                              {/*    <td>-</td>*/}
                              {/*    <td>水墨畫（二）｜陳華</td>*/}
                              {/*    <td>-</td>*/}
                              {/*    <td><b>未錄取</b></td>*/}
                              {/*    <td></td>*/}
                              {/*    <td>2200</td>*/}
                              {/*    <td>報名截止</td>*/}
                              {/*    <td>*/}
                              {/*    </td>*/}
                              {/*</tr>*/}

                              {/*<tr>*/}
                              {/*    <td>112/01/08</td>*/}
                              {/*    <td>-</td>*/}
                              {/*    <td>水墨畫（三）｜陳華</td>*/}
                              {/*    <td>港區藝術中心</td>*/}
                              {/*    <td><b className="text-red">申請退費</b></td>*/}
                              {/*    <td></td>*/}
                              {/*    <td>2200</td>*/}
                              {/*    <td>報名截止</td>*/}
                              {/*    <td><button type="button" onclick="window.location.href='#';" className="btn-line btn-secondary" value="download">退費單下載</button>*/}
                              {/*    </td>*/}
                              {/*</tr>*/}
                          </tbody>
                      </table>
                  </div>


              </div>
          </section>
      </>
  );
}
export default StudentSearch;

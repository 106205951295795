import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_LessonInfo } from '../global/constants';
import { checkAutdToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
function CourseLessonInfo() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const mainTit = {

        fontSize: '1.875rem',
        fontWeight: '600',
        marginBottom: '1.875rem',
        textAlign: 'center',
    }

    useEffect(() => {
        API_LessonInfo({
            CouID: parseInt(queryParams.get('CouID')),
        })
            .then((res) => res.data)
            .then(data => {

                if (data.code == "0000") {
                    console.log(data);
                    setImgInfo(data.result.lessonImgInfo);
                    setLessonInfo(data.result.lessonInfo);
                }
                else {
                    Swal.fire(data.message, "錯誤", "error").then
                        ((res) => {
                            window.history.back();
                        });
                }

            }).catch(e => {
                console.log(e);
            })
    }, []);

    const [lessonImgInfo, setImgInfo] = useState([]);
    const [lessonInfo, setLessonInfo] = useState([]);

    return (
        <>
            <section class="section-box bg-gray-100">
                <div class="container">
                    {/*<!-- 課程列表 -->*/}
                    <div class="course">
                        {/*<!-- table -->*/}
                        <div class="table-box table-responsive">
                            <table>
                                <tr>
                                    <td colSpan="2" style={{ textAlign: 'center' }}>
                                        <span style={mainTit}>班別資訊</span> <br />
                                        <span style={{ fontSize: '85%', 'color': '#b7b7b7', fontWeight: 'lighter', textAlign: 'center' }}>LESSON INFO</span> <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>單位名稱：</td>
                                    <td>
                                        {
                                            lessonInfo.deptName
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>課程名稱：</td>
                                    <td>
                                        {
                                            lessonInfo.couName
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>適用對象：</td>
                                    <td>
                                        {
                                            lessonInfo.couTarget
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '10%', textAlign: 'right' }}>日期：</td>
                                    <td>
                                        {
                                            lessonInfo.couStartDate == null ? null :
                                                lessonInfo.couStartDate.split('T')[0]
                                        }
                                        ~
                                        {
                                            lessonInfo.couCloseDate == null ? null :
                                                lessonInfo.couCloseDate.split('T')[0]
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>上課時段：</td>
                                    <td>
                                        {"("} {lessonInfo.couWeeks} {")"}
                                        {
                                            lessonInfo.couStartDate == null ? null :
                                                lessonInfo.couStartTime.split(':')[0] + ":" + lessonInfo.couStartTime.split(':')[1]
                                        }
                                        ~
                                        {
                                            lessonInfo.couCloseDate == null ? null :
                                                lessonInfo.couCloseTime.split(':')[0] + ":" + lessonInfo.couCloseTime.split(':')[1]
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>上課時數：</td>
                                    <td>
                                        {lessonInfo.couHour}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>上課地點：</td>
                                    <td>
                                        {lessonInfo.couPlace}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>報名費：</td>
                                    <td>
                                        {lessonInfo.couMoney}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>名額：</td>
                                    <td>
                                        {lessonInfo.couAmount}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>教師姓名：</td>
                                    <td>
                                        {lessonInfo.couTeacherName}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>教師經歷：</td>
                                    <td>
                                        {lessonInfo.teacherExp}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>課程內容：</td>
                                    <td>
                                        {lessonInfo.couDesc}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>備註：</td>
                                    <td>
                                        {
                                            lessonInfo.couRemark
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        {
                                            lessonImgInfo == null || lessonImgInfo.length == 0 ? null :
                                                lessonImgInfo.map((x, i) =>
                                                    <div key={i} style={{ width: "20%", display: "inline-block" }} >
                                                        <img style={{ width: "100%", height: "auto" }} src={x.ATTFPath} />
                                                    </div>
                                                )
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" style={{ textAlign: 'center' }}>
                                        <button onClick={() => { history.push('/CourseListTable?DeptID=' + queryParams.get('DeptID')) }} className="btn-line btn-primary btn-small focusablack">返回</button>
                                    </td>

                                </tr>
                            </table>
                        </div>
                    </div>


                </div>
            </section>
        </>
    );
}
export default CourseLessonInfo;

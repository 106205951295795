import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { getAuthToken, checkAuthToken } from "../global/TokenUtil";

function StudentSearchResultQuery() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const [listShow, setlistShow] = useState(false);
    const [ID, setID] = useState("");
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--查詢個人課程";
        checkAuthToken(data);
        setID(localStorage.getItem("ID"));
        if (localStorage.getItem("Std") != null && localStorage.getItem("Std") != '') {
            setlistShow(true);
        }
    }, []);
    function submit() {
        if (ID == '' || ID == null) {
            history.push('\IdentityVerify');
        }
    }
  return (
      <>

          <section class="section-box bg-white">
              <div class="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1 class="text-center">查詢個人課程</h1>

                  <div class="personal-search">

                      <div class="form-item mt-5 mb-md-5">
                          <div class="field">身分證號碼 /居留證碼</div>
                          <div class="value">
                              <input type="text" value="A123456789" title="ID"/>
                          </div>
                      </div>

                      <div class="form-item btn-area">
                          <button type="button" onclick="window.location.href='student-search-result-query.html';"
                              class="btn btn-primary btn-large" value="research">重新查詢</button>
                      </div>

                  </div>


              </div>
          </section>


         
          <section class="section-box bg-gray-100">
              <div class="container">

                  <h2 class="text-center">學員：陳曉明</h2>


                  {/*<!-- table -->*/}
                  <div class="table-box table-responsive">
                      <table>
                          <thead>
                              <tr>
                                  <th>開課日期</th>
                                  <th>期別</th>
                                  <th>課程名稱</th>
                                  <th>活動單位</th>
                                  <th>報名狀態</th>
                                  <th>繳費情況</th>
                                  <th>報名費</th>
                                  <th>課程狀況</th>

                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>111/09/24</td>
                                  <td>111春季班</td>
                                  <td>仿真粘土-冷瓷花及多肉植物</td>
                                  <td>大墩活動中心</td>
                                  <td><b class="text-red">已錄取</b></td>
                                  <td><b class="text-pay">完成繳費</b></td>
                                  <td>1500</td>
                                  <td>報名中</td>

                              </tr>

                              <tr>
                                  <td>111/10/20</td>
                                  <td>111春季班</td>
                                  <td>藝術手工皂及保養品</td>
                                  <td>大墩活動中心</td>
                                  <td><b>候補</b></td>
                                  <td></td>
                                  <td>2600</td>
                                  <td>繳費中</td>
                                  <td>
                                  </td>
                              </tr>

                              <tr>
                                  <td>111/11/10</td>
                                  <td>-</td>
                                  <td>水墨畫（一）｜陳華</td>
                                  <td>市立圖書館-西屯分館</td>
                                  <td><b class="text-red">待繳費</b></td>
                                  <td><b class="text-pay">未繳費</b></td>
                                  <td>2000</td>
                                  <td>報名中</td>

                              </tr>

                              <tr>
                                  <td>111/12/10</td>
                                  <td>-</td>
                                  <td>書法｜張志鴻</td>
                                  <td>港區藝術中心</td>
                                  <td><b class="text-red">待繳費</b></td>
                                  <td><b class="text-pay">未繳費</b></td>
                                  <td>3000</td>
                                  <td>繳費中</td>

                              </tr>

                              <tr>
                                  <td>111/12/18</td>
                                  <td>-</td>
                                  <td>水墨畫（二）｜陳華</td>
                                  <td>-</td>
                                  <td><b>未錄取</b></td>
                                  <td></td>
                                  <td>2200</td>
                                  <td>報名截止</td>

                              </tr>

                              <tr>
                                  <td>112/01/08</td>
                                  <td>-</td>
                                  <td>水墨畫（三）｜陳華</td>
                                  <td>港區藝術中心</td>
                                  <td><b class="text-red">申請退費</b></td>
                                  <td></td>
                                  <td>2200</td>
                                  <td>報名截止</td>

                              </tr>
                          </tbody>
                      </table>
                  </div>
                  {/*<!-- end table-box -->*/}


              </div>
          </section>
     </>
  );
}
export default StudentSearchResultQuery;

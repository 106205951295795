import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function printConvenience() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
         
              
          <section className="section-box">
              <div className="container">


                  {/*  導盲磚 */}
                  <div>
                      <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a>
                  </div>


                  <h1 className="text-center">繳費單下載</h1>


                  {/*  繳費單種類 */}
                  <div className="print-category">
                      <div className="news-category">

                          <ul className="news-category-list">

                              <li>
                                  <a href="/printAtm" title="ATM繳費資訊與ATM繳費單下載">
                                      <i className="im im-icon-ATM"></i>
                                      ATM 繳費資訊
                                  </a>
                              </li>

                              <li>
                                  <a href="/printPhone" title="超商繳費資訊與手機版繳費單下載">
                                      <i className="im im-icon-Smartphone-3"></i>
                                      超商繳費（手機）
                                  </a>
                              </li>

                              <li>
                                  <a className="active" href="/printConvenience" title="超商繳費資訊與超商繳費單下載">
                                      <i className="im im-icon-Billing"></i>
                                      超商繳費單
                                  </a>
                              </li>

                              <li>
                                  <a href="/printCounter" title="臨櫃繳費資訊與臨櫃繳費單下載">
                                      <i class="im im-icon-Money-2"></i>
                                      臨櫃繳費單
                                  </a>
                              </li>

                          </ul>

                      </div>
                  </div>
                  {/*  end 繳費單種類 */}


                   {/*  內容 */}
                  <div className="form-box">

                      {/*  列印範圍 */}
                      <div id="print">

                          <h1>超商繳費單</h1>
                          <h2>大墩文化中心仿真粘士-冷瓷花及多肉植物 研習課程報名費</h2>
                          <div className="date-info"><span>日期：2022/10/30</span><span>第一聯：收執聯</span></div>

                          <table>
                              <tr>
                                  <td width="35%">繳費人：陳小明</td>
                                  <td>備註</td>
                              </tr>
                              <tr>
                                  <td>繳費帳號：1234567890123456</td>
                                  <td rowspan="4">
                                      <ol>
                                          <li>每筆繳費帳號皆為獨立，請勿使用他人帳號繳款。</li>
                                          <li>超商繳費上限金額為3萬元。</li>
                                          <li>以本條碼方式至超商（7-11、全家、萊爾富、OK）繳費後，請務必保留「代收款繳費證明單」，以保障權益。</li>
                                          <li>建議將手機/平板之螢幕亮度調至最亮，以確保繳費條碼正常讀取。<br />
                                              **條碼列印建議使用雷射印表機或噴墨印表（草稿模式）</li>
                                      </ol>
                                  </td>
                              </tr>
                              <tr>
                                  <td>繳費期限：2022-10-05</td>
                              </tr>
                              <tr>
                                  <td>繳費金額：5000元</td>
                              </tr>
                              <tr>
                                  <td>合計：新臺幣伍仟元整</td>
                              </tr>
                          </table>

                          <hr/>

                              <h1>超商繳費單</h1>
                              <h2>大墩文化中心仿真粘士-冷瓷花及多肉植物 研習課程報名費</h2>
                          <div className="date-info"><span>日期：2022/10/30</span><span>第二聯：代收單位留存聯</span></div>

                              <table>
                                  <tr>
                                      <td width="35%">繳費人：陳小明</td>
                                      <td>備註</td>
                                  </tr>
                                  <tr>
                                      <td>繳費帳號：1234567890123456</td>
                                      <td rowspan="4">
                                      <div align="center"><img src="images/fake/print/barcode.jpg" alt="7-11、全家、萊爾富、OK專用條碼區" class="pic-size" /></div>
                                          <div align="center">7-11 / 全家 / 萊爾富/ OK 專用條碼區</div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>繳費期限：2022-10-05</td>
                                  </tr>
                                  <tr>
                                      <td>繳費金額：5000元</td>
                                  </tr>
                                  <tr>
                                      <td>合計：新臺幣伍仟元整</td>
                                  </tr>
                              </table>

                      </div>
                      {/*  end 列印範圍 */}

                      <div className="text-center mt-5">
                          <button name="print" type="button" value="列印繳費單" onclick="Print_Div()"
                              className="btn btn-primary btn-large">列印繳費單</button>
                      </div>

                  </div>
                  {/*  end 內容 */}


              </div>
          </section>


    
     </>
  );
}
export default printConvenience;

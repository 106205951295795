import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import { Helmet } from 'react-helmet'; //title
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_SignIn, API_EmailCert, API_ReSentEmailCert } from '../global/constants';
import { key } from "../global/constants"
import { checkAuthToken } from "../global/TokenUtil";
import ClientCaptcha from 'react-client-captcha';
import Swal from "sweetalert2";
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
function IdentityVerify() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [ID, setID] = useState('');
    const [UserIDNo, setUserIDNo] = useState('');
    const [UserPwd, setUserPwd] = useState('');
    const [CertCode, setCertCode] = useState('');
    const [token, settoken] = useState('');
    const [PreUrl, setPreUrl] = useState('');
    const [Email, setEmail] = useState('');
    const [EmailHidden, setEmailHidden] = useState(true);
    const [LoginHidden, setLoginHidden] = useState(false);
    const [captchaCode, setCaptchaCode] = useState('');
    const [UserCaptchaCode, setUserCaptchaCode] = useState('');
    const [encryptUserPwd, setEncryptUserPwd] = useState("");
    document.onkeydown = function (event) {
        var target, code, tag;
        if (!event) {
            event = window.event;
            code = event.keyCode;
            if (code == 13) {
                console.log(target.form);

            }
        } else {
            target = event.target; //針對遵循w3c標準的瀏覽器
            code = event.keyCode;
            if (code == 13) {
                if (target.form.id == "loginForm") {
                    submit();
                }
                else if (target.form.id == "emailForm") {
                    Emailsubmit();
                }
            }
        }
    };
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--學員登入頁";
        if (localStorage.getItem('UserData') != null) {
            window.location = '/';
        }
        if (localStorage.getItem("preUrl") != null) {
            setPreUrl(localStorage.getItem("preUrl"));
        }
        localStorage.removeItem("preUrl");
    }, []);
    function aesDecryptBase64(Source, cryptoKey) {
        try {
            // 生成 SHA256 哈希作为密钥
            const key = CryptoJS.SHA256(cryptoKey);

            // 生成 MD5 哈希作为 IV
            const iv = CryptoJS.MD5(cryptoKey);

            // 解密数据
            const decrypted = CryptoJS.AES.decrypt(Source, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            // 将解密后的数据从 WordArray 转换为 UTF-8 字符串
            const decryptedStr = CryptoJS.enc.Utf8.stringify(decrypted);

            return decryptedStr;
        } catch (err) {
            console.error('Decryption failed:', err);
            return null;
        }
    }
    function submit() {
        if (captchaCode === '') {
            Swal.fire("請輸入驗證碼", "", "warning");
            //alert('請輸入驗證碼');
            event.preventDefault();
            return false;
        }

        if (captchaCode.toLowerCase() != UserCaptchaCode.toLowerCase()) {
            Swal.fire("驗證碼輸入錯誤", "", "warning");
            //alert('驗證碼輸入錯誤');
            event.preventDefault();
            return false;
        }
        var dt =
        {
            AcntNo: UserIDNo,
            AcntPwd: UserPwd
        }
        //console.log(dt);
        var loading = document.getElementById('loading');
        loading.style.display = "block";
        API_SignIn(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    var c = checkAuthToken(data);

                    var originalText = aesDecryptBase64(data.result, key);
                    var _data = JSON.parse(originalText);
                    console.log(_data);
                    localStorage.setItem('UserData', JSON.stringify(_data.data));
                    Swal.fire("登入成功", "成功", "success").then(ok => {
                        if (PreUrl != '') {
                            window.location = PreUrl;
                        }
                        else
                            window.location = "/";
                    });

                }
                else if (data.code == "1044") {
                    Swal.fire("帳號尚未啟用，請驗證Email", "", "warning").then(ok => {
                        setEmail(data.result.email);
                        setEmailHidden(false);
                        setLoginHidden(true);
                    });

                }
                else if (data.code == "1038" || data.code == "1023") {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    var c = checkAuthToken(data);
                    Swal.fire("密碼到期，請重設密碼。", "重設密碼", "info").then(ok => {
                        localStorage.setItem('UserID', JSON.stringify(UserIDNo));
                        window.location = "/ChangePassword";
                    });
                }
                else {
                    Swal.fire(data.message, "", "error");
                }
                loading.style.display = "none";
            })
            .catch(err => {
                loading.style.display = "none";
                console.log(err);
            })
        event.preventDefault();
    }
    function Emailsubmit() {
        var dt =
        {
            AcntNo: UserIDNo,
            CertCode: CertCode,
            CertType: "0"
        }
        //console.log(dt);
        API_EmailCert(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    if (!checkAuthToken(data)) {
                        history.push("/");
                    }
                    var c = checkAuthToken(data);
                    localStorage.setItem('UserData', JSON.stringify(data.result.data));
                    //alert("登入成功");
                    Swal.fire("登入成功", "", "success").then(ok => {
                        if (PreUrl != '') {
                            window.location = PreUrl;
                        }
                        else
                            window.location = "/";
                    })
                }
                else {
                    Swal.fire(data.message, "", "error");

                }

            })
            .catch(err => {
                console.log(err);
            })
        event.preventDefault();
    }
    function ReEmail() {
        var loading = document.getElementById('loading');
        loading.style.display = "block";
        var dt =
        {
            AcntNo: UserIDNo,
            CertType: "0"
        }
        API_ReSentEmailCert(dt)
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    Swal.fire("已送出驗證碼", "", "success");
                }
                else {
                    Swal.fire(data.message, "", "error");
                }
                loading.style.display = "none";
            })
            .catch(err => {
                loading.style.display = "none";
                console.log(err);
            })
    }
    const responseGoogle = (response) => {
        console.log(response);
    }

    const showpassword1 = () => {
        if (document.getElementById('password').type === "password") {
            document.getElementById('password').type = "text";
            document.getElementById('eye1').classList.add('active');
        } else {
            document.getElementById('password').type = "password";
            document.getElementById('eye1').classList.remove('active');
        }
    }


    const _handleInputPassword = (e) => {
        const value = e.target.value;
        console.log(value)
        // 加密
        const encryptPasswor = AES.encrypt(`${value}`, key).toString();
        setUserPwd(value);
        console.log(encryptPasswor);
        setEncryptUserPwd(encryptPasswor);

        
    }

    return (
        <>
            <Helmet>
                <title>臺中市政府文化局報名繳費系統-身分驗證</title>
            </Helmet>

            <section className="section-box">
                <div className="container">
                    <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky focusactive">:::</a></div>
                    <h1 className="text-center">身分驗證</h1>

                    {
                        LoginHidden ? null :
                            <div className="form-box" id="Login" >
                                <form onSubmit={submit} id="loginForm">
                                    <div className="form-container login-container">

                                        <div className="form-item">

                                            <div className="field">

                                                <label htmlFor="UserIDNo">身分證號碼</label>

                                            </div>

                                            <div className="value">

                                                <input type="text" className="focusactive" id="UserIDNo" value={UserIDNo} onChange={e => setUserIDNo(e.target.value)} title="請輸入身分證號碼或居住證號" placeholder="請輸入身分證號碼或居住證號" required />

                                            </div>


                                        </div>

                                        <div className="form-item show-notes">
                                            <div className="field">
                                                <label htmlFor="password">密碼</label>

                                            </div>

                                            <div className="value item-inline">
                                                <input className="focusactive" id="password" type="password" title="請輸入密碼" placeholder="請輸入密碼" value={UserPwd} onChange={(e) => _handleInputPassword(e)} required />
                                                <i className="eye" id="eye1" onClick={showpassword1}></i>
                                            </div>
                                        </div>

                                        <div className="form-item show-notes">
                                            <div className="field">
                                                <label htmlFor="password">驗證碼</label>
                                            </div>
                                            <div className="value item-inline">
                                                <input className="focusactive" id="Tpassword" type="Tpassword" title="請輸入下方驗證碼（不區分大小寫）" placeholder="請輸入下方驗證碼（不區分大小寫）" value={UserCaptchaCode} onChange={(e) => { setUserCaptchaCode(e.target.value) }} required />
                                            </div>
                                            <span className="notes">
                                                <ClientCaptcha captchaCode={(code) => setCaptchaCode(code)} chars="0123456789" />
                                            </span>
                                            <hr />
                                            <span className="notes">

                                            </span>
                                        </div>

                                        <div className="form-item btn-area mt-5">
                                            <button type="submit" value="submit" className="btn btn-primary btn-large mr-2 focusablack">登入</button>

                                        </div>

                                        <div className="form-item btn-area btn-no-style">
                                            <button type="button" value="join" onClick={() => { window.location = '/Register'; }} className="btn mr-2 focusactive">加入研習之友</button>
                                            <button type="button" value="forget" onClick={() => { window.location = '/ForgetPassword'; }} className="btn focusactive">忘記密碼</button>
                                        </div>

                                    </div>

                                </form>



                            </div>
                    }
                    {EmailHidden ? null :
                        <div className="form-box" id="EmailVerfy" >
                            <form onSubmit={Emailsubmit} id="EmailForm">
                                <div className="form-container login-container">
                                    <div className="form-item show-notes">

                                        <div className="field">
                                            <label htmlFor="password">信箱驗證碼</label>
                                        </div>
                                        <div className="value item-inline">
                                            <input className="focusactive" id="certCode" type="text" title="請輸入密碼" placeholder="請輸入密碼" value={CertCode} onChange={e => setCertCode(e.target.value)} required />
                                        </div>
                                        <span className="notes">
                                            <b>※</b> 請至註冊之信箱收取驗證碼，Email:{Email}。
                                        </span>
                                        <span className="notes">
                                            <a className="focusactive" onClick={ReEmail} style={{ fontSize: " 0.9rem", color: "rgb(205, 29, 29)", textDecoration: "underline", paddingLeft: "0.8rem", cursor: "pointer" }}>重新發送驗證信</a>
                                        </span>
                                    </div>

                                    <div className="form-item btn-area mt-5">
                                        <button type="submit" value="login" className="btn btn-primary btn-large mr-2 focusactive">驗證</button>

                                    </div>

                                </div>

                            </form>



                        </div>
                    }

                </div>
            </section>
        </>
    );
}
export default IdentityVerify;

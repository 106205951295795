import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import { Helmet } from 'react-helmet'; //title
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function QuestionnaireSurvey() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <Helmet>
              <title>臺中市政府文化局報名繳費系統-系統操作滿意度</title>
          </Helmet>
          <section class="section-box">
              <div class="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>

                  <h1 class="text-center">系統操作滿意度</h1>


                  <div class="form-box">
                      <div class="form-container">

                          <div class="max-w-lg mx-auto">

                              <div class="system-rating-list">
                                  <h3>系統介面是否清楚易懂並方便操作</h3>
                                  <div class="system-rating">
                                      <input type="radio" name="star" id="star1" value="1" />
                                      <label for="star1" class="fa fa-star"></label>
                                      <input type="radio" name="star" id="star2" value="2" />
                                      <label for="star2" class="fa fa-star"></label>
                                      <input type="radio" name="star" id="star3" value="3" />
                                      <label for="star3" class="fa fa-star"></label>
                                      <input type="radio" name="star" id="star4" value="4" />
                                      <label for="star4" class="fa fa-star"></label>
                                      <input type="radio" name="star" id="star5" value="5" />
                                      <label for="star5" class="fa fa-star"></label>
                                  </div>
                              </div>

                              <div class="system-rating-list">
                                  <h3>整體而言，您對線上報名系統的滿意度</h3>
                                  <div class="system-rating">
                                      <input type="radio" name="star1" id="star6" value="1" />
                                      <label for="star6" class="fa fa-star"></label>
                                      <input type="radio" name="star1" id="star7" value="2" />
                                      <label for="star7" class="fa fa-star"></label>
                                      <input type="radio" name="star1" id="star8" value="3" />
                                      <label for="star8" class="fa fa-star"></label>
                                      <input type="radio" name="star1" id="star9" value="4" />
                                      <label for="star9" class="fa fa-star"></label>
                                      <input type="radio" name="star1" id="star10" value="5" />
                                      <label for="star10" class="fa fa-star"></label>
                                  </div>
                              </div>


                              <hr />


                              <div class="form-item">
                                  <div class="field">線上報名其他建議</div>
                                  <div class="value">
                                      <input type="text" placeholder="請填寫您的建議" />
                                  </div>
                              </div>


                              <div class="form-item btn-area mt-5">
                                  <button type="button" onClick={() => { history.push('/ApplySuccessQus'); }} class="btn btn-primary btn-large">確定</button>

                              </div>

                          </div>

                      </div>
                      
                  </div>
                 


              </div>
          </section>
     </>
  );
}
export default QuestionnaireSurvey;

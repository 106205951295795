import "../css/style.css";
//import Loginform from "../components/Login/loginform";
import Swal from 'sweetalert2'
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_CourseDtail, API_CourseReg, API_UserInfoBK, API_ValidLibAccount, API_ValidLibData , key} from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js';
function CourseRegistration() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const [cookies, setCookie, removeCookie] = useCookies(['SystemSurvey']);
    const path = queryParams.get("path");
    const hide = { 'display': 'none' };
    const show = { 'display': 'block' };
    const [UserIDNo, setUserIDNo] = useState("");
    const [UserName, setUserName] = useState("");
    const [UserSex, setUserSex] = useState("");
    const [Birday, setBirday] = useState("1911-01-01");
    const [CityNo, setCityNo] = useState("");
    const [AreaNo, setAreaNo] = useState("");
    const [UserPhone, setUserPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [Contact, setContact] = useState("");
    const [ContactPhone, setContactPhone] = useState("");
    const [isForeig, setisForeig] = useState(false);
    const [CouDetail, setCouDetail] = useState({ SpecList: [] });
    const [AreaList, setAreaList] = useState([{}]);
    const DeptID = queryParams.get("DeptID").toString();
    const [RegPoing, setRegPoing] = useState(0);
    const [DiscPoint, setDiscPoint] = useState(0);
    const [DiscMoney, setDiscMoney] = useState(0);
    useEffect(() => {
        
        document.title = "臺中市政府文化局報名繳費系統--報名內頁";
        
      let  CouID = queryParams.get("CouID").toString();
        if (CouID == null || DeptID == null) {
            Swal.fire("尚未開始報名，或截止報名。", "", "error").then(ok => {            
                history.goBack();
            });
        }
       
      if (localStorage.getItem("UserData") != null) {
          let UserData = JSON.parse(localStorage.getItem("UserData"));
          let date = new Date(UserData.birDay);
          var year = date.toLocaleString("default", { year: "numeric" }).replace("年", ""); 
          var month = date.toLocaleString("default", { month: "2-digit" }).replace("月","");
          setUserIDNo(UserData.userIDNo);
          setUserName(UserData.userName);
          setUserSex(UserData.userSex);
          setCityNo(UserData.cityNo);
          setArea(UserData.cityNo);
          setAreaNo(UserData.areaNo);
          setBirday(year + '-' + month);
          setUserPhone(UserData.userPhone);
          setEmail(UserData.email);
          setContact(UserData.contact);
          setContactPhone(UserData.contactPhone);
          setisForeig(UserData.isForeig);
      }
      API_CourseDtail({ CouID: CouID })
          .then((response) => response.data)
          .then((data) => {
              if (data.code == "0000") {
                  checkAuthToken(data);
                  setCouDetail(data.result);
                  setRegPoing(data.result.RegPoint);
                  setDiscMoney(data.result.DiscMoney);
                  setDiscPoint(data.result.DiscPoint);
              }
              else if (data.code == "3028" || data.code == "4038") {
                  Swal.fire(data.message, "", "warning").then(ok => {                     
                      window.location = "/";
                  });
              }
              else {
                  Swal.fire(data.message, "", "warning");
                  //alert(data.message);
              }

          })
          .catch(err => {
              console.log(err);
          })

    }, []);
    const [IsGetInfo, setIsGetInfo] = useState(false);
    function aesDecryptBase64(Source, cryptoKey) {
        try {
            // 生成 SHA256 哈希作为密钥
            const key = CryptoJS.SHA256(cryptoKey);

            // 生成 MD5 哈希作为 IV
            const iv = CryptoJS.MD5(cryptoKey);

            // 解密数据
            const decrypted = CryptoJS.AES.decrypt(Source, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            // 将解密后的数据从 WordArray 转换为 UTF-8 字符串
            const decryptedStr = CryptoJS.enc.Utf8.stringify(decrypted);

            return decryptedStr;
        } catch (err) {
            console.error('Decryption failed:', err);
            return null;
        }
    }
    function ReSetData(){
        //setUserIDNo('');
        setUserName('');
        setUserSex('');
        setCityNo('');
        setArea('');
        setAreaNo('');
        setBirday('');
        setUserPhone('');
        setEmail('');
        setContact('');
        setContactPhone('');
        setisForeig('');
        setIsGetInfo(false);
    }
    function GetUserInfo() {
        //() => { history.push('/IdentityVerify'); }
        if (localStorage.getItem('UserData') == null) {
            localStorage.setItem("preUrl", location.pathname + location.search)
            window.location = '/IdentityVerify';
        }




        API_UserInfoBK({ UserIDNo: UserIDNo })
            .then(res => res.data)
            .then(data => {
                if (data.code == "0000") {
                    //Lee Jason解

                    var originalText = aesDecryptBase64(data.result, key);
                    var _data = JSON.parse(originalText);

                    //Lee


                    let date = new Date(_data.BirDay);
                    var year = date.toLocaleString("default", { year: "numeric" }).replace("年", "");
                    var month = date.toLocaleString("default", { month: "2-digit" }).replace("月", "");
                    setUserIDNo(_data.UserIDNo);
                    setUserName(_data.UserName);
                    setUserSex(_data.UserSex);
                    setCityNo(_data.CityNo);
                    setArea(_data.CityNo);
                    setAreaNo(_data.AreaNo);
                    setBirday(year + '-' + month);
                    setUserPhone(_data.UserPhone);
                    setEmail(_data.Email);
                    setContact(_data.Contact);
                    setContactPhone(_data.ContactPhone);
                    setisForeig(_data.IsForeig);
                    setIsGetInfo(true);
                }
                else {
                    Swal.fire(data.message, "", "warning");
                    setIsGetInfo(false);
                }
            })
    }
    function setArea(CityNo) {
        if (CityNo == "1") {
            setAreaList(
                [
                    { Value: 1, Text: "中區" },
                    { Value: 2, Text: "東區" },
                    { Value: 3, Text: "南區" },
                    { Value: 4, Text: "西區" },
                    { Value: 5, Text: "北區" },
                    { Value: 6, Text: "北屯區" },
                    { Value: 7, Text: "西屯區" },
                    { Value: 8, Text: "南屯區" },
                    { Value: 9, Text: "太平區" },
                    { Value: 10, Text: "大里區" },
                    { Value: 11, Text: "霧峰區" },
                    { Value: 12, Text: "烏日區" },
                    { Value: 13, Text: "豐原區" },
                    { Value: 14, Text: "后里區" },
                    { Value: 15, Text: "石岡區" },
                    { Value: 16, Text: "東勢區" },
                    { Value: 17, Text: "和平區" },
                    { Value: 18, Text: "新社區" },
                    { Value: 19, Text: "潭子區" },
                    { Value: 20, Text: "大雅區" },
                    { Value: 21, Text: "神岡區" },
                    { Value: 22, Text: "大肚區" },
                    { Value: 23, Text: "沙鹿區" },
                    { Value: 24, Text: "龍井區" },
                    { Value: 25, Text: "梧棲區" },
                    { Value: 26, Text: "清水區" },
                    { Value: 27, Text: "大甲區" },
                    { Value: 28, Text: "外埔區" },
                    { Value: 29, Text: "大安區" }
                ]
            );

        }
        else {
            setAreaList(
                [
                    { Value: 2, Text: "臺北市" },
                    { Value: 3, Text: "新北市" },
                    { Value: 4, Text: "桃園市" },
                    { Value: 5, Text: "臺南市" },
                    { Value: 6, Text: "高雄市" },
                    { Value: 7, Text: "基隆縣" },
                    { Value: 8, Text: "新竹市" },
                    { Value: 9, Text: "嘉義市" },
                    { Value: 10, Text: "新竹縣" },
                    { Value: 11, Text: "苗栗縣" },
                    { Value: 12, Text: "彰化縣" },
                    { Value: 13, Text: "南投縣" },
                    { Value: 14, Text: "雲林縣" },
                    { Value: 15, Text: "嘉義縣" },
                    { Value: 16, Text: "屏東縣" },
                    { Value: 17, Text: "宜蘭縣" },
                    { Value: 18, Text: "花蓮縣" },
                    { Value: 19, Text: "臺東縣" },
                    { Value: 20, Text: "澎湖縣" },
                    { Value: 21, Text: "金門縣" },
                    { Value: 22, Text: "連江縣" },
                    { Value: 1, Text: "其他" }
                ]
            );
        }
    }
    async function ChkLib() {

    }
    async function Reg() {
        event.preventDefault();
        var loading = document.getElementById('loading');
        var libData = null;
        var libres = false;
        var islibPont = false;
        if (DiscPoint > 0 && RegPoing == 0) {
            await Swal.fire(
                {
                    title: "是否使用圖書館點數扣點",
                    html:"",
                    focusConfirm: false,
                    showCancelButton: true,
                    confirmButtonText: "登入",
                    cancelButtonText: "略過"
                }).then(res => {
                    if (res.isConfirmed) {
                        islibPont = true;
                    }
                    else {
                        islibPont = false;
                    }
                }
            )
        }
        if (RegPoing > 0 || islibPont) {
            libData = localStorage.getItem("libData");
            if (libData != null) {
                await API_ValidLibData({ Data: libData })
                    .then(res => res.data)
                    .then(data => {
                        if (data.code == "0000") {
                            libData = data.result;
                            localStorage.setItem("libData", libData);
                            libres = true;
                        }
                        else {
                            libres = false;
                        }
                    })
            }
        }
        else {
            libres = true;
        }
        if (!libres) {
            await Swal.fire({
                title: '中市圖讀者登入',
                html:
                    '' +
                    '<div>' +
                    '<span>帳號</span>' +
                    '<input id="swal-input1" class="swal2-input" value=' + UserIDNo + ' disabled  required>' +
                    '</div>' +
                    '<div>' +
                    '<span>密碼</span>' +
                    '  <input id="swal-input2" class="swal2-input" required>' +
                    '</div>',
                focusConfirm: false,
                showCancelButton: true,
                confirmButtonText: "登入",
                cancelButtonText: "略過",
                preConfirm: async () => {
                    Swal.resetValidationMessage();
                    if (document.getElementById('swal-input2').value == '') {
                        Swal.showValidationMessage('請輸入密碼');

                        return false;
                    }

                }

            }).then(async ok => {
                if (ok.isConfirmed) {
                    loading.style.display = "block";
                    await API_ValidLibAccount({ UserName: document.getElementById('swal-input1').value, Password: document.getElementById('swal-input2').value })
                        .then(res => res.data)
                        .then(async data => {
                            if (data.code == "0000") {
                                libData = data.result;
                                localStorage.setItem("libData", libData);
                                libres = true;
                            }
                            else {
                                loading.style.display = "none";
                                await Swal.fire(data.message, "失敗", "error");
                                libres = false;
                            }
                        })
                }
                else if (ok.isDismissed) {
                    await Swal.fire({
                        title: "臺中市圖書館帳號尚未登入，無法使用點數扣抵",
                        icon: "info"
                    }).then(ok => {
                        libres = false;
                    });
                }
            })
        }        
        if (libres) {
            loading.style.display = "block";
            console.log(libData);
            let specInput = document.getElementsByName("spec");
            let CouID = queryParams.get("CouID").toString();
            var specstr = "";
            for (let i = 0; i < specInput.length; i++) {
                if (specInput[i].checked)
                    specstr += specInput[i].value + ",";
            }
            if (specstr.length > 0) {
                specstr = specstr.substring(0, specstr.length - 1);
            }
            var dt =
            {
                UserIDNo: UserIDNo,
                UserName: UserName,
                UserPhone: UserPhone,
                UserSex: UserSex,
                CityNo: CityNo,
                AreaNo: AreaNo,
                BirDay: Birday + "-" + "01",
                Email: Email,
                Contact: Contact,
                ContactPhone: ContactPhone,
                Spec: specstr,
                IsForeig: isForeig,
                CouID: CouID,
                Data: libData
            }
            API_CourseReg(dt)
                .then((response) => response.data)
                .then((data) => {
                    loading.style.display = "none";
                    checkAuthToken(data);
                    if (data.code == "0000") {
                        console.log(data.message);
                        //alert("報名成功!");
                        Swal.fire(
                            data.message , "報名成功", "success"
                        ).then((res) => {
                            if (res.isConfirmed) {
                                window.location = "/SystemSurvey?URegID=" + data.result + "&DeptID=" + DeptID;                                
                            }
                        })
                    }
                    else {
                        Swal.fire(data.message, "", "warning");
                        // alert(data.message);
                    }

                })
                .catch(err => {
                    console.log(err);
                })
        }
        else {
            //Swal.fire("登入失敗", "", "warning");
            loading.style.display = "none";
        }
    }
  return (
      <>
          <section class="section-box">
              <div class="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky focusactive">:::</a></div>
                  <h1 class="text-center">我要報名</h1>


                  {/*<!-- 報名表單 -->*/}
                  <div class="form-box">

                      <form onSubmit={Reg}>

                          <div class="form-container">


                              <div class="form-item show-notes">
                                  <div class="field"><label htmlFor="UserId">身分證號碼</label></div>
                                  <div class="value item-inline">
                                      <input type="text" id="UserId" className="focusactive" placeholder="請填寫身分證號碼" value={UserIDNo} required onChange={(e) => { setUserIDNo(e.target.value) }} disabled={localStorage.getItem("UserData") != null || IsGetInfo ? "disabled" : ""} />
                                      {
                                          localStorage.getItem("UserData") != null ? "" :
                                              IsGetInfo ? 
                                                  <button type="button" onClick={ReSetData} class="btn btn-primary ml-2 focusablack" >我是研習之友</button>
                                                  :
                                                  <button type="button" onClick={GetUserInfo} class="btn btn-primary ml-2 focusablack" >我是研習之友</button>
                                      }
                                  </div>
                                  <div class="value">
                                      <input type="checkbox" className="focusactive" id="foreign" checked={isForeig} onClick={() => setisForeig(!isForeig)} disabled={localStorage.getItem("UserData") != null || IsGetInfo ? "disabled" : ""}/>
                                      <label htmlFor="foreign">外籍人士（請填寫居住證號或護照號碼）</label>
                                  </div>
                                  <span class="notes">
                                      <b>※</b> 如果您是研習之友學員，請點選「<b>我是研習之友</b>」並登入，系統將會自動帶入您的基本資料。
                                  </span>
                              </div>
                              <h4 class="" style={{color:"red"}}>請注意：使用他人身分證號或偽造身分證號報名，屬刑法偽造文書罪，請勿以身試法。</h4>

                              <h2 class="text-line my-5">填寫基本資料</h2>


                              <div class="form-item">
                                  <div class="field"><label htmlFor="UName">姓名</label></div>
                                  <div class="value">
                                      <input type="text" id="UName" className="focusactive" placeholder="請填寫真實中文姓名" value={UserName} disabled={localStorage.getItem("UserData") != null || IsGetInfo ? "disabled" : ""} onChange={(e) => { setUserName(e.target.value) }} required />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field"><label htmlFor="Ubirday">出生年月(西元)</label></div>
                                  <div class="value">
                                      <input type="month" id="Ubirday" className="focusactive" style={{ fontSize: "1.2em" }} value={Birday} disabled={localStorage.getItem("UserData") != null || IsGetInfo ? "disabled" : ""} onChange={(e) => { setBirday(e.target.value) }} required />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">性別</div>
                                  <div class="value">
                                      <div>
                                          <input type="radio" className="focusactive"  id="male" name="radio" value="male" onChange={() => { setUserSex("M") }} checked={UserSex == "M" ? "Checked" : ""} required/>
                                          <label htmlFor="male">男生</label>
                                      </div>
                                      <div>
                                          <input type="radio" className="focusactive" id="female" name="radio" value="female" onChange={() => { setUserSex("F") }} checked={UserSex == "F" ? "Checked" : ""} required/>
                                          <label for="female">女生</label>
                                      </div>
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field"><label htmlFor="cityNo">居住地區</label></div>
                                  <div class="value item-inline">
                                      <select name="" className="focusactive" id="cityNo" value={CityNo} onChange={(e) => { setCityNo(e.target.value), setArea(e.target.value) }} required>
                                          <option value="" disabled> 請選擇</option>
                                          <option value="1">臺中市</option>
                                          <option value="2">其他</option>
                                      </select>

                                      <select name="" className="focusactive" id="AreaNo" value={AreaNo} onChange={(e) => { setAreaNo(e.target.value) }} required>
                                          <option value="" disabled> 請選擇</option>
                                          {
                                              AreaList.map((x) => (
                                                  <option value={x.Value}>{x.Text}</option>
                                              ))
                                          }
                                      </select>
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field"><label htmlFor="userPhone">手機號碼</label></div>
                                  <div class="value">
                                      <input type="tel" className="focusactive"  id="userPhone" placeholder="請輸入手機號碼" value={UserPhone} onChange={(e) => { setUserPhone(e.target.value) }} required/>
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field"><label htmlFor="Email">電子信箱</label></div>
                                  <div class="value">
                                      <input type="email" className="focusactive"  id="Email" placeholder="請輸入電子信箱" value={Email} onChange={(e) => { setEmail(e.target.value) }} required/>
                                  </div>
                              </div>


                              <hr />


                              <div class="form-item">
                                  <div class="field"><label htmlFor="Contact">緊急聯絡人姓名</label></div>
                                  <div class="value">
                                      <input type="text" className="focusactive"  id="Contact" placeholder="請填寫緊急聯絡人姓名" value={Contact} onChange={(e) => { setContact(e.target.value) }} required/>
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field"><label htmlFor="ContactPhone">緊急聯絡人電話</label></div>
                                  <div class="value">
                                      <input type="tel" className="focusactive"  id="ContactPhone" placeholder="請輸入緊急聯絡人手機號碼" value={ContactPhone} onChange={(e) => { setContactPhone(e.target.value) }} required/>
                                  </div>
                              </div>


                              <hr />


                              <div class="form-item">
                                  <div class="field">優惠身分</div>
                                  <div class="value">
                                      {
                                          CouDetail.SpecList.map((x) => (
                                              <div>
                                                  <input type="checkbox" id={"spec-" + x.SpecDiscID} name="spec" value={x.SpecDiscID} />
                                                  <label for={"spec-" + x.SpecDiscID}>{ x.SpecName}</label>
                                              </div>
                                              ))
                                      }
                                    
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">學習時數申請</div>
                                  <div class="value">
                                      <div>
                                          <input type="checkbox" className="focusactive" id="hours" />
                                          <label for="hours">公務人員學習時數、教師研習時數</label>
                                      </div>
                                  </div>
                              </div>

                              {/*<!-- 課程資訊 -->*/}
                              {
                                  <div class="course-info">
                                      <h3>{CouDetail.DeptName} ｜{CouDetail.CouSName}｜班別序號：{CouDetail.CouNo}</h3>
                                      課程：<b>{CouDetail.CouName}</b><br />
                                      上課日期：{CouDetail.CouStartDate} ~ {CouDetail.CouCloseDate}<br />
                                      上課時段：（{CouDetail.CouWeeks}）{CouDetail.CouStartTime} - {CouDetail.CouCloseTime}
                                      {
                                          RegPoing > 0 ? <div>報名最低點數限制：<b>{RegPoing}</b> 點</div> : null
                                      }
                                      {
                                          DiscPoint > 0 ? <div>報名點數 <b>1</b> 點折抵 <b>{DiscMoney}</b> 元，可折抵 <b>{DiscPoint}</b> 點</div> : null
                                      }
                                  </div>
                              }


                              <div class="form-item btn-area">
                                  <button type="button" class="btn-line btn-primary mr-2 focusablack" onClick={() => { history.goBack(); }}>放棄報名</button>
                                  <button type="submit" class="btn btn-primary focusablack">確定報名</button>
                              </div>

                          </div>


                      </form>
                     

                  </div>
                 


              </div>
          </section>
     </>
  );
}
export default CourseRegistration;

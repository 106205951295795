

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { API_DeptList } from '../../global/constants';
function Header() {
    var history = useHistory();
    const [loginStr, setloginStr] = useState('');
    const [islogin, setlogin] = useState(false);
    const [DeptArray, setDeptArray] = useState([{}]);
    useEffect(() => {
        if (localStorage.getItem("UserData") == null ) {
            setloginStr('登入');
            setlogin(false);
        }
        else {
            setloginStr(JSON.parse(localStorage.getItem("UserData")).name + '，你好!　登出');
            setlogin(true);
        }
        API_DeptList()
            .then((response) => response.data)
            .then((data) => {
                
                if (data.code == "0000") {
                    setDeptArray(data.result);
                    console.log(data);
                    //console.log(data.result.carouselList);
                }
                else {
                    
                    Swal.fire(data.message, "", "error");
                    //alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
        }, []);
    document.onkeydown = function (event) {
        var target, code, tag;
            if (!event) {
            event = window.event;
            code = event.keyCode;
            if (code == 13) {
                submit();
             }
        } else {
            target = event.target; //針對遵循w3c標準的瀏覽器
            code = event.keyCode;
                if (code == 13) {
                    submit();
            }
        }
    };
    
    return (
        <header>
           
            <nav id="navigation" class="navigation navigation-justified sticky-top">
              

                <div class="navigation-header">
                    <div class="navigation-logo">
                        {/* logo */}
                        <a href="/">  <img src="images/logo.png" alt="臺中市政府文化局報名繳費系統" />  </a>
                        {/*end logo */}
                    </div>

                    <span>
                        <div class="navigation-button-toggler">
                            <i class="hamburger-icon"></i>
                        </div>
                    </span>
                    </div>

                <div class="navigation-body">
                    <div class="navigation-body-header">
                        <div><a href="#C" title="跳到主要內容區塊" tabIndex="1" className="focusable">跳到主要內容區塊</a></div>
                        <div class="navigation-logo">
                            
                            <a href="/">  <img src="images/logo.png" alt="臺中市政府文化局報名繳費系統" />  </a>

                        </div>
                        <span class="navigation-body-close-button">&#10005;</span>
                    </div>

                    <a accesskey="U" href="#U" id="AU" name="U" title="上方功能導覽區" class="a-acceky">:::</a>
                    <ul class="navigation-menu">
                       
                        <li class="navigation-item">                          
                            <a class="navigation-link" href="/">首頁</a>
                            {/*<ul class="navigation-dropdown">*/}
                            {/*    */}{/*<li class="navigation-dropdown-item">*/}
                            {/*    */}{/*    <a class="navigation-dropdown-link" href="/MerryChristmas">聖誕節主題</a>*/}
                            {/*    */}{/*</li>*/}
                            {/*    */}{/*<li class="navigation-dropdown-item">*/}
                            {/*    */}{/*    <a class="navigation-dropdown-link" href="/HappyNewYear">節慶主題</a>*/}
                            {/*    */}{/*</li>*/}
                            {/*</ul>*/}
                        </li>   

                        <li class="navigation-item">
                            <a class="navigation-link " href="#" title="課程" alt="課程">課程</a>
                            <ul class="navigation-dropdown">
                                {
                                    DeptArray.map((x) => (
                                         <li class="navigation-dropdown-item">
                                            <a class="navigation-dropdown-link" href={"/CourseListTable?DeptID=" + x.deptID} alt={x.deptName+"(另開新視窗)"}>{x.deptName}</a>
                                        </li>
                                        
                                    ))
                                }
                               
                            </ul>
                        </li>

                        
                        <li class="navigation-item">
                            <a class="navigation-link" href="/Announcement">最新消息</a>
                        </li>

                        <li class="navigation-item">
                            <a class="navigation-link" href="/FAQ">常見問題</a>
                        </li>

                        <li class="navigation-item">
                            <a class="navigation-link" href="/StudentSearch">繳費/退費</a>
                        </li>
                        <li class="navigation-item">
                            <a class="navigation-link" href="/StudentSearchCosure">研習之友專區</a>
                        </li>  
                        <li class="navigation-item">
                            <a class="navigation-link" href="/FAQ#tab5">尋求幫助</a>
                        </li>
                    </ul>

                    <ul class="navigation-menu">
                        <li class="navigation-item">
                            <a class="navigation-link" href="#" onClick={e=>islogin ? (localStorage.clear(), window.location = '/') : window.location = '/IdentityVerify' }>{loginStr}</a>
                        </li>
                        <li>
                            <div class="navigation-body-section">                               
                                <form class="navigation-inline-form">
                                    <input class="navigation-input" type="search" name="search" title="課程關鍵字搜尋" placeholder="課程關鍵字搜尋" />
                                    <button type="submit" class="navigation-btn" aria-label="搜尋" value="search">
                                        <i class="navigation-search-icon"></i>
                                    </button>
                                </form>
                            </div>
                        </li>

                        <li class="navigation-item navigation-icon-item">
                            <a class="navigation-link" href="/Sitemap" id="AS">
                                <i class="im im-icon-Bulleted-List"  ></i>網站導覽
                            </a>
                        </li>

                    </ul>

                </div>
            </nav>
        </header>
    );
}
export default Header;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function ApplySuccessQus() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <section class="section-box">
              <div class="container">


                  <h1 class="text-center">填寫完成</h1>


                  <div class="form-box">
                      <div class="form-container">

                          <div class="text-center">

                              <img src="images/apply-success.svg" />

                              <p class="my-4 mb-4 mb-md-5">感謝您的填寫。</p>

                              <button type="button" class="btn btn-primary btn-large" >確定</button>

                          </div>

                      </div>
                     
                  </div>
                 


              </div>
          </section>
     </>
  );
}
export default ApplySuccessQus;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件

function CourseRegistrationEnter() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

  useEffect(() => {
      
  },[]);

  return (
      <>
          <section class="section-box">
              <div class="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1 class="text-center">我要報名</h1>


                  {/*<!-- 報名表單 -->*/}
                  <div class="form-box">

                      <form action="#">

                          <div class="form-container">


                              <div class="form-item show-notes">
                                  <div class="field">身分證號碼</div>
                                  <div class="value item-inline">
                                      <input type="text" placeholder="請填寫身分證號碼" value="A123456789" />
                                      <button type="button" onclick="window.location.href='course-login.html';" class="btn btn-primary ml-2">我是學員</button>
                                  </div>
                                  <div class="value">
                                      <input type="checkbox" id="foreign" checked />
                                      <label for="foreign">外籍人士（請填寫居住證號）</label>
                                  </div>
                                  <span class="notes">
                                      <b>※</b> 如果您是研習之友學員，請點選「<b>我是學員</b>」並登入，系統將會自動帶入您的基本資料。
                                  </span>
                              </div>


                              <h2 class="text-line my-5">填寫基本資料</h2>


                              <div class="form-item">
                                  <div class="field">姓名</div>
                                  <div class="value">
                                      <input type="text" placeholder="請填寫真實中文姓名" value="陳曉明" />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">出生年月(西元)</div>
                                  <div class="value">
                                      <input type="date" value="1998-12-25" />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">性別</div>
                                  <div class="value">
                                      <div>
                                          <input type="radio" id="male" name="radio" value="male" checked />
                                          <label for="male">男生</label>
                                      </div>
                                      <div>
                                          <input type="radio" id="female" name="radio" value="female" />
                                          <label for="female">女生</label>
                                      </div>
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">居住地區</div>
                                  <div class="value item-inline">
                                      <select name="" id="">
                                          <option value="1" selected>臺中市</option>
                                          <option value="2">臺北市</option>
                                          <option value="3">新北市</option>
                                          <option value="4">臺南市</option>
                                          <option value="5">高雄市</option>
                                      </select>

                                      <select name="" id="">
                                          <option value="1" selected>西屯區</option>
                                          <option value="2">中區</option>
                                          <option value="3">東區</option>
                                          <option value="4">南區</option>
                                          <option value="5">北區</option>
                                          <option value="6">南屯區</option>
                                          <option value="7">北屯區</option>
                                          <option value="8">豐原區</option>
                                          <option value="9">大里區</option>
                                          <option value="10">太平區</option>
                                          <option value="11">清水區</option>
                                          <option value="12">沙鹿區</option>
                                          <option value="13">大甲區</option>
                                          <option value="14">東勢區</option>
                                          <option value="15">梧棲區</option>
                                          <option value="16">烏日區</option>
                                      </select>
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">手機號碼</div>
                                  <div class="value">
                                      <input type="tel" placeholder="請輸入手機號碼" value="0912123456" />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">電子信箱</div>
                                  <div class="value">
                                      <input type="email" placeholder="請輸入電子信箱" value="aaa@gmail.com" />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">緊急聯絡人姓名</div>
                                  <div class="value">
                                      <input type="text" placeholder="請填寫緊急聯絡人姓名" value="甄美麗" />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">緊急聯絡人手機號</div>
                                  <div class="value">
                                      <input type="tel" placeholder="請輸入緊急聯絡人手機號碼" value="0922122822" />
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">優惠身分</div>
                                  <div class="value">
                                      <div>
                                          <input type="checkbox" id="checkbox1" />
                                          <label for="checkbox1">身心障礙人士</label>
                                      </div>
                                      <div>
                                          <input type="checkbox" id="checkbox2" />
                                          <label for="checkbox2">中 / 低收入戶</label>
                                      </div>
                                      <div>
                                          <input type="checkbox" id="checkbox3" checked />
                                          <label for="checkbox3">原住民</label>
                                      </div>
                                      <div>
                                          <input type="checkbox" id="checkbox4" />
                                          <label for="checkbox4">新住民</label>
                                      </div>
                                  </div>
                              </div>

                              <div class="form-item">
                                  <div class="field">學習時數申請</div>
                                  <div class="value">
                                      <div>
                                          <input type="checkbox" id="hours" checked />
                                          <label for="hours">公務人員學習時數、教師研習時數</label>
                                      </div>
                                  </div>
                              </div>

                              {/*<!-- 課程資訊 -->*/}
                              <div class="course-info">
                                  <h3>大墩文化中心｜111年秋季課程｜班別序號：AA001</h3>
                                  課程：<b>羊毛氈創作班</b><br />
                                  上課日期：111/09/24 ~ 111/12/17<br />
                                  上課時段：（星期二）09:30 - 16:30
                              </div>


                              <div class="form-item btn-area">
                                  <button type="button" class="btn-line btn-primary mr-2">取消報名</button>
                                  <button type="button" onClick={() => { history.push('/ApplySuccess'); }} class="btn btn-primary">確定報名</button>
                              </div>

                          </div>


                      </form>
                      

                  </div>
                  


              </div>
          </section>
     </>
  );
}
export default CourseRegistrationEnter;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_Carousel, API_DeptList } from '../global/constants';
import { checkAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";



function home() {

    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [CarouselArray, setCarouselArray] = useState([{ caroImage: "", caroCaption: "", caroUrl :""}]);
    const [DeptArray, setDeptArray] = useState([{}]);
    const [autoCarousel, setAutoCarousel] = useState(true);
    var intervalID = 0;
    useEffect(() => {
        API_Carousel()
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    if (data.result.carouselList.length > 0) {
                        setCarouselArray(data.result.carouselList);
                    }
                    console.log(data.result.carouselList);
                }
                else {
                    Swal.fire(data.message, "", "warning");
                    //alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
        API_DeptList()
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    setDeptArray(data.result);
                   
                }
                else {
                    Swal.fire(data.message, "", "warning");
                    //alert(data.message);
                }
            })
            .catch(err => {
                console.log(err);
            })
        //setCarousel(false);

    }, []);
    
    //const timeout = setTimeout(() => {
    //    if (autoCarousel) {
    //        prev();
    //    }
    //}, 5000)

    const [currentId, setCurrentId] = useState(0);
    const next = () => {
        setCurrentId((currentId) => (CarouselArray.length - 1 > currentId ? currentId + 1 : 0))
    }

    const prev = () => {
        setCurrentId((currentId) => (currentId > 0 ? currentId - 1 : CarouselArray.length - 1))
    }

    return (
        <>
            <section>
                <div className="index-banner">
                    <div class="index-banner-style">
                        <img src="images/index/banner.png" alt="" />
                    </div>
                    <div class="index-banner-ad">
                        <div class="index-banner-box"> 
                            <div>
                                <a href={CarouselArray[currentId].caroUrl}><img style={{ width: "100%", maxHeight:"25em" }} src={CarouselArray[currentId].caroImage} alt={CarouselArray[currentId].caroCaption} /></a>
                            </div>
                            <button className="slider-prev" onClick={() => { prev(), setAutoCarousel(false) }} alt="上一頁">
                                { "<"}
                            </button>
                            <button className="slider-next" onClick={() => { next(), setAutoCarousel(false) }} alt="下一頁">
                                {">"}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-box">
                <div className="">
                    <h1 style={{ color: "transparent" }}>臺中市政府文化局報名繳費系統</h1>
                    <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky focusactive" >:::</a></div>
                    {/*      <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a>*/}
                    <div className="cultural-logo">
                      
                        {
                            DeptArray.map((x) => (
                                <a href={"/CourseListTable?DeptID=" + x.deptID} className="focusactive"><img src={"images/" + x.deptID + ".png"} alt=""  />{x.deptName}</a>
                            ))
                        }
                      
                    </div>
                </div>
            </section>
        </>
    );
}
export default home;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API_ChangePwd } from "../global/constants"
import { checkAuthToken, getAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
import { Helmet } from 'react-helmet'; //title
function ChangePassword() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const [AcntNo, setAcntNo] = useState('');
    const [Pwd, setPwd] = useState('');
    const [rePwd, setrePwd] = useState('');
    const [Chk, setChk] = useState({ PwdChk: false, rePwdChk: false, diffPwdChk: false, RegChk: false });
    useEffect(() => {
        var userJson = localStorage.getItem("UserID");
        if (userJson != null) {
            //var user = JSON.parse(userJson);
            //setAcntNo(user.acntNo);
            var userID = JSON.parse(userJson);
            setAcntNo(userID);
        }
        document.title = "臺中市政府文化局報名繳費系統--重設密碼";
        
    }, []);
    function setValue(e, type) {
        if (type == "pwd") {
            setPwd(e.target.value);
            if (e.target.value != '') {
                setChk(prev => { prev.PwdChk = false; return prev; });
                if (e.target.value == rePwd) { setChk(prev => { prev.diffPwdChk = false; return prev; }); }
                else { setChk(prev => { prev.diffPwdChk = true; return prev; }); }
            }
            else { setChk(prev => { prev.PwdChk = true; return prev; }) }
        }
        if (type == "repwd") {
            setrePwd(e.target.value);
            if (e.target.value != '') {
                setChk(prev => { prev.rePwdChk = false; return prev; });
                if (e.target.value == Pwd) { setChk(prev => { prev.diffPwdChk = false; return prev; }); }
                else { setChk(prev => { prev.diffPwdChk = true; return prev; }); }
            }
            else { setChk(prev => { prev.rePwdChk = true; return prev; }) }
        }
    }
    function ChangePwd() {
        var isOK = true;
        console.log(Chk);
        if (Pwd == '') {
            setChk(prev => { prev.PwdChk = true; return prev; });
            isOK = false;
            Swal.fire("請輸入密碼", "錯誤", "error");
            return;
        }
        if (rePwd == '') {
            setChk(prev => { prev.rePwdChk = true; return prev; });
            isOK = false;
            Swal.fire("請輸入確認密碼", "錯誤", "error");
            return;
        }
        if (Pwd != rePwd) {
            setChk(prev => { prev.diffPwdChk = true; return prev; });
            isOK = false;
            Swal.fire("兩次密碼不相符", "驗證錯誤", "error");
            return;
        }
        var reg = new RegExp(/^(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{12,}$/);
        if (!reg.test(Pwd)) {
            isOK = false;
            Swal.fire("密碼至少12碼，須包含大寫英文、小寫英文、數字、特殊符號。", "驗證錯誤", "error");
            return;
        }
        console.log(Chk);
        if (isOK) {
            var loading = document.getElementById('loading');
            loading.style.display = "block";
            API_ChangePwd({ AcntNo: AcntNo, NewPassword: Pwd, RePassword : rePwd })
                .then(res => res.data)
                .then(data => {
                    loading.style.display = "none";
                    if (data.code == "0000") {
                        Swal.fire("更改成功，請用新密碼登入", "成功", "success")
                            .then(ok => {
                                localStorage.clear();
                                window.location = "/IdentityVerify";
                            });

                    }
                    else {
                        Swal.fire(data.message, "錯誤", "error");
                    }
                })
        }
    }
  return (
      <>
          <Helmet>
              <title>臺中市政府文化局報名繳費系統-重設密碼</title>
          </Helmet>

          <section className="section-box">
              <div className="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1 className="text-center">修改密碼</h1>
                  <div className="form-box">
                      <div className="form-container login-container">
                          <div className="form-item">
                              <div className="field">您的帳號：</div>
                              <div className="value">
                                  <b className="ml-md-2">{AcntNo}</b>
                              </div>
                          </div>
                          <div className={`form-item show-notes  ${Chk.PwdChk ? 'show-help' : ''}`}>
                              <div className="field">請輸入新密碼</div>
                              <div className="value">
                                  <input type="password" onChange={(e) => setValue(e, "pwd")} placeholder="請輸入新密碼" />
                              </div>
                              <span className="notes" >
                                  <b>※</b> 密碼至少12碼，須包含大寫英文、小寫英文、數字、特殊符號。
                              </span>
                              <span className="help-word">請輸入新密碼</span>
                          </div>
                          <div className={`form-item ${Chk.rePwdChk ? 'show-help' : ''}`}>
                              <div className="field">再次確認密碼</div>
                              <div className="value">
                                  <input type="password"
                                      onChange={(e) => setValue(e, "repwd")}
                                      placeholder="再次確認密碼" />
                              </div>
                              <span className="help-word">再次確認密碼</span>
                          </div>
                          <div className={`form-item ${Chk.diffPwdChk ? 'show-help' : ''}`}>
                              <span className="help-word">兩次密碼不相符</span>
                          </div>
                          <div className="form-item btn-area mt-5">
                              <button type="button" onClick={ChangePwd} className="btn btn-primary btn-large mr-2">確認送出</button>
                          </div>
                      </div>
                  </div>

              </div>
          </section>
     </>
  );
}
export default ChangePassword;

import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { checkAuthToken } from "../global/TokenUtil";
import {
    API_CenterList,             //中心列表
    API_AnnouncementList,       //公告類別
    API_MessageRecList,         //查詢列表
    API_MessageRecContant       //公告內容
} from '../global/constants';
import Swal from "sweetalert2";


function Announcement() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };

    const [CenterList, setCenterList] = useState([{}]);
    const [AnnouncementList, setAnnouncementList] = useState([{}]);

    //最新公告
    const [NewsList, setNewsList] = useState([{}]);

    const [DeptID, setDeptID] = useState();
    
    const [MsgTypeID, setMsgTypeID] = useState();

    //控制lab
    const [LDeptID, setLDeptID] = useState();
     
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統-最新消息";
       // var s = document.getElementById("string");
        //s.innerHTML = "<p style='color:#553125;font-size:20px'>123</p>";

      //取得中心列表
      API_CenterList()
          .then((response) => response.data)
          .then((data) => {
              checkAuthToken(data);
              if (data.code == "0000") {
                  setCenterList(data.result);

                  if (data.result.length > 0) {
                      
                      setDeptID(data.result[0].deptID)
                      
                  }
                  console.log(data.result);
              }
              else {
                  Swal.fire(data.message, "", "error");
                  //alert(data.message);
              }
          })
          .catch(err => {
              //console.log(err);
          })

        //取得公告類別
        API_AnnouncementList()
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    setAnnouncementList(data.result);
                    if (data.result.length > 0) {

                        setMsgTypeID(data.result[0].codeValue)
                    }
                    console.log(data.result);
                    
                }
                else {
                    Swal.fire(data.message, "", "error");
                    //alert(data.message);
                }
            })
            .catch(err => {
                //console.log(err);
            })
       
    }, []);

    useEffect(() => {
        getData();
        setActiveTab(window.location.hash);
    }, [DeptID, MsgTypeID])

    function setActiveTab(deptID, deptName) {
        setDeptID(deptID);
        document.title = "臺中市政府文化局報名繳費系統-最新消息(" + deptName + ")";
    }

    function getData() {
        API_MessageRecList({ DeptID: DeptID, MsgType: MsgTypeID })
            .then((response) => response.data)
            .then((data) => {
                if (data.code == "0000") {
                    console.log(data);
                    setNewsList(data.result);
                    /* setlistShow(true);*/
                   
                }
                else {
                    console.log(data.message);
                    setNewsList([]);
                }
            })
            .catch(err => {
                console.log(err);
            })

    }

  return (
      <>
          <section className="section-box">
              <div className="container">
             {/*  //   <div id="string">  </div>*/}

                  {/* 導盲磚 */}
                  <div>
                      <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" className="a-acceky focusactive">:::</a>
                  </div>


                  <h1 className="text-center">最新消息</h1>

                 {/* href="javascript:void(0)"*/}
                  {/* 各中心分類 */}
                  <div className="news-category">

                      <ul className="news-category-list">
                          {
                              CenterList.map((x) => (
                                  <li >
                                      <a className={`#${x.deptID}`  == window.location.hash ? "active focusactive" : "focusactive"}
                                       
                                          href={`#${x.deptID}`}
                                         
                                          title={`${x.deptName}最新消息`}
                                          value={x.deptID}
                                          onClick={(e) => { setActiveTab( x.deptID, x.deptName) }} >

                                          <img src={`images/${x.deptID}.png`}
                                              alt={x.deptName} />{x.deptName}
                                      </a>
                                  </li>
                              ))
                          }
                      </ul>

                  </div>
                  {/* end 各中心分類 */}


                  {/* 公告篩選 */}
                  <div class="news-filter focusactive">
                      {
                          AnnouncementList.map((x, i) => (
                              <div>
                                  <input type="radio"
                                      id={`news${i}`}
                                      name="radio"
                                      value={x.codeValue}
                                      onClick={(e) => { setMsgTypeID(e.target.value); }}
                                      checked={x.codeValue == MsgTypeID ? true : false}
                                      className="focusablack" />

                                  <label for={`news${i}`}>{x.codeText} </label>
                              </div>
                          ))
                      }
                  </div>


                  {/* 公告列表 */}
                  <div className="news-box">

                      <div className="news-list-area">

                          {/* 最新公告範例 */}
                          {
                              NewsList.map((x) => (
                                  <a className="news-list focusactive" href={"/News?MsgID="+x.MsgID}  title="最新公告範例" >
                                      <div className="news-date">
                                          {x.MsgPushDate }
                                      </div>
                                      <div className="news-title">
                                          {x.MsgSub}
                                      </div>
                                      <div className="news-more">
                                          more <i className="sl sl-icon-arrow-right"></i>
                                      </div>
                                  </a>
                              ))
                          }


                      </div>

                      {/* 頁數 */}
                      <div className="pagination">
                          <ul>
                              <li><a href="javascript:;" title="最前頁" className="focusactive">«</a></li>
                              <li className="active"><a href="javascript:;" title="第一頁" className="focusactive">1</a></li>
                              <li><a href="javascript:;" title="最末頁" className="focusactive">»</a></li>
                          </ul>
                      </div>

                  </div>
                  {/* end 公告列表 */}


              </div>
          </section>
      </> 
  );
}
export default Announcement;

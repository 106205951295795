import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { API_URegDetail, API_UserRefund } from "../global/constants"
import { checkAuthToken, getAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
function Applyrefundconfirm() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const URegID = queryParams.get("URegID");
    const [URegDetail, setURegDetail] = useState({});
    const [URFCaption, setURFCaption] = useState("");
    const [userIDNo, setUserIDNo] = useState("");
    const [userName, setUserName] = useState("");
    const [RefundFile, setRefundFile] = useState();
    const LimitSize = 5242880;
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--退費申請確認頁";
        if (localStorage.getItem("UserData") == null) {
            localStorage.setItem("preUrl", document.baseURI);
            history.push("/IdentityVerify");
            return;
        }
        
        if (getAuthToken() == "xxx") {
            //alert();
            Swal.fire("憑證過期，請重新登入。", "", "error").then(ok => {
                localStorage.setItem("preUrl", document.baseURI);
                history.push("/IdentityVerify");
            });            
            return;
        }
        setUserIDNo(JSON.parse(localStorage.getItem("UserData")).userIDNo);
        setUserName(JSON.parse(localStorage.getItem("UserData")).userName);
        if (URegID == null || URegID == "") {
            history.goBack();
        }
        var dt = {
            UserID: JSON.parse(localStorage.getItem("UserData")).userID,
            URegID: URegID
        }
        API_URegDetail(dt)
          .then((response) => response.data)
            .then((data) => {
              checkAuthToken(data);
              if (data.code == "0000") {
                  if (data.result != null) {
                      setURegDetail(data.result);
                  }
                  else {
                      window.location = "/StudentSearch";
                  }
              }
              else {
                  Swal.fire(data.message, "", "error").then
                      ((res) => {
                          window.history.back();
                      });
                  //console.log(data.message);
                  //window.location = "/";
              }
          })
          .catch(err => {
              console.log(err);
          })
    }, []);
    function FileUpload(event) {
        if (event.target.files.length > 0) {
            console.log(event.target.files);
            if (event.target.files[0].size > LimitSize) {
                Swal.fire("超過檔案大小(" + LimitSize / 1024 / 1024 + "MB)", "", "error");
                //alert("超過檔案大小");
                return;
            }
            setRefundFile(event.target.files[0]);
            var filename = event.target.files[0].name;
            if (filename.length > 16) {
                filename = filename.substring(1, 16)+"...";
            }
            document.getElementById("FileLabel").innerText = filename;
        }
    }
    function RefundOK() {
        if (RefundFile == undefined) {
            Swal.fire("請選擇檔案", "", "error");
            //alert();
        }
        else {
            var reader = new FileReader();
            reader.readAsDataURL(RefundFile);
            reader.onload = function () {
                API_UserRefund({ URegID: URegID, URFCaption: URFCaption, RefundFile: reader.result, FileName: RefundFile.name })
                    .then((response) => response.data)
                    .then((data) => {
                        if (data.code == "0000") {

                            Swal.fire(data.message, "", "success").then(ok => {
                                window.location = "/Applysuccessrefundok";
                            });
                        }
                        else if (data.code == "2001") {
                            Swal.fire("資料或檔案未完整。", "", "error");
                            //alert();
                        }
                        else {
                            Swal.fire(data.message, "", "error").then(ok => {
                                //alert(data.message);
                                window.location = "/StudentSearch";
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        }
        event.preventDefault();
    }
  return (
      <>
          <section className="section-box">
              <div className="container">

                  <div> <a accessKey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>
                  <h1 className="text-center">退費申請</h1>
                  <form onSubmit={RefundOK}>

                  <div className="form-box">
                      <div className="form-container">
                              <h2 style={{ color: "transparent" }}>退費內容</h2>
                          <div className="max-w-xl mx-auto">

                                  <p className="my-4 text-center">身分證號碼 / 居留證號：{userIDNo}　<span className="mobile-block">姓名：{userName}</span></p>

                              <div className="form-item">
                                  <div className="field">退費申請原因</div>
                                      <div className="value">
                                          <input type="text" placeholder="請填寫退費原因" value={URFCaption} onChange={(e) => { setURFCaption(e.target.value) }} required />
                                  </div>
                              </div>
                                  <div className="form-item">
                                      <div className="field">退費文件上傳</div>
                                      <div className="value" style={{ justifyContent: "space-between" }}>
                                          <label for="" id="FileLabel" className="" style={{ cursor: "default" }}>尚未選擇文件</label>
                                          <input id="RefundFile" onChange={FileUpload} style={{ opacity: "0", width: "1px", height: "1px", margin: "0px", padding: "0px" }} name="RefundFile" type="file" className="" placeholder="請填寫退費原因" required/>
                                          <label for="RefundFile" className="btn-line btn-primary mr-2">選擇退費證明文件</label>
                                         
                                      </div>
                                  </div>
                              {/*<!-- 課程資訊 -->*/}
                              <div className="course-info mt-4">
                                  <h3>{URegDetail.DeptName}｜{URegDetail.CouPName}｜班別序號：{URegDetail.CouNo}</h3>
                                  課程：<b>{URegDetail.CouName}</b><br />
                                  上課日期：{URegDetail.CouStartDate} ~ {URegDetail.CouCloseDate}<br />
                                  上課時段：（{URegDetail.CouWeeks}）{URegDetail.CouStartTime} - {URegDetail.CouCloseTime}
                                  <hr />
                                    預計退費金額：依簡章規定計算，實際退費金額以審核結果為主。審核通過後，會簡訊通知至現場進行退費。<br />
                              </div>

                              <div className="form-item btn-area">
                                  <button type="button" onClick={() => { history.push('/StudentSearch'); }}
                                          className="btn-line btn-primary mr-2">取消申請</button>
                                  <button type="submit" className="btn btn-primary">確認申請退費</button>
                              </div>

                          </div>

                      </div>
                      {/*<!-- end form-container -->*/}
                      </div>
                  </form>
                  {/*<!-- end form-box -->*/}


              </div>
          </section>
     </>
  );
}
export default Applyrefundconfirm;
